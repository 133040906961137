import React, { useState, useEffect } from 'react'
import {Container} from './style'

import {Button, Input, Modal, Table, Popconfirm, Col, Form, message, Icon, Upload, Select, Popover} from 'antd'

import moment from 'moment'

import OzoneRequest from "../../../request/ozone-request";

import CommonRequest from '../../../request/common-request'

import DeTable from '../../../component/DeTable';

const _request = new OzoneRequest()
const _common = new CommonRequest()

function Ozone(props) {
    const [tableTotal, setTableTotal] = useState(0)
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [washList, setWashList] = useState([])
    const [goodsRoomList, setGoodsRoomList] = useState([])
    const [washName, setWashName] = useState([])
    const [equipmentNo,setEquipmentNo] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '消毒间',
                dataIndex: 'disinfectionRoomName',
                key: 'disinfectionRoomName',
            },
            {
                title: '注册码',
                key: 'equipmentNo',
                dataIndex: 'equipmentNo',
            },
            {
                title: '操作人',
                key: 'createUser',
                dataIndex: 'createUser',
            },
            {
                title: '操作时间',
                key: 'createDate',
                dataIndex: 'createDate',
                render: (text, record, index) => (
                    <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
                )
            },
            {
                title: '在线状态',
                key: 'isOnline',
                dataIndex: 'isOnline',
                render: (text, record, index) => (
                    <div style={{ position: 'relative' }}>
                        {
                            text !== null?text?
                                <Popover content={'最后通讯时间：' + (record.offlineTime?moment(record.offlineTime).format("YYYY-MM-DD HH:mm:ss"):'-')} title="设备在线">
                                    <span style={{color: 'green'}}>在线</span>
                                </Popover>
                                :<Popover content={'设备离线时间：' + (record.offlineTime?moment(record.offlineTime).format("YYYY-MM-DD HH:mm:ss"):'-')} title="设备已离线">
                                    <span style={{color: 'red'}}>离线</span>
                                </Popover>:'-'
                        }
                    </div>
                )
            },
            {
                title: '最新数值',
                key: 'value',
                dataIndex: 'value',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <span style={{ marginRight: 8 }} className='tableA' onClick={openModal.bind(this, 'edit', record)}>编辑</span>
						{/*<span style={{ marginRight: 8 }} className='tableA' onClick={openModal.bind(this, 'record', record, record.id,1,10)}>记录</span>*/}
						<Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
							<span className='tableADelete'>删除</span>
						</Popconfirm>
                    </span>
                ),
            },
        ]

    //表格结构
    const [tableData, setTableData] = useState([]) //表格数据

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getWaterList()
    }, [])
    //获取水表列表
    const getWaterList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            equipmentNo,
            washName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getOzoneSensorList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteOzoneSensor(params).then(res => {
            message.info('删除成功！')
            getWaterList()
        })
    }
    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }

    //选择洗消点
    const washChange = (e) => {
        const { setFieldsValue } = props.form
        setTimeout(() => {
            setFieldsValue({
                disinfectionRoomId: null,
            })
        }, 0)
        getDisinfectionRoom(e)
    }

    const getDisinfectionRoom = (washSpotId) => {
        let params = {
            washSpotId
        }
        _common.getDisinfectionRoom(params).then(res => {
            setGoodsRoomList(res)
        })
    }

    //打开编辑页
    const openModal = (type, record,recordId, pageNo = 1, pageSize = 10) => {
        // 获取洗消点集合
        washSpotList()

        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type === 'edit'){ // 编辑页面
            getDisinfectionRoom(record.washSpotId)
            setTimeout(() => {
                setFieldsValue({
                    equipmentNo: record.equipmentNo,
                    disinfectionRoomId: record.disinfectionRoomId,
                    washSpotId : record.washSpotId,
                    id: record.id
                })
            }, 0)
        }
    }

    const { getFieldValue, validateFields, setFieldValue } = props.form

    //编辑页提交
    const editConfire = () => {
        validateFields((err, value) => {
            if (!err) {
                // setConfirmLoading(true)
                let name = ''
                goodsRoomList.forEach(item => {
                    if (getFieldValue('disinfectionRoomId') === item.id){
                        name = item.name
                    }
                })
                if (modalType === 'add') {
                    let params = {
                        equipmentNo: getFieldValue('equipmentNo'),
                        disinfectionRoomId : getFieldValue('disinfectionRoomId'),
                        disinfectionRoomName : name,
                        washSpotId : getFieldValue('washSpotId')
                    }
                    _request.addOzoneSensor(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getWaterList()
                    })
                } else {
                    let params = {
                        equipmentNo: getFieldValue('equipmentNo'),
                        disinfectionRoomId : getFieldValue('disinfectionRoomId'),
                        disinfectionRoomName : name,
                        washSpotId : getFieldValue('washSpotId'),
                        id: getFieldValue('id')
                    }
                    _request.updateOzoneSensor(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getWaterList()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getWaterList()
    }
    //重置
    const clickReset = () => {
        setWashName('')
        setEquipmentNo('')
        setPageNo(1)
        setPageSize(10)
        getWaterList('reset')
    }
    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getWaterList('',pageNo,pageSize);
    }

    const { Option } = Select;

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入洗消点名称" style={{ width: 200, marginRight: 10 }} value={washName} onPressEnter={clickSearch} onChange={(e) => setWashName(e.target.value)}></Input>
                <Input placeholder="请输入设备编号" style={{ width: 200, marginRight: 10 }} value={equipmentNo} onPressEnter={clickSearch} onChange={(e) => setEquipmentNo(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增臭氧检测设备' : modalType === 'edit' ? '编辑' : '使用详情'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    modalType === 'record' ? null :
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                        </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{ height: 300 }}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="注册码：">
                                    {getFieldDecorator('equipmentNo', {
                                        rules: [{ required: true, message: '请输入注册码！' }],
                                    })(
                                        <Input
                                            placeholder="请输入注册码"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择洗消点！' }],
                                    })(
                                        <Select placeholder="请选择洗消点" onChange={washChange}>
                                            {
                                                washList.map((item,index) => {
                                                    return <Option key={item.key} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="消毒间：">
                                    {getFieldDecorator('disinfectionRoomId', {
                                        rules: [{ required: true, message: '请选择消毒间！' }],
                                    })(
                                        <Select placeholder="请选择消毒间">
                                            {
                                                goodsRoomList.map((item,index) => {
                                                    // console.log(item.key);
                                                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                        :
                        <Form style={{ height: 300 }}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="注册码：">
                                    {getFieldDecorator('equipmentNo', {
                                        rules: [{ required: true, message: '请输入注册码！' }],
                                    })(
                                        <Input
                                            placeholder="请输入注册码"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择洗消点！' }],
                                    })(
                                        <Select placeholder="请选择洗消点" onChange={washChange}>
                                            {
                                                washList.map((item,index) => {
                                                    return <Option key={item.key} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="消毒间：">
                                    {getFieldDecorator('disinfectionRoomId', {
                                        rules: [{ required: true, message: '请选择消毒间！' }],
                                    })(
                                        <Select placeholder="请选择消毒间">
                                            {
                                                goodsRoomList.map((item,index) => {
                                                    // console.log(item.key);
                                                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(Ozone)
