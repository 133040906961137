import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'
import DeTable from '../../../component/DeTable'
import moment from 'moment'

import StoreSettingHttp from '../../../request/demo'
import CommonHttp from "../../../request/common-request";

const _request = new StoreSettingHttp()
const _commonrequest = new CommonHttp()

function Demo(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [goodsName, setGoodsName] = useState('')
    const [washSpotName, setWashSpotName] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '物品类型',
                dataIndex: 'goodsType',
                key: 'goodsType',
                render: (text, record, index) => (
                    goodTypeData.map((item,index1) => {
                        if(record.goodsType === item.dictValue) {
                            return  <span>{item.dictDesc}</span>
                        }
                    })

                )
            },
            {
                title: '物品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
            },
            {
                title: '所属猪场',
                key: 'pigFramName',
                dataIndex: 'pigFramName',
            },
            {
                title: '剩余库存',
                key: 'stock',
                dataIndex: 'stock',
            },
            {
                title: '累计总数',
                key: 'stockSum',
                dataIndex: 'stockSum',
            },
            {
                title: '操作时间',
                key: 'operationDate',
                dataIndex: 'operationDate',
				render: (text, record, index) => {
					return <span>{moment(record.operationDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}

            },
            {
                title: '描述',
                dataIndex: 'remark',
                key: 'remark',
            },
        ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [goodTypeData, setGoodTypeData] = useState([]) //物资类型
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getAllStock()
        getGoodTypeList()
    }, [])
    //获取物资列表
    const getAllStock = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            goodsName,
            washSpotName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.findMaterialStock(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //获取物品类型列表
    const getGoodTypeList = () => {
        let params = {
            dictType:'GOOD_TYPE'
        }
        _commonrequest.getDictList(params).then(res => {
            setGoodTypeData(res.list)
        })
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getAllStock()
    }

    //重置
    const clickReset = () => {
		setGoodsName('')
		setWashSpotName('')
        setPageNo(1)
        setPageSize(10)
        getAllStock('reset')
    }

    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getAllStock('',pageNo,pageSize);
    }

    return (
        <Container>
            <div className="top">
                <Input placeholder="请输入洗消点" style={{ width: 200, marginRight: 10 }} value={washSpotName} onPressEnter={clickSearch} onChange={(e) => setWashSpotName(e.target.value)}></Input>
                <Input placeholder="请输入物品名称" style={{ width: 200, marginRight: 10 }} value={goodsName} onPressEnter={clickSearch} onChange={(e) => setGoodsName(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>查找</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
				<DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>
        </Container>

    )
}

export default Form.create()(Demo)
