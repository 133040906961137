import React, {useState, useEffect} from 'react'
import {Container, LoginBox, Footer, LoginShade, LoginTitle} from './style'
import loginLogo from '../../img/loginLogo.png'
import {connect} from 'react-redux'
import {Button, Input, Form, Checkbox, Icon, message, Col} from 'antd'
import Post from '../../request/system-request.js'

import moment from "moment";

const _post = new Post();

function Login(props) {
    const {getFieldDecorator} = props.form
    const [randomTime, setRandomTime] = useState('') //随机数 用于验证码刷新
    const [isRotate, setIsRotate] = useState(false) //验证码刷新按钮旋转
    const [isLogin, setIsLogin] = useState(true) //当前为登录状态还是重置密码状态

    const flAge = 0;
    const hello = [
        '你不能只靠「如果」来过自己的人生。',
        '一次只做一件事，做到最好。',
        '任何值得做的事就值得把它做好。',
        '相信你自己。'
    ];
    const enHello = [
        'You can not live your life according to maybes.',
        'Do one thing at a time, do the best.',
        'Whatever is worth doing is worth doing well.',
        'Believe in yourself.'
    ];
    useEffect(() => {
    })
    //登录
    const loginNow = (e) => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                _post.getLogin({
                    // userAccount: values.username,
                    username: values.username,
                    // userPassword: values.password
                    password: values.password
                }).then((res) => {
                    sessionStorage.setItem('token', res.accessToken)
                    window.location.href="#/toHome?token="+res.accessToken;
                    // props.history.push('/home')
                }, (errMsg) => {


                })
            } else {

            }
        });
//      this.setState({
//			loading:true
//		})
        //e.preventDefault();
//	  this.props.form.validateFields((err, values) => {
//
//	  });

    }
    //刷新验证码
    const refreshImg = () => {
        setRandomTime(Math.random().toFixed(9))
        setIsRotate(true)
        setTimeout(() => {
            setIsRotate(false)
        }, 500);
    }
    //忘记密码
    const forgetPassword = () => {
        setIsLogin(false)
    }
    //返回登录界面
    const backToLogin = () => {
        setIsLogin(true)
    }
    const timeChinese = (e) => {
        if (e === '01'){
            return '一'
        }else if (e === '02'){
            return '二'
        }else if (e === '03'){
            return '三'
        }else if (e === '04'){
            return '四'
        }else if (e === '05'){
            return '五'
        }else if (e === '06'){
            return '六'
        }else if (e === '07'){
            return '七'
        }else if (e === '08'){
            return '八'
        }else if (e === '09'){
            return '九'
        }else if (e === '10'){
            return '十'
        }else if (e === '11'){
            return '十一'
        }else if (e === '12'){
            return '十二'
        }
    }
    return (
        <Container>
            <LoginShade>
                <LoginTitle>
                    <div className='logoImg'>
                        <img src={loginLogo}/>
                    </div>
                    <div className='title'>
                        <p>润农生物安全系统</p>
                        <span>Winland Biosecurity System</span>
                    </div>
                </LoginTitle>
                <LoginBox>
                    {
                        isLogin ?
                            <Form className="login-form" onSubmit={loginNow}>
                                <Form.Item>
                                    <div className="title">
                                        <div className='time'>
                                            <span className='time-day'>{moment(new Date()).format("DD")}</span>
                                            <span style={{fontFamily: 'pingfang'}} className='time-month'>{timeChinese(moment(new Date()).format("MM"))}月</span>{/*中文*/}
                                        </div>
                                        <div style={{height: 50,width: 1,backgroundColor: '#e0e0e0',marginTop: 10}}></div>
                                        <div className='title-content'>
                                            <p className='enHello'>{enHello[flAge]}</p>
                                            <p className='hello'>{hello[flAge]}</p>
                                        </div>
                                    </div>
                                </Form.Item>
                                <div style={{ width: '90%',fontWeight: '500',marginBottom: 10,marginTop: -10 }}>账号</div>
                                <Form.Item style={{width: '100%', textAlign: 'center'}}>
                                    {getFieldDecorator('username', {
                                        rules: [{required: true, message: '请输入您的用户名!'}]
                                    })(
                                        <Input style={{width: '90%'}} size="large"
                                               prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                               placeholder="请输入您的用户名"/>
                                    )}
                                </Form.Item>
                                <div style={{ width: '90%',fontWeight: '500',marginBottom: 10 }}>密码</div>
                                <Form.Item style={{width: '100%', textAlign: 'center'}}>
                                    {getFieldDecorator('password', {
                                        rules: [{required: true, message: '请输入您的密码!'}]
                                    })(
                                        <Input style={{width: '90%',marginBottom: 20}} size="large"
                                               prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                               type="password" placeholder="请输入您的密码"/>
                                    )}
                                </Form.Item>
                                {
                                    1 === 2
                                        ?
                                        <Form.Item style={{width: '70%', textAlign: 'left'}}>
                                            {getFieldDecorator('code', {
                                                rules: [{required: true, message: '请输入验证码！'}],
                                            })(
                                                <Input
                                                    placeholder="请输入验证码"
                                                    size="large"
                                                    prefix={<Icon type="message" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                                    style={{width: '50%', marginRight: 10}}
                                                />,
                                            )}
                                            <span onClick={refreshImg} style={{width: '50%'}}>
                                            <React.Fragment>
                                                <img src={"client/validate/code?time=" + randomTime} className="code"
                                                     alt="获取验证码失败"/>
                                                <Icon style={{marginLeft: 10}} spin={isRotate} type="sync"></Icon>
                                            </React.Fragment>
                                        </span>
                                        </Form.Item>
                                        :
                                        ''
                                }

                                <Button type="primary" style={{width: '90%'}} htmlType="submit" size="large">登录</Button>
                                {/*<Form.Item style={{marginTop: 10, width: '90%', textAlign: 'right'}}>*/}
                                    {/*<Button type="link" onClick={forgetPassword} style={{ textDecoration:'underline' }}>忘记密码</Button>*/}
                                    {/*<a href='javascript:;' onClick={forgetPassword} style={{ textDecoration:'underline' }}>忘记密码</a>*/}
                                    {/*{getFieldDecorator('remember', {*/}
                                    {/*    valuePropName: 'checked',*/}
                                    {/*    initialValue: false*/}
                                    {/*})(*/}
                                    {/*    <Checkbox>记住帐号</Checkbox>*/}
                                    {/*)}*/}
                                {/*</Form.Item>*/}
                            </Form>
                            :
                            <Form className="login-form">
                                <Button shape="circle" icon="arrow-left" onClick={backToLogin} className="back-to-login"/>
                                <Form.Item>
                                    <div className="title">重置密码</div>
                                </Form.Item>
                                <Form.Item style={{width: '70%', textAlign: 'left'}}>
                                    {getFieldDecorator('phone', {
                                        rules: [{required: true, message: '请输入您的手机号!'}]
                                    })(
                                        <Input style={{width: '55%', marginRight: 10}} size="large"
                                               prefix={<Icon type="mobile" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                               placeholder="请输入您的手机号"/>
                                    )}
                                    <Button size="large" disabled type="primary">23s 发送验证码</Button>
                                </Form.Item>
                                <Form.Item style={{width: '100%', textAlign: 'center'}}>
                                    {getFieldDecorator('phonecode', {
                                        rules: [{required: true, message: '请输入手机验证码!'}]
                                    })(
                                        <Input style={{width: '70%'}} size="large"
                                               prefix={<Icon type="message" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                               type="password" placeholder="请输入手机验证码"/>
                                    )}
                                </Form.Item>
                                <Form.Item style={{width: '100%', textAlign: 'center'}}>
                                    {getFieldDecorator('loginpassword', {
                                        rules: [{required: true, message: '请设置登录密码！'}]
                                    })(
                                        <Input style={{width: '70%'}} size="large"
                                               prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                               type="password" placeholder="请设置登录密码"/>
                                    )}
                                </Form.Item>
                                <Form.Item style={{width: '100%', textAlign: 'center'}}>
                                    {getFieldDecorator('confirmpassword', {
                                        rules: [{required: true, message: '请确认登录密码！'}]
                                    })(
                                        <Input style={{width: '70%'}} size="large"
                                               prefix={<Icon type="check" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                               type="password" placeholder="请确认登录密码"/>
                                    )}
                                </Form.Item>
                                <Button type="primary" style={{width: '70%'}} onClick={loginNow} size="large">确认修改</Button>
                            </Form>
                    }
                </LoginBox>
            </LoginShade>

            <Footer>服务热线：400-777-2180 www.winland.com.cn 深圳市润农科技有限公司 版权所有 Copyright © 2010-2021 Winland Technologies, Inc. All Rights Reserved</Footer>
        </Container>
    )
}

const mapState = (state) => ({})

const mapDispatch = (dispatch) => ({})

export default connect(mapState, mapDispatch)(Form.create()(Login))
