import styled from 'styled-components'

import bgImage from '../../img/bg.jpg'
import bgImage2 from '../../img/bg1.png'
import bgImage3 from '../../img/login-bg.png'
import shadowImage from '../../img/shadow.png'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: url(${bgImage3}) no-repeat;
  background-size: cover;
  position: relative;
`

export const LoginShade = styled.div`
  height: 100%;
  width: 100%;
  background: url(${shadowImage}) no-repeat;
  background-size: cover;
  position: relative;
`

export const LoginTitle = styled.div`
  position: absolute;
  right: 10%;
  display: flex;
  top: 12%;
  width: 500px;
  .logoImg {
    margin-top: 1%;

    img {
      width: 165px;
    }
  }

  .title {
    text-align: left;
    color: #FFFFFF;
    margin-left: 20px;

    p {
      font-size: 36px;
      margin: 10px 0 15px 0!important;
      font-weight: 500;
    }

    span {
      font-size: 20px;
    }
  }
`

export const LoginBox = styled.div`
  position: absolute;
  //width: 30%;
  height: 60%;
  min-width: 500px;
  min-height: 460px;
  background: white;
  box-shadow: 0px 0px 3px white;
  border-radius: 10px;
  top: 29%;
  right: 10%;
  //transform: translate(-50%, -60%);

  .login-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      width: 100%;
      //text-align: center;
      color: rgba(42, 42, 55, 0.6);
      //padding-top: 10px;
      display: flex;
      .time {
        line-height: 55px;
        margin-right: 10px;
        font-size: 26px;
        //color: #000000;
        &-day {
          margin-left: 20px;
          font-size: 60px;
          font-weight: 500;
          margin-right: 10px;
          color: rgba(47,126,207);
        }
        &-month{
          color: rgba(47,126,207,0.8);
        }
      }
      &-content{
        margin-left: 10px;
        p{
          margin-bottom: 0;
        }
        .hello{
          font-size: 15px;
          font-weight: 500;
        }
        .enHello{
          font-size: 14px;
          margin-top: -10px;
        }
      }
    }

    .back-to-login {
      position: absolute;
      top: 30px;
      left: 5%;
    }
  }
`

export const Footer = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  color: #2A2A37;
`