import React, {useEffect, useState} from "react";
import {Container} from './style'
import {Form, Button, Input, message} from "antd";
import ShareRequest from "../../../../request/share-request";
import moment from "moment";
import { VxTimeFormat } from '../../../../utils/homeFormat'

const {TextArea} = Input
const _request = new ShareRequest()

function Share(props) {
    const { getFieldDecorator,getFieldValue,setFieldsValue,validateFields } = props.form
    const [messageData,setMessageData] = useState([])
    const [pageSize,setPageSize] = useState(10)
    const [pageNo,setPageNo] = useState(1)
    // let pageSize = 10;
    useEffect(()=>{
        getMessageBoard()

    },[])

    const getMessageBoard = (e) => {
        let param = {
            regionId: sessionStorage.getItem('regionId'),
            current: pageNo,
            size: pageSize
        }
        if (e){
            param.current = e
        }
        _request.getMessageBoard(param).then(res => {
            console.log(res);
            if (messageData.length === 0){
                setMessageData(res.records)
            }else{
                setMessageData(messageData.concat(res.records))
            }
        })
    }
    const getMessageBoard2 = () => {
        let param = {
            regionId: sessionStorage.getItem('regionId'),
            size: 10,
            current: 1
        }
        _request.getMessageBoard(param).then(res => {
            setMessageData(res.records)
        })
    }

    const sendClick = () => {
        validateFields((err, value) => {
            if (!err) {
                let param = {
                    content: getFieldValue('content')
                }
                _request.addMessageBoard(param).then(res => {
                    getMessageBoard2()
                    message.info('发送成功！')
                    setFieldsValue({
                        content: ''
                    })
                })
            }
        })
    }

    const myFunction = (e) => {
        e.persist()
        let scrollHeight = e.target.scrollHeight
        let scrollTop = e.target.scrollTop
        let subtraction = scrollHeight - scrollTop
        if (parseInt(subtraction) === e.target.clientHeight){
            setPageNo(pageNo + 1)
            getMessageBoard(pageNo + 1)
        }
    }

    return (
        <Container>
            <div className='messageDiv' onScroll={myFunction}>
                {
                    messageData.length === 0?
                        <div style={{ textAlign: 'center',lineHeight: '190px' }}>暂无数据</div>
                        :
                        messageData.map(item=>{
                            return(
                                <div className='messageDiv-div'>
                                    <div className='titleDate'>
                                        <span className='titleDate-title'>{item.nickname}</span>
                                        <span className='titleDate-date'>{VxTimeFormat(item.createdTime)}</span>
                                    </div>
                                    <div className='content'>{item.content}</div>
                                </div>
                            )
                        })
                }
            </div>
            <div className='inputDiv'>
                <div className='TextAreaDiv'>
                    <Form>
                        <Form.Item>
                            {getFieldDecorator('content',{
                                rules: [{required: true, message: '请输入内容！'},{ pattern: /^[^\s]*$/, message: '禁止输入空格！' }],
                            })(
                                <TextArea className='TextArea' placeholder='请输入内容' maxLength='150' onPressEnter={sendClick} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
                <div className='sendButton'>
                    <Button type="primary" size='small' onClick={sendClick}>发送</Button>
                </div>
            </div>
        </Container>
    )
}

export default Form.create()(Share)
