import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
  .top{
    .checked{
      font-weight: bold;
    }
  }
  .bottom{
    margin-top: 10px;
    height: 80%;
  }
  .ant-table-bordered .ant-table-header > table, .ant-table-bordered .ant-table-body > table, .ant-table-bordered .ant-table-fixed-left table, .ant-table-bordered .ant-table-fixed-right table{
    border-top: none!important;
  }
  .ant-table-bordered.ant-table-empty .ant-table-placeholder{
    border-bottom: 1px solid #e8e8e8;
  }
`
