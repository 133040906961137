import request from '../https'

class DecontaminateRequest{
	//洗消点列表=========================
    getWashSetList(params){
        return request({
            url: 'safety/washSet/list',
            method: 'post',
            params
        })
    }
	//洗消点列表=========================
	getParentList(params){
	    return request({
	        url: 'safety/washSet/getParentList',
	        method: 'post',
	        params
	    })
	}
	//获取组列表=========================
	getetGroupDataList(params){
	    return request({
	        url: 'safety/group/list',
	        method: 'post',
	        params
	    })
	}
    //新增保存洗消点
    addWashSet(params) {
        return request({
            url: 'safety/washSet/add',
            method: 'POST',
            params
        });
    }
	//新增保存组
	addGroupSet(params) {
	    return request({
	        url: 'safety/group/add',
	        method: 'POST',
	        params
	    });
	}
    //修改保存洗消点
    updateWashSet(params) {
        return request({
            url: 'safety/washSet/update',
            method: 'PUT',
            params
        });
    }
    //删除洗消点
    deleteWashSet(params) {
        return request({
            url: 'safety/washSet/delete/' + params.id,
            method: 'DELETE'
        });
    }
    
    
    //获取洗消点关联的猪场列表
   getPigFramList(params){
        return request({
            url: 'safety/washSet/getPigFramList',
            method: 'post',
            params
        })
    }
   
   
   
   
   //洗消点流程===========================================================================
   //洗消点流程列表=========================
    getWorkFlowList(params){
        return request({
            url: 'safety/workFlow/list',
            method: 'post',
            params
        })
    }
    //新增保存洗消点流程
    addWorkFlow(params) {
        return request({
            url: 'safety/workFlow/add',
            method: 'POST',
            params
        })
    }
    //整体保存流程图数据
    saveWorkFlowPic(data) {
        return request({
            url: 'safety/workFlowPic/add',
            method: 'POST',
            data
        })
    }
    //修改保存洗消点流程
    updateWorkFlow(params) {
        return request({
            url: 'safety/workFlow/update',
            method: 'PUT',
            params
        })
    }
    //删除洗消点流程
    deleteWorkFlow(params) {
        return request({
            url: 'safety/workFlow/delete/' + params.id,
            method: 'DELETE'
        })
    }
    //洗消点流程检测项列表=========================
    getCheckSetList(params){
        return request({
            url: 'safety/checkSet/list',
            method: 'post',
            params
        })
    }
    //新增保存洗消点流程检测项
    addCheckSet(params) {
        return request({
            url: 'safety/checkSet/add',
            method: 'POST',
            params
        })
    }
    //删除洗消点流程检测项
    deleteCheckSet(params) {
        return request({
            url: 'safety/checkSet/delete/' + params.id,
            method: 'DELETE'
        })
    }
    //查询物资类型
    getGoodsType(params) {
        return request({
            url: 'safety/workFlow/getGoodsType',
            method: 'POST',
            data: params
        })
    }
    //查询洗消点物资消毒规则
    getGoodsRules(params) {
        return request({
            url: 'safety/workFlow/getGoodsRules',
            method: 'POST',
            data: params
        })
    }
}

export default DecontaminateRequest