import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Select, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import ExpireTimeHttp from '../../../request/expiretime-request'


const Option = Select.Option;
const _request = new ExpireTimeHttp()

function ExpireTime(props) {
    const [tableTotal, setTableTotal] = useState(0)
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [type, setType] = useState('')
    const [regionName, setRegionName] = useState('')

    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '机构名称',
                key: 'regionName',
                dataIndex: 'regionName',
            },
            {
                title: '失效时间(分钟)',
                key: 'expireTime',
                dataIndex: 'expireTime',
            },
            {
                title: '类型',
                key: 'type',
                dataIndex: 'type',
                render: (text, record, index) => (
                    <span>{record.type === '1' ? '车辆' :  '人员'}</span>
                )
            },
            {
                title: '日期',
                dataIndex: 'createDate',
                key: 'createDate',
				render: (text, record, index) => {
					return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <span className='tableA' style={{ marginRight: 8 }} onClick={openModal.bind(this, 'edit', record)}>编辑</span>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                            <span className='tableADelete'>删除</span>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
     //表格结构
    const [tableData, setTableData] = useState([]) //表格数据
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getExpireTimeList()
    }, [])
    //获取列表
    const getExpireTimeList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            type:type,
            regionName:regionName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getExpireTimeList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }


    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.expireTimeDelete(params).then(res => {
            message.info('删除成功！')
            getExpireTimeList()
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){

            setTimeout(() => {
                setFieldsValue({
                    expireTime: record.expireTime,
                    type: record.type,
                    id: record.id
                })
            }, 0)


        }

    }

    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (modalType === 'add') {
                    let params = {
                        expireTime: getFieldValue('expireTime'),
                        type: getFieldValue('type')
                    }
                    _request.expireTimeAdd(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getExpireTimeList()
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })
                } else {
                    let params = {
                        expireTime: getFieldValue('expireTime'),
                        type: getFieldValue('type'),
                        id: getFieldValue('id')
                    }
                    _request.expireTimeUpdate(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getExpireTimeList()
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getExpireTimeList()
    }
    //重置
    const clickReset = () => {
		setType('')
		setRegionName('')
        setPageNo(1)
        setPageSize(10)
        getExpireTimeList('reset')
    }
    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getExpireTimeList('',pageNo,pageSize);
    }
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                {/* <Input placeholder="请输入机构名称" style={{ width: 200, marginRight: 10 }} value={regionName} onPressEnter={clickSearch} onChange={(e) => setRegionName(e.target.value)}></Input> */}
                <Select style={{ width: 200, marginRight: 10 }} value={type} onPressEnter={clickSearch} onChange={(e) => setType(e)}>
					<Option value="" key="" >请选择类型</Option>
					<Option value="1" key="" >车辆</Option>
					<Option value="2" key="" >人员</Option>
				</Select>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增洗消点' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ height: 100 }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="失效时间(分钟)：">
                                {getFieldDecorator('expireTime', {
                                    rules: [{ required: true, message: '请输入失效时间！',pattern:new RegExp(/^\d+$|^\d+[.]?\d+$/, "g")  }],
                                })(
                                    <Input
                                        placeholder="请输入失效时间"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="类型：">
								{getFieldDecorator('type', {
									rules: [{ required: true, message: '请选择类型!' }],
								})(
									<Select>
										<Option value='1' key='' >车辆</Option>
										<Option value='2' key='' >人员</Option>
									</Select>
								)}

							</Form.Item>
						</Col>
                    </Form>
                    :
                    <Form style={{ height: 100 }}>
                        <Form.Item>
                            {getFieldDecorator('id')(
                                <span></span>
                            )}
                        </Form.Item>

                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="失效时间(分钟)：">
                                {getFieldDecorator('expireTime', {
                                    rules: [{ required: true, message: '请输入失效时间！',pattern:new RegExp(/^\d+$|^\d+[.]?\d+$/, "g") }],
                                })(
                                    <Input
                                        placeholder="请输入失效时间"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="类型：">
								{getFieldDecorator('type', {
									rules: [{ required: true, message: '请选择类型!' }],
								})(
									<Select>
										<Option value='1' key='' >车辆</Option>
										<Option value='2' key='' >人员</Option>
									</Select>
								)}

							</Form.Item>
						</Col>
                    </Form>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(ExpireTime)
