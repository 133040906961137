import request from '../https'

class OzoneRequest {
    //获取列表
    getOzoneSensorList(params) {
        return request({
            url: 'safety/ozoneSensor/list',
            method: 'post',
            data:params
        })
    }
    //新增臭氧检测设备
    addOzoneSensor(params) {
        return request({
            url: 'safety/ozoneSensor/add',
            method: 'post',
            data:params
        })
    }
    //编辑臭氧检测设备
    updateOzoneSensor(params) {
        return request({
            url: 'safety/ozoneSensor/update',
            method: 'post',
            data:params
        })
    }
    //删除臭氧检测设备
    deleteOzoneSensor(params) {
        return request({
            url: 'safety/ozoneSensor/delete',
            method: 'post',
            data:params
        })
    }
}

export default OzoneRequest