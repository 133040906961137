import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 10px;
  height: 285px;
  overflow: auto;
  .videoDiv{
    width: 100%;
    margin-left: 5px;
    position: relative;
    .dataDiv{
      display: flex;
      margin-bottom: 10px;
      cursor: pointer;
      img{
        width: 36%;
      }
      .dataTitle{
        margin-left: 10px;
        div{
          height: 25px;
          line-height: 35px;
          width: calc(100% - 20px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .unread{
      position: absolute;
      top: 1px;
      right: 10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: red;
    }
    .risk{
      position: absolute;
      width: 40px;
      height: 40px;
      background-size: 100%;
      top: 5px;
      left: 80%;
    }
    .centre{
      position: absolute;
      line-height: 30px;
      text-align: center;
      top: 5px;
      left: 80%;
      color: orange;
      border: 1px solid orange;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .low{
      position: absolute;
      line-height: 30px;
      text-align: center;
      top: 5px;
      left: 80%;
      color: green;
      border: 1px solid green;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
`
