import React from "react";
import {Form,Popconfirm} from "antd";
import {RightOutlined,QuestionCircleOutlined} from '@ant-design/icons';
import {Container} from './style'
import auditIcon from '../../img/audit.png'
import alertIcon from '../../img/alert.png'
import videoIcon from '../../img/video.png'
import washIcon from '../../img/wash.png'
import shareIcon from '../../img/share.png'

function HomeTitle(props) {
    const {clickDetails, isBorder, iconFont, isAlert, confirm1, cancel1} = props

    const style = {
        display: 'flex',
        justifyContent: 'space-between',
        height: 50,
        lineHeight: '50px',
        padding: '0 10px 0 20px',
        borderBottom: isBorder ? '1px solid #D3D7DF' : 'none'
    }


    const details = () => {
        clickDetails()
    }
    const confirm = () => {
        confirm1()
    }
    const cancel = () => {
        cancel1()
    }
    return (
        <Container>
            <div style={style}>
                <div style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.7)'}}>
                    {/*<span className={'iconfont ' + iconFont}> </span>*/}
                    <span className='iconImg'>
                {
                    iconFont === 'alert' ? <img style={{marginTop: -4}} src={alertIcon} width='14' height='17'/>:
                    iconFont === 'audit' ? <img src={auditIcon} width='14' height='17'/> :
                    iconFont === 'video' ? <img src={videoIcon} width='21' height='19'/> :
                    iconFont === 'wash' ? <img src={washIcon} width='17' height='17'/> :
                    iconFont === 'share' ? <img src={shareIcon} width='16' height='17'/> : ''
                }
                </span>
                    {/*<img*/}
                    {/*    src={*/}
                    {/*        iconFont === 'alert' ? alertIcon :*/}
                    {/*        iconFont === 'audit' ? auditIcon :*/}
                    {/*        iconFont === 'video' ? videoIcon :*/}
                    {/*        iconFont === 'wash' ? washIcon :*/}
                    {/*        iconFont === 'share' ? shareIcon : ''*/}
                    {/*    }*/}
                    {/*    width='20' height='19'*/}
                    {/*    style={{marginRight: 3}}*/}
                    {/*/>*/}
                    <span style={{fontWeight: 600}}>{props.title}</span>
                </div>
                {
                    isAlert?
                        <Popconfirm title="请选择跳转页面" okText="警报中心" icon={
                            <QuestionCircleOutlined/>
                        } cancelText="AI报警统计" onConfirm={confirm} onCancel={cancel}>
                            <span style={{color: 'rgba(0, 0, 0, 0.4)', fontWeight: 400, fontSize: 14, cursor: 'pointer'}}>查看更多<RightOutlined/></span>
                        </Popconfirm>:
                        <span style={{color: 'rgba(0, 0, 0, 0.4)', fontWeight: 400, fontSize: 14, cursor: 'pointer'}}
                                     onClick={details}>查看更多<RightOutlined/></span>
                }

            </div>
        </Container>

    )
}

export default Form.create()(HomeTitle)
