import request from "../https";

class UltravioletRequest {
    //获取列表
    getUltravioletSensorList(params) {
        return request({
            url: 'safety/ultravioletSensor/list',
            method: 'post',
            data:params
        })
    }
    //新增臭氧检测设备
    addUltravioletSensor(params) {
        return request({
            url: 'safety/ultravioletSensor/add',
            method: 'post',
            data:params
        })
    }
    //编辑臭氧检测设备
    updateUltravioletSensor(params) {
        return request({
            url: 'safety/ultravioletSensor/update',
            method: 'post',
            data:params
        })
    }
    //删除臭氧检测设备
    delUltravioletSensor(params) {
        return request({
            url: 'safety/ultravioletSensor/delete',
            method: 'post',
            data:params
        })
    }
}

export default UltravioletRequest