import styled from 'styled-components'

export const Container = styled.div `
  height: 100%;
  .ant-tabs-bar{
    background-color: #FFFFFF!important;
  }
  .tabTable{
    height: calc(100% - 50px) !important;
    .sc-VigVT{
      height: 100%;
    }
  }
  .total{
    margin-bottom: 6px;
    margin-top: -6px;
  }
  .ant-tabs-nav .ant-tabs-tab-active{
    font-weight: 700;
    border-bottom: 1px solid!important;
  }
  .ant-tabs-nav-scroll{
    font-weight: 600;
  }
  .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab-active{
    
  }

`
