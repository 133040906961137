import React, {useEffect, useState} from "react";
import {Container} from './style'
import { Form,Tabs } from "antd";
import PeopleWash from "../personManage/peopleWash";
import DecontaminationRecord from "../car/decontaminationRecord";
import Decontamination from "../material/decontamination"
import SprinklerRecord from "./sprinkler";

const { TabPane } = Tabs;
function WashRecord(props){
    const {washSpotId,time,endTime} = props
    const [activeKey,setActiveKey] = useState('1')
    useEffect(()=>{
        if (washSpotId){
            setActiveKey(washSpotId.split('&')[0])
        }
    },[washSpotId])
    const onActive = (e) => {
        setActiveKey(e);
    }
    return (
        <Container>
            <Tabs activeKey={activeKey} onChange={onActive}>
                <TabPane tab="人员洗消记录" key="1">
                    <PeopleWash washSpotId={washSpotId} time={time}/>
                </TabPane>
                <TabPane tab="车辆洗消记录" key="2" style={{ marginLeft: '20px' }}>
                    <DecontaminationRecord washSpotId={washSpotId} time={time}/>
                </TabPane>
                <TabPane tab="物资洗消记录" key="3" style={{ marginLeft: '20px' }}>
                    <Decontamination washSpotId={washSpotId} time={time} endDate={endTime}/>
                </TabPane>
                <TabPane tab="花洒淋浴记录" key="4" style={{ marginLeft: '20px' }}>
                    <SprinklerRecord />
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(WashRecord)
