import React from "react";
import {Container} from './style'
import { Form,Tabs } from "antd";
import Register from "../register"
import Stock from "../stock"
import OutAndWarehousing from "../outAndWarehousing"

const { TabPane } = Tabs;
function MaterialAdmin(props){

    return (
        <Container>
            <Tabs defaultActiveKey="1">
                <TabPane tab="物资登记" key="1">
                    <Register></Register>
                </TabPane>
                <TabPane tab="物品库存" key="2" style={{ marginLeft: '20px' }}>
                    <Stock></Stock>
                </TabPane>
                <TabPane tab="物品出入库" key="3" style={{ marginLeft: '20px' }}>
                    <OutAndWarehousing></OutAndWarehousing>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(MaterialAdmin)