import React, { useState, useEffect } from 'react'
import {Container} from './style'

import {
    Button,
    Input,
    Modal,
    Steps,
    Popconfirm,
    Col,
    Form,
    message,
    Icon,
    Upload,
    Select,
    DatePicker,
    TreeSelect
} from 'antd'

import moment from 'moment'

import AICameraRequest from '../../request/AICamera-request'

import DeTable from '../../component/DeTable';
import {stringFormat,getPeriodTime} from "../../utils/homeFormat";
import CommonRequest from "../../request/common-request";
import WashTotalRequest from "../../request/washTotal-request";

const _request = new AICameraRequest()
const _common = new CommonRequest()
const _washRequest = new WashTotalRequest()
const { MonthPicker, RangePicker } = DatePicker;
const { TreeNode } = TreeSelect;

const {Option} = Select;
function AIWarnStatistics(props) {
    const {washSpotId,time,nextClick} = props
    const [name, setName] = useState('')
    const [workflowId, setWorkflowId] = useState()
    const [startTime, setStartTime] = useState(moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD'))
    const [endTime, setEndTime] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [searchSiteValue,setSearchSiteValue] = useState(null)
    const [AIVideoSiteList, setAIVideoSiteList] = useState([])
    const [searchSiteId,setSearchSiteId] = useState('')
    const [searchSiteType,setSearchSiteType] = useState('')
    const [searchSitePositionId,setSearchSitePositionId] = useState('')
    const [searchSitePigpenId,setSearchSitePigpenId] = useState('')
    const [alarmTypesList, setAlarmTypesList] = useState([])
    const [alarmTypes, setAlarmTypes] = useState()
    const [alarmRiskLevelList, setAlarmRiskLevelList] = useState([])
    const [alarmRiskLevel, setAlarmRiskLevel] = useState()
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '所属区域',
                dataIndex: 'siteName',
                key: 'siteName',
            },
            {
                title: '警报类型',
                dataIndex: 'alarmType',
                key: 'alarmType',
            },
            {
                title: '风险等级',
                dataIndex: 'riskLevel',
                key: 'riskLevel',
            },
        {
            title: '警报数量',
            key: 'count',
            dataIndex: 'count',
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: 'action',
            render: (imgPath, record) => (
                <span className='tableA' onClick={detailsSkip.bind(this,record)}>查看报警记录详情</span>
            )
        },
    ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [washList, setWashList] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    useEffect(() => {
        washSpotList()
        getAIVideoSiteList()
        getWashList()
        getAlarmTypes()
        getAlarmRiskLevel()
    }, [washSpotId,time])
    //获取员工列表
    const getWashList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            startDate:startTime,
            endDate: endTime,
            siteId: searchSiteId,
            sitePositionId: searchSitePositionId,
            alarmType: alarmTypes,
            riskLevel: alarmRiskLevel,
            searchSiteValue: searchSiteValue,
            sitePigpenId: searchSitePigpenId,
            pageNo: pageNo,
            pageSize: pageSize
        }
        if (!ButtonType && time){
            setSearchSiteValue(washSpotId)
            setEndTime(moment(time).format("YYYY-MM-DD"))
            setStartTime(moment(time).format("YYYY-MM-DD"))
            params.startDate = moment(time).format("YYYY-MM-DD")
            params.endDate = moment(time).format("YYYY-MM-DD")
            params.siteId = washSpotId
        }
        // if (typeof siteType === 'number'){
        //     params.siteId = siteId
        //     params.sitePositionId = sitePositionId
        //     params.sitePigpenId = sitePigpenId
        // }
        if (ButtonType === 'reset') {
            params = {
                startDate:startTime,
                endDate: endTime,
                pageNo: pageNo,
                pageSize: pageSize
            }
        }
        _request.getAiAlarmStatistics(params).then(res => {
            let arr = res.list
            // arr.forEach(item =>{
            //     if ((params.riskLevel || params.alarmType) && !params.searchSiteValue){
            //         item.siteName = '所有区域'
            //     }
            //     if ((params.searchSiteValue || params.alarmType) && !params.riskLevel){
            //         item.riskLevel = '所有等级'
            //     }
            //     if ((params.searchSiteValue || params.riskLevel) && !params.alarmType){
            //         item.alarmType = '所有类型'
            //     }
            // })
            setTableTotal(res.total || 0)
            setTableData(arr)
        })
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getWashList('search',1,10)
    }


    //递归对比
    const searchComparison = (e) =>{
        setSearchSiteId('')
        setSearchSiteType('')
        setSearchSitePositionId('')
        setSearchSitePigpenId('')
        let siteId = e.split('-')[1]
        let ID = e.split('-')[0]
        AIVideoSiteList.forEach(item =>{
            if (item.siteId === e){
                setSearchSiteId(item.siteId)
            }else if (item.positionList && item.positionList.length > 0){
                item.positionList.forEach(j =>{
                    if (j.sitePositionId === ID && j.siteId === siteId){
                        setSearchSiteId(j.siteId)
                        setSearchSitePositionId(j.sitePositionId)
                    }
                })
            }else if (item.pigpenList && item.pigpenList.length > 0){
                item.pigpenList.forEach(j =>{
                    if (j.sitePigpenId === ID && j.siteId === siteId){
                        setSearchSiteId(j.siteId)
                        if (j.type === 0){
                            setSearchSitePigpenId(j.sitePigpenId)
                        }else {
                            setSearchSitePositionId(j.sitePigpenId)
                        }
                    }else {
                        if (j.positionList){
                            j.positionList.forEach(i =>{
                                if (i.sitePositionId === ID && i.siteId === siteId){
                                    setSearchSiteId(i.siteId)
                                    setSearchSitePositionId(i.sitePositionId)
                                }
                            })
                        }
                    }
                })
            }
        })
    }

    const getAIVideoSiteList = () => {
        _request.getAIVideoSiteList().then(res =>{
            setAIVideoSiteList(res)
            /*if (washSpotId){
                setSearchSiteValue(washSpotId)
                res.forEach(item =>{
                    if (item.siteId === washSpotId){
                        setSearchSiteId(item.siteId)
                        getWashList(null,1, 10,item.siteId)
                    }else if (item.positionList && item.positionList.length > 0){
                        item.positionList.forEach(j =>{
                            if (j.sitePositionId === washSpotId){
                                setSearchSiteId(j.siteId)
                                setSearchSitePositionId(j.sitePositionId)
                                getWashList(null,1, 10,j.siteId,j.sitePositionId)
                            }
                        })
                    }else if (item.pigpenList && item.pigpenList.length > 0){
                        item.pigpenList.forEach(j =>{
                            if (j.sitePigpenId === washSpotId){
                                setSearchSiteId(j.siteId)
                                setSearchSitePigpenId(j.sitePigpenId)
                                getWashList(null,1, 10,j.siteId,null,j.sitePigpenId)
                            }else {
                                j.positionList.forEach(i =>{
                                    if (i.sitePositionId === washSpotId){
                                        setSearchSiteId(i.siteId)
                                        setSearchSitePositionId(i.sitePositionId)
                                        getWashList(null,1, 10,i.siteId,i.sitePositionId)
                                    }
                                })
                            }
                        })
                    }
                    // item.siteList.forEach(i =>{
                    //     if (i.siteId === washSpotId){
                    //         getWashList(null,1, 10,i.siteType,i.siteId,'')
                    //         setSearchSiteId(i.siteId)
                    //         setSearchSiteType(i.siteType)
                    //     }else {
                    //         if (i.sitePositionList){
                    //             i.sitePositionList.forEach(j =>{
                    //                 if (j.sitePositionId === washSpotId){
                    //                     getWashList(null,1, 10,j.siteType,'',j.sitePositionId)
                    //                     setSearchSitePositionId(j.sitePositionId)
                    //                     setSearchSiteType(j.siteType)
                    //                 }
                    //             })
                    //         }
                    //     }
                    // })
                })
            }*/
        })
    }

    //详情跳转
    const detailsSkip = (e) => {
        let param = {
            key: '158&AI报警记录'
        }
        let obj = {
            startDate:startTime,
            endDate: endTime,
            siteId: e.siteId,
            sitePositionId: searchSitePositionId,
            sitePigpenId: searchSitePigpenId,
            alarmType: e.alarmType,
            riskLevel: e.riskLevel,
        }
        nextClick(param,obj)
    }

    //重置
    const clickReset = () => {
        setName('')
        setWorkflowId()
        setStartTime(moment(new Date()).format('YYYY-MM-DD'))
        setEndTime(moment(new Date()).format('YYYY-MM-DD'))
        setSearchSiteId('')
        setSearchSiteType('')
        setSearchSitePositionId('')
        setSearchSitePigpenId('')
        setSearchSiteValue(null)
        setAlarmRiskLevel()
        setAlarmTypes()
        setPageNo(1)
        setPageSize(10)
        getWashList('reset',1,10)
    }
    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getWashList('',pageNo,pageSize);
    }

    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }


    //获取警报类型
    const getAlarmTypes = () =>{
        _request.getAlarmTypes().then(res => {
            const arr = res.map(i =>{
                return {name: i}
            })
            setAlarmTypesList(arr)
        })
    }
    //获取风险等级
    const getAlarmRiskLevel = () =>{
        _request.getAlarmRiskLevel().then(res => {
            const arr = res.map(i =>{
                return {name: i}
            })
            setAlarmRiskLevelList(arr)
        })
    }

    //日期控件搜索
    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }


    function rangeTimeChange(date){
        if(date){
            if(date[0]!=undefined){
                setStartTime(date[0].format('YYYY-MM-DD'));
            }else{
                setStartTime('');
            }
            if(date[1]!=undefined){
                setEndTime(date[1].format('YYYY-MM-DD'));
            }else{
                setEndTime('');
            }
        }
    }


    return (
        <Container>
            <div className="top">
                <TreeSelect
                    style={{ width: 150, marginRight: 10 }}
                    dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                    placeholder="请选择区域"
                    value={searchSiteValue}
                    onChange={(value,options,extra)=>{
                        setSearchSiteValue(value)
                        searchComparison(value)
                    }}
                >
                    {
                        AIVideoSiteList.length > 0 ?
                            AIVideoSiteList.map((item,index) =>{
                                return <TreeNode value={item.siteId} title={item.siteName} key={item.siteId}>
                                    {
                                        item.positionList && item.positionList.length > 0?item.positionList.map(i =>{
                                            return <TreeNode value={i.sitePositionId+'-'+i.siteId} title={i.sitePositionName} key={i.sitePositionId}>
                                            </TreeNode>
                                        }):(item.pigpenList && item.pigpenList.length > 0?item.pigpenList.map(i =>{
                                            return <TreeNode value={i.sitePigpenId+'-'+i.siteId} title={i.sitePigpenName} key={i.sitePigpenId}>
                                                {
                                                    i.positionList && i.positionList.length > 0?i.positionList.map(j =>{
                                                        return <TreeNode value={j.sitePositionId+'-'+j.siteId} title={j.sitePositionName} key={j.sitePositionId} />
                                                    }):''
                                                }
                                            </TreeNode>
                                        }):'')
                                    }
                                </TreeNode>
                            })
                            :''
                    }
                </TreeSelect>
                <Select placeholder="请选择报警类型" style={{width: 150, marginRight: 10}} showSearch value={alarmTypes}
                        onChange={e => setAlarmTypes(e)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                    {
                        alarmTypesList.map((item, index) => {
                            return <Option key={item.name} value={item.name}>{item.name}</Option>
                        })
                    }
                </Select>
                <RangePicker
                    ranges={{
                        '今天': [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                        '一个月': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD"), moment(endTime, "YYYY-MM-DD")]}
                    /*disabledTime={disabledRangeTime}*/
                    onChange={rangeTimeChange}
                    showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                    }}
                    format="YYYY-MM-DD"
                />
                <Select placeholder="请选择风险等级"
                        mode="multiple"
                        style={{width: 150, marginRight: 10, marginLeft: 10}} showSearch value={alarmRiskLevel}
                        onChange={e => setAlarmRiskLevel(e)}>
                    {
                        alarmRiskLevelList.map((item, index) => {
                            return <Option key={item.name} value={item.name}>{item.name}</Option>
                        })
                    }
                </Select>

                &nbsp;&nbsp;
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div style={{marginBottom: 5}}>合计&nbsp;{tableTotal}&nbsp;条</div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>

        </Container>

    )
}

export default Form.create()(AIWarnStatistics)
