import React, {useEffect, useState} from "react";
import {Container} from './style'
import {Form, Button, Input, message, DatePicker, Popconfirm, Modal} from "antd";
import ShareRequest from "../../request/share-request";
import moment from "moment";

const _request = new ShareRequest();
const {RangePicker} = DatePicker;
const {TextArea} = Input;

function ShareCommunication(props) {
    const {getFieldDecorator, getFieldValue, setFieldsValue, validateFields} = props.form

    const [startDate, setStartDate] = useState(moment().startOf('day').subtract(6, 'days'))
    const [endDate, setEndDate] = useState(moment().endOf('day'))
    const [name, setName] = useState('')
    const [messageList, setMessageList] = useState([])
    const [messageList2, setMessageList2] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [pageNo2, setPageNo2] = useState(1)
    const [isAffirm, setIsAffirm] = useState(false)
    const [visible, setVisible] = useState(false)
    const [inquireName, setInquireName] = useState(false)

    useEffect(() => {
        getMessageList()
        getMessageList2()
    }, [])

    const getMessageList = (reset, e) => {
        let param = {
            startTime: moment(startDate).format('YYYY/MM/DD HH:mm:ss'),
            endTime: moment(endDate).format('YYYY/MM/DD HH:mm:ss'),
            regionId: sessionStorage.getItem('regionId'),
            nickname: name,
            size: 10,
            current: pageNo
        }
        if (e) {
            param.pageNo = e
        }
        if (reset) {
            setMessageList([])
            param = {
                startTime: moment(startDate).format('YYYY/MM/DD HH:mm:ss'),
                endTime: moment(endDate).format('YYYY/MM/DD HH:mm:ss'),
                nickname: '',
                regionId: sessionStorage.getItem('regionId'),
                size: 10,
                current: 1
            }
        }
        _request.getMessageBoard(param).then(res => {
            if (reset === 'reset'){
                setMessageList(res.records)
            }else {
                if (messageList.length === 0) {
                    setMessageList(res.records)
                } else {
                    setMessageList(messageList.concat(res.records))
                }
            }
        })
    }
    const getMessageList4 = () => {
        let param = {
            startTime: moment(startDate).format('YYYY/MM/DD HH:mm:ss'),
            endTime: moment(endDate).format('YYYY/MM/DD HH:mm:ss'),
            regionId: sessionStorage.getItem('regionId'),
            nickname: name,
            size: 10,
            current: 1
        }
        _request.getMessageBoard(param).then(res => {
            setMessageList(res.records)
        })
    }
    const getMessageList2 = (e) => {
        let param = {
            regionId: sessionStorage.getItem('regionId'),
            size: 10,
            current: pageNo2
        }
        if (e) {
            param.current = e
        }
        _request.getMessageBoard(param).then(res => {
            if (messageList2.length === 0) {
                setMessageList2(res.records)
            } else {
                setMessageList2(messageList2.concat(res.records))
            }
        })
    }
    const getMessageList3 = () => {
        let param = {
            regionId: sessionStorage.getItem('regionId'),
            size: 10,
            current: 1
        }
        _request.getMessageBoard(param).then(res => {
            setMessageList(res.records)
            setMessageList2(res.records)
        })
    }

    const rangeTimeChange = (date) => {
        if (date) {
            if (date[0] != undefined) {
                setStartDate(date[0]);
            } else {
                setStartDate('');
            }
            if (date[1] != undefined) {
                setEndDate(date[1]);
            } else {
                setEndDate('');
            }
        }
    }


    const myFunction = (e) => {
        e.persist()
        let scrollHeight = e.target.scrollHeight
        let scrollTop = e.target.scrollTop
        let subtraction = scrollHeight - scrollTop
        if (parseInt(subtraction) === e.target.clientHeight) {
            setPageNo(pageNo + 1)
            getMessageList(null, pageNo + 1)
        }
    }
    const myFunction2 = (e) => {
        e.persist()
        let scrollHeight = e.target.scrollHeight
        let scrollTop = e.target.scrollTop
        let subtraction = scrollHeight - scrollTop
        if (parseInt(subtraction) === e.target.clientHeight) {
            setPageNo2(pageNo2 + 1)
            getMessageList2(pageNo2 + 1)
        }
    }

    const sendClick = () => {
        validateFields((err, value) => {
            if (!err) {
                let param = {
                    sysCode: 'safety',
                    regionId: sessionStorage.getItem('regionId'),
                    content: getFieldValue('content')
                }
                _request.addMessageBoard(param).then(res => {
                    // getMessageList()
                    // getMessageList2()
                    getMessageList3()
                    setFieldsValue({
                        content: ''
                    })
                    message.info('发送成功！')
                })
            }
        })
    }

    //撤回
    const withdraw = (id) => {
        _request.delMessageBoard(id).then(res => {
            getMessageList3()
            message.info('撤回成功！')
        })
        // _request.getSelectOne().then(res => {
        //     if (res) {
        //         let arr = []
        //         arr[0] = res
        //         setMessageList(arr)
        //         setVisible(true)
        //     } else {
        //         // setMessageList([])
        //         message.error('没有可撤回消息！')
        //         getMessageList3()
        //         setIsAffirm(true)
        //     }
        // })
    }

    const affirmWithdraw = () => {
        let param = {
            id: messageList[0].id
        }
        _request.delMessageBoard(param).then(res => {
            getMessageList3()
            // getMessageList2()
            message.info('撤回成功！')
            setVisible(false)
        })
    }
    const cancelModal = () => {
        setVisible(false)
        getMessageList3()
    }
    //查询
    const inquire = () => {
        setInquireName(true)
        getMessageList4()
    }
    //重置
    const reset = () => {
        setStartDate(moment().startOf('day').subtract(6, 'days'))
        setEndDate(moment().endOf('day'))
        setName('')
        getMessageList('reset')
    }

    return (
        <Container>
            <div className='shareLeft'>
                <div>
                    <Input placeholder='发送人' style={{width: '150px'}} value={name}
                           onChange={(e) => setName(e.target.value)}/>
                    <RangePicker
                        style={{marginLeft: 10}}
                        // ranges={{
                        //     '今天': [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                        //     // '过去一周': [moment(new Date(),'DD') - 7, moment('23:59:59', 'HH:mm:ss')],
                        //     '一个月': [moment().startOf('month'), moment().endOf('month')],
                        // }}
                        value={startDate === '' || endDate === '' ? null : [startDate, endDate]}
                        /*disabledTime={disabledRangeTime}*/
                        onChange={rangeTimeChange}
                        format="YYYY-MM-DD"
                    />
                    <div style={{float: 'right', marginRight: 10}}>
                        <Button style={{marginRight: 10}} className='buttonReset' onClick={reset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
                        <Button type='primary' onClick={inquire}><span className='iconfont icon-sousuo buttonIcon'></span>查询</Button>
                    </div>
                </div>
                <div style={{borderTop: '1px solid #E2E2E2', marginTop: 20, height: '100%'}}>
                    <div style={{display: 'flex',marginTop: 10,justifyContent: 'space-between'}}>
                        <div>
                            已为您搜索到
                            <span> {moment(startDate).format('MM-DD')} </span>
                            至
                            <span> {moment(endDate).format('MM-DD')} </span>
                            {
                                name !== '' && inquireName?
                                    <span>关于“<span style={{color: '#2F7ECF',fontSize: '16px'}}>{name}</span>”</span>:''
                            }
                            的信息，共
                            <span style={{color: '#2F7ECF',fontSize: '18px'}}> {messageList.length}</span> 条
                        </div>
                        <div>
                            {/*<span className='iconfont icon-chehui' style={{color: '#272829',marginRight: '5px',fontSize: '14px'}}></span>*/}
                            {/*<span style={{cursor: 'pointer', color: '#272829'}} onClick={withdraw}>撤回上一条</span>*/}
                        </div>
                    </div>
                    <div className='content' onScroll={myFunction}>
                        {
                            messageList.length === 0 ? '' :
                                messageList.map((item, index) => {
                                    return (
                                        <div className='content-div'>
                                            <div>
                                                <div className='content-name'>
                                                    <span>{item.nickname}</span>
                                                    <span
                                                        style={{marginLeft: 10}}>{moment(item.createdTime).format('YYYY/MM/DD HH:mm:ss')}</span>
                                                </div>
                                                <div className='content-text'>{item.content}</div>
                                            </div>
                                            {
                                                item.resetFlag?
                                                    <div>
                                                        <span className='iconfont icon-chehui' style={{color: '#272829',marginRight: '5px',fontSize: '14px'}}></span>
                                                        <span style={{cursor: 'pointer', color: '#272829'}} onClick={withdraw.bind(this,item.id)}>撤回</span>
                                                    </div>
                                                    :<div></div>
                                            }
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
            <div className='shareRight'>
                <div className='messageDiv' onScroll={myFunction2}>
                    {
                        messageList2.length === 0 ?
                            <div style={{textAlign: 'center', lineHeight: '190px'}}>暂无数据</div>
                            :
                            messageList2.map(item => {
                                return (
                                    <div className='messageDiv-div'>
                                        <div className='titleDate'>
                                            <span className='titleDate-title'>{item.nickname}</span>
                                            <span
                                                className='titleDate-date'>{moment(item.createdTime).format("YYYY/MM/DD HH:mm:ss")}</span>
                                        </div>
                                        <div className='content'>{item.content}</div>
                                    </div>
                                )
                            })
                    }
                </div>
                <div className='inputDiv'>
                    <div className='TextAreaDiv'>
                        <Form>
                            <Form.Item>
                                {getFieldDecorator('content', {
                                    rules: [{required: true, message: '请输入内容！'}, {
                                        pattern: /^[^\s]*$/,
                                        message: '禁止输入空格！'
                                    }],
                                })(
                                    <TextArea className='TextArea' placeholder='请输入内容' maxLength='150'
                                              onPressEnter={sendClick}/>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='sendButton'>
                        <Button type="primary" size='small' onClick={sendClick}>发送</Button>
                    </div>
                </div>
            </div>
            <Modal
                // title='确认撤回？'
                visible={visible}
                onCancel={cancelModal}
                onOk={affirmWithdraw}
                closable={false}
                width={'200px'}
            >
                <div style={{width: '100%',textAlign: 'center',fontWeight: 600}}>确认撤回吗？</div>
            </Modal>
        </Container>
    )
}


export default Form.create()(ShareCommunication)
