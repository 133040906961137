import request from '../https'

class AuditRequest{
    //人员审核
    VisitorConfirm(param){
        return request({
            url: 'safety/visitor/confirm',
            method: 'POST',
            data: param
        })
    }
    //获取临时访客登记列表
    getVisitorList(param){
        return request({
            url: 'safety/visitor/list',
            method: 'POST',
            data: param
        })
    }
    //获取临时访客登记列表
    getVisitorListHome(param){
        return request({
            url: 'safety/visitor/list/home',
            method: 'POST',
            data: param
        })
    }
    //获取车辆信息列表
    getTempCarList(param){
        return request({
            url: 'safety/tempCarInfo/list',
            method: 'POST',
            data: param
        })
    }
    //临时车辆审批
    confirmCar(param){
        return request({
            url: 'safety/tempCarInfo/approval',
            method: 'POST',
            data: param
        })
    }
}

export default AuditRequest