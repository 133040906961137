import React, { useState, useEffect } from 'react'
import {Container} from './style'

import {Button, Input, Select, Modal, Table, Popconfirm, Col, Form, message, Icon, Popover} from 'antd'

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import ElectricMeterHttp from '../../../request/electricmeter-request'
import CommonHttp from '../../../request/common-request'


const Option = Select.Option;
const _request = new ElectricMeterHttp()
const _commonrequest = new CommonHttp()

function ElectricMeter(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [detailVisible, setDetailVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [washSpotName, setWashSpotName] = useState('')
    const [equipmentNo, setEquipmentNo] = useState('')

    const tableColunm =
        [{
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '电表位置',
                key: 'equipmentPosition',
                dataIndex: 'equipmentPosition',
                render: (text, record, index) => (
                	electricPositionData.map((item,index1) => {
						if(record.equipmentPosition === item.dictValue) {
							return  <span>{item.dictDesc}</span>
						}
					})

                )
            },
            {
                title: '设备号',
                dataIndex: 'equipmentNo',
                key: 'equipmentNo',
            },
            {
                title: '在线状态',
                key: 'isOnline',
                dataIndex: 'isOnline',
                render: (text, record, index) => (
                    <div style={{ position: 'relative' }}>
                        {
                            text !== null?text?
                                <Popover content={'最后通讯时间：' + (record.offlineTime?moment(record.offlineTime).format("YYYY-MM-DD HH:mm:ss"):'-')} title="设备在线">
                                    <span style={{color: 'green'}}>在线</span>
                                </Popover>
                                :<Popover content={'设备离线时间：' + (record.offlineTime?moment(record.offlineTime).format("YYYY-MM-DD HH:mm:ss"):'-')} title="设备已离线">
                                    <span style={{color: 'red'}}>离线</span>
                                </Popover>:'-'
                        }
                    </div>
                )
            },
            {
                title: '操作人',
                key: 'createUser',
                dataIndex: 'createUser',
            },
            {
                title: '操作日期',
                dataIndex: 'createDate',
                key: 'createDate',
				render: (text, record, index) => {
					return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <a style={{ marginRight: 8 }} className='tableA' href='javascript:;' onClick={openDetailModal.bind(this, 'detail', record.equipmentNo, 1, 10)}>详情</a>
                        <a style={{ marginRight: 8 }} className='tableA' href='javascript:;' onClick={openModal.bind(this, 'edit', record)}>编辑</a>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                            <a className='tableADelete' href='javascript:;'>删除</a>
                        </Popconfirm>
                    </span>
                ),
            },
        ]




        const tableDetailColunm =
        [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '设备号',
                dataIndex: 'electricId',
                key: 'electricId',
            },
            {
                title: '值',
                key: 'electricValue',
                dataIndex: 'electricValue',
            },
            {
                title: '操作日期',
                dataIndex: 'createDate',
                key: 'createDate',
				render: (text, record, index) => {
					return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
        ]
     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableDetailTotal, setTableDetailTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [electricPositionData, setElectricPositionData] = useState([]) //智能电表位置列表
    const [washSpotData, setWashSpotData] = useState([]) //洗消点列表
    const [tableDetailData, setTableDetailData] = useState([]) //使用详情表格数据
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const [detailEquipmentNo, setDetailEquipmentNo] = useState('')

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getElectricMeterList()
        getDictList()
        getWashSpotList()
    }, [])
    //获取列表
    const getElectricMeterList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            washSpotName:washSpotName,
            equipmentNo:equipmentNo,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getElectricMeterList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //获取智能电表位置列表
    const getDictList = () => {
        let params = {
           dictType:'EQUIPMENT_POSITION'
        }
        _commonrequest.getDictList(params).then(res => {
            setElectricPositionData(res.list)
        })
    }

     //获取当前登录用户下的洗消点列表
    const getWashSpotList = () => {
        let params = {
        }
        _commonrequest.getWashSpotList(params).then(res => {
            setWashSpotData(res)
        })
    }

    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteElectricMeter(params).then(res => {
            message.info('删除成功！')
            getElectricMeterList()
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){
            setTimeout(() => {
                setFieldsValue({
                    equipmentPosition: record.equipmentPosition,
                    equipmentNo: record.equipmentNo,
                    washSpotId: record.washSpotId,
                    id: record.id
                })
            }, 0)
        }

    }

     //打开详情页
    const openDetailModal = (type, equipmentNo, pageNo = 1, pageSize = 10) => {
        setDetailEquipmentNo(equipmentNo)
        setDetailVisible(true)
        let params = {
            equipmentNo:equipmentNo,
            pageNo:pageNo,
            pageSize:pageSize
        }
        _request.getElectricMeterDetail(params).then(res => {
            setTableDetailTotal(res.total || 0)
            setTableDetailData(res.list)
        })
    }

    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                let washSpotName = washSpotData.filter(item => item.id === getFieldValue('washSpotId'))[0].name
                if (modalType === 'add') {
                    let params = {
                        equipmentPosition: getFieldValue('equipmentPosition'),
                        equipmentNo: getFieldValue('equipmentNo'),
                        washSpotId: getFieldValue('washSpotId'),
                        washSpotName: washSpotName
                    }
                    _request.addElectricMeter(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getElectricMeterList()
                    })
                } else {
                    let params = {
                        equipmentPosition: getFieldValue('equipmentPosition'),
                        equipmentNo: getFieldValue('equipmentNo'),
                        washSpotId: getFieldValue('washSpotId'),
                        washSpotName: washSpotName,
                        id: getFieldValue('id')
                    }
                    _request.updateElectricMeter(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getElectricMeterList()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //详情页取消
    const cancelDetail = () => {
        setDetailVisible(false)
        setConfirmLoading(false)
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getElectricMeterList()
    }
    //重置
    const clickReset = () => {
		setWashSpotName('')
		setEquipmentNo('')
        setPageNo(1)
        setPageSize(10)
        getElectricMeterList('reset')
    }

    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getElectricMeterList('',pageNo,pageSize);
    }

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入洗消点" style={{ width: 200, marginRight: 10 }} value={washSpotName} onPressEnter={clickSearch} onChange={(e) => setWashSpotName(e.target.value)}></Input>
                <Input placeholder="请输入设备号" style={{ width: 200, marginRight: 10 }} value={equipmentNo} onPressEnter={clickSearch} onChange={(e) => setEquipmentNo(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增智能电表' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ height: 200 }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备号：">
                                {getFieldDecorator('equipmentNo', {
                                    rules: [{ required: true, message: '请输入设备号！' }],
                                })(
                                    <Input
                                        placeholder="请输入设备号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                       <Col span={12}>
							<Form.Item {...formItemLayout} label="智能电表位置：">
								{getFieldDecorator('equipmentPosition', {
									rules: [{ required: true, message: '请选择智能电表位置!' }],
								})(
									<Select >
										{
							  				electricPositionData.map((item,index) => {
								  				return (
								  					<Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item {...formItemLayout} label="洗消点：">
								{getFieldDecorator('washSpotId', {
									rules: [{ required: true, message: '请选择洗消点!' }],
								})(
									<Select >
										{
							  				washSpotData.map((item,index) => {
								  				return (
								  					<Option value={item.id} key={item.id} >{item.name}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                    </Form>
                    :
                    <Form style={{ height: 200 }}>
                        <Form.Item>
                            {getFieldDecorator('id')(
                                <span></span>
                            )}
                        </Form.Item>
                         <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备号：">
                                {getFieldDecorator('equipmentNo', {
                                    rules: [{ required: true, message: '请输入设备号！' }],
                                })(
                                    <Input
                                        placeholder="请输入设备号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                       <Col span={12}>
							<Form.Item {...formItemLayout} label="智能电表位置：">
								{getFieldDecorator('equipmentPosition', {
									rules: [{ required: true, message: '请选择智能电表位置!' }],
								})(
									<Select >
										{
							  				electricPositionData.map((item,index) => {
								  				return (
								  					<Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item {...formItemLayout} label="洗消点：">
								{getFieldDecorator('washSpotId', {
									rules: [{ required: true, message: '请选择洗消点!' }],
								})(
									<Select >
										{
							  				washSpotData.map((item,index) => {
								  				return (
								  					<Option value={item.id} key={item.id} >{item.name}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                    </Form>
                }
            </Modal>

             <Modal
                title={'电表使用详情'}
                visible={detailVisible}
                onCancel={cancelDetail}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='detailClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>

                    </div>
                }
            >

            <div className="bottom">
			    <DeTable columns={tableDetailColunm} total={tableDetailTotal} onPaginationChange={(pageNo, pageSize) => openDetailModal('', detailEquipmentNo, pageNo, pageSize)}  dataSource={tableDetailData} />
			</div>

            </Modal>
        </Container>

    )
}

export default Form.create()(ElectricMeter)
