import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------员工管理
    //获取水表列表
    getWaterList(params) {
        return request({
            url: '/safety/water/list',
            method: 'post',
            params
        })
    }
	//获取当前登录用户下的猪场集合
	getPigfarmList(params){
		return request({
		    url: '/safety/area/getChildrenRegionInfo',
		    method: 'post',
		    params
		})
	}
	
    //新增水表
    addWaterInfo(params) {
        return request({
            url: '/safety/water/add',
            method: 'post',
            params
        })
    }
    //编辑水表
    editWaterInfo(params) {
        return request({
            url: '/safety/water/update',
            method: 'post',
            params
        })
    }
    //删除水表
    deleteWaterInfo(params) {
        return request({
            url: '/safety/water/delete',
            method: 'post',
            params
        })
    }
	
	//获取水表使用记录
	waterRecordList(params) {
	    return request({
	        url: '/safety/waterRecord/list',
	        method: 'post',
	        params
	    })
	}
}

export default StoreSettingHttp