import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import { Row, Col, Table, Tree, Divider, Button, Input, message } from 'antd'
import SystemRequest from '../../../request/system-request'
import PowerButton from '../../../component/PowerButton'
import ModalSource from './subpage/index'
import ModalPower from './subpage/modelpower'
import moment from "moment";
import {Container} from './style'
import DeTable from '../../../component/DeTable/index'

const Search = Input.Search;
const TreeNode = Tree.TreeNode;
const sys_request = new SystemRequest();
let tmpcheckIds = []

class Role extends React.Component {
	constructor(props) {
		super(props);
		this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
		this.state = {
			currentPage: 1,
			organlist: [],
			tdata: [],
			table: {
				pageSize: 10,
				pageNo: 1,
				total: 0
			},
			selectedNodes: null,
			tcolumn: [],
			showTable: false,
			ids: '',
			isShow: false,
			isShow2: false,
			modaltitle: '',
			tid: '',
			pid: '',
			type: 'add',
			organizechoosedata: {},
			searchname: '',
			powerData: [],
			urlpid: '',
			checkIds: [] //选中的权限
		}
	}
	componentWillMount() {
		this.setState({ urlpid: this.props.pageId })
		sys_request.organizeList({
			parentId: ''
		}).then((res) => {
			this.setState({
				organlist: res
			}, () => {
				this.onSelect([this.state.organlist[0].regionId])
			})
		})
	}
	onLoadData(treeNode) {
		let id = treeNode.props.eventKey;
		return new Promise((resolve) => {
			if (treeNode.props.children) {
				resolve();
				return;
			}
			setTimeout(() => {
				sys_request.organizeList({
					parentId: id
				}).then((res) => {
					if (res) {
						let childrentmp = [];
						for (var i = 0; i < res.length; i++) {
							childrentmp.push({ regionName: res[i].regionName, regionId: res[i].regionId })
						}
						treeNode.props.dataRef.children = childrentmp;
						this.setState({
							organlist: [...this.state.organlist],
						});
						resolve();
					}

				})

			}, 500);
		});

	}
	renderTreeNodes(data) {
		return data.map((item) => {
			if (item.children) {
				return (
					<TreeNode title={item.regionName} key={item.regionId} dataRef={item}>
						{this.renderTreeNodes(item.children)}
					</TreeNode>
				);
			}
			return <TreeNode title={item.regionName} key={item.regionId} dataRef={item} />;
		});
	}
	onSelect(selectedKeys, info) {
		//console.log(selectedKeys.constructor==Array)
		if (info) {
			this.setState({
				selectedNodes: info.selectedNodes[0].props.dataRef
			})
		}
		if (selectedKeys != "") {
			this.setState({
				showTable: true,
				pid: selectedKeys[0],
				tdata: []
			})
			sys_request.roleList({
				pageSize: this.state.table.pageSize,
				pageNo: this.state.table.pageNo,
				regionId: selectedKeys[0],
				roleName: this.state.searchname
			}).then((res) => {
				this.setState({
					currentPage: 1,
					table: {
						total: res.total
					},
					tdata: res.list,
					tcolumn: [{
						title: '序号',
						render: (text, record, index) => (
							<span>{index + 1}</span>
						),
						dataIndex: 'number',
					},{
						title: '角色名称',
						dataIndex: 'roleName',
						sorter: true
					}, {
						title: '用户状态',
						dataIndex: 'isValid',
						render: (text, record, index) => (
							<span>
								{text == 1 ? <span>有效</span> : <span>无效</span>}
							</span>
						)
					}, {
						title: '创建用户',
						dataIndex: 'createUser'
					}, {
						title: '操作时间',
						dataIndex: 'createDate',
						render: text => {
							return moment(text).format("YYYY-MM-DD HH:mm:ss");
						}
					}, {
						title: '操作',
						render: (text, record, index) => (<span><PowerButton
							pageId={this.state.urlpid}
							btnType='update'
							size="small"
							onClick={this.singleEdit.bind(this, record, index)}
							title='编辑' >
						</PowerButton>
							<Divider type="vertical" /><Button size="small" style={{ background: '#5FA944', borderColor: '#5FA944' }} type="primary" onClick={this.singlePower.bind(this, record, index)}>授权</Button>
							<Divider type="vertical" />
							<PowerButton
								pageId={this.state.urlpid}
								btnType='delete'
								size="small"
								type="danger"
								onClick={this.singleDel.bind(this, record, index)}
								title='删除' >
							</PowerButton></span>)
					}]
				})
			})
		}
	}
	singleEdit(record, index) {
		this.setState({
			isShow: true,
			tid: record.roleId,
			type: 'edit',
			modaltitle: '编辑角色',
			organizechoosedata: record
		})
	}
	//递归取值
	getcheckedIds(res) {
		res.map((item) => {
			if (item.itemType == 2 || item.itemType == 1) {
				if (item.childMenu.length == 0) {
					if (item.isSelect == '1') {
						tmpcheckIds = [...tmpcheckIds, '' + item.itemId + '']
					}
				} else {
					{ this.getcheckedIds(item.childMenu) }
				}
			} else {
				if (item.isSelect == '1') {
					tmpcheckIds = [...tmpcheckIds, '' + item.itemId + '']
				}
			}

		})
		this.setState({ checkIds: tmpcheckIds })
	}
	singlePower(record, index) {
		this.setState({
			checkIds: []
		});
		tmpcheckIds = [];
		sys_request.roleMenuList({
			roleId: record.roleId
		}).then((res) => {
			this.setState({
				powerData: res,
				isShow2: true,
				tid: record.roleId,
				type: 'edit',
				modaltitle: '角色授权',
				organizechoosedata: record
			})
			this.getcheckedIds(res)
		})
	}
	singleDel(record, index) {
		sys_request.deletePower({ id: record.roleId }).then((res) => {
			const dataSource = [...this.state.tdata];
			dataSource.splice(index, 1);//index为获取的索引，后面的 1 是删除几行
			this.setState({ tdata: dataSource });
		})

	}
	// showIds(callback) {
	// 	this.setState({
	// 		ids: callback
	// 	})
	// }
	setIsShow(callback) {
		this.setState({
			isShow: callback
		})
	}
	setIsShow2(callback) {
		this.setState({
			isShow2: callback
		})
	}
	setValues(callback) {
		if (this.state.type === 'add') {
			sys_request.addRole({
				roleName: callback.roleName,
				regionId: this.state.tid
			}).then((res) => {
				this.onSelect([this.state.pid])
				message.success('新增保存成功');
			})
		} else {
			sys_request.updateRole({
				roleName: callback.roleName,
				isValid: callback.isValid,
				roleId: this.state.tid
			}).then((res) => {
				this.onSelect([this.state.pid])
				message.success('编辑保存成功');
			})
		}

	}
	addNewModal() {
		if (!this.state.selectedNodes) return message.error("请选择上级角色!");
		this.setState({
			isShow: true,
			tid: this.state.pid,
			type: 'add',
			modaltitle: '新增角色',
			organizechoosedata: ''
		})
	}
	searchOranize(value) {
		this.setState({
			searchname: value
		}, () => {
			this.onSelect([this.state.pid]);
		})
	}

	setPower(callback) {
		sys_request.savePower({
			roleId: this.state.tid,
			menuId: callback
		}).then((res) => {
			message.success('保存权限成功');
		})
	}
	render() {
		return (
			<Container>
				<Row gutter={16} style={{ height: '100%' }}>
					<Col span={4} style={{ borderRight: '1px solid #ddd', height: '100%', overflowX: 'auto' }}>
						<Tree loadData={this.onLoadData.bind(this)} showLine onSelect={this.onSelect.bind(this)} style={{ height: '100%' }}>
							{this.renderTreeNodes(this.state.organlist)}
						</Tree>
					</Col>
					<Col style={{ height: '100%' }} span={20}>
						{
							this.state.showTable
								?
								<React.Fragment>
									<div style={{ margin: '0 0 10px 0' }}>
										<PowerButton
											pageId={this.state.urlpid}
											btnType='add'
											onClick={this.addNewModal.bind(this)}
											title='新增' >
										</PowerButton> <Search
											placeholder="请输入角色名称"
											onSearch={value => this.searchOranize(value)}
											enterButton
											style={{ width: '200px' }}
										/>
									</div>
									<div className="bottom">
										<DeTable dataSource={this.state.tdata} columns={this.state.tcolumn} pagination={{ current: this.state.currentPage, onChange: (page) => this.setState({ currentPage: page }), showSizeChanger: true, showTotal: (total) => `共 ${total} 条` }}></DeTable>
									</div>
								</React.Fragment>
								:
								''
						}

					</Col>
				</Row>
				<ModalSource type={this.state.type} organizechoosedata={this.state.organizechoosedata} isShow={this.state.isShow} setValues={this.setValues.bind(this)} setIsShow={this.setIsShow.bind(this)} modaltitle={this.state.modaltitle}></ModalSource>
				<ModalPower checkedKeys={this.state.checkIds} powerData={this.state.powerData} isShow={this.state.isShow2} setPower={this.setPower.bind(this)} setIsShow={this.setIsShow2.bind(this)} organizechoosedata={this.state.organizechoosedata}></ModalPower>
			</Container>
		)
	}
}
export default Role;
