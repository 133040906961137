import request from '../https'

class WashTotalRequest {
    //根据开始和结束时间获取人员洗消数据
    peopleWash(params) {
        return request({
            // url: '/safety/api/peopleWash',
            url: '/safety/statistics/peopleWash',
            method: 'post',
            params
        })
    }
    //根据人员ID和开始时间获取人员洗消详情数据
    peopleWashDetail(params) {
        return request({
            url: '/safety/api/peopleWashDetail',
            method: 'post',
            data: params
        })
    }
    //根据开始和结束时间获取车辆洗消数据
    carWash(params) {
        return request({
            // url: '/safety/api/carWash',
            url: '/safety/statistics/carWash',
            method: 'post',
            params
        })
    }
    //根据车辆ID和开始时间获取车辆洗消详情数据
    carWashDetail(params) {
        return request({
            url: '/safety/api/carWashDetail',
            method: 'post',
            data: params
        })
    }
    //根据开始和结束时间获取物资洗消数据
    goodsWash(params) {
        return request({
            // url: '/safety/api/goodsWash',
            url: 'safety/statistics/goodsWash',
            method: 'post',
            params
        })
    }
    //根据物品ID和开始时间获取物资洗消详情数据
    goodsWashDetail(params) {
        return request({
            url: '/safety/api/goodsWashDetail',
            method: 'post',
            data: params
        })
    }
}

export default WashTotalRequest