import React, {useState, useEffect} from 'react'

import { Container } from './style'

import {Table} from 'antd'

function DeTable(props) {
    // const [current, setCurrent] = useState(1)
    // const [pageSize, setPageSize] = useState(10)
    const { total, onPaginationChange, isPagination, clickRow, current, pageSize, setSize, setOn } = props

    const fetchData = async () => {
        // setOn(current);
        // setSize(pageSize);
    };

    useEffect(() => {
        fetchData();
    }, [current, pageSize, setSize, setOn])

    const handleChange = (no, size) => {
        setOn(no);
        setSize(size);
        // setCurrent(no);
        // setPageSize(size);
        onPaginationChange(no, size);
    }
    const handleSizeChange = (no, size) => {
        // setCurrent(no);
        // setPageSize(size);
        setOn(no);
        setSize(size);
        onPaginationChange(no, size);
    }
    const handleClick = (e) => {
        if (clickRow){
            clickRow(e);
        }
    }
    const customPagination = {
        showTotal: (total) => `共 ${total} 条`,
        size: 'small',
        current,
        pageSize,
        showSizeChanger: true,
        total,
        onChange: handleChange,
        onShowSizeChange: handleSizeChange,
    };
    return (
        <Container>
            {
                !isPagination?
                    // <Table pagination={{ size: 'small', current, pageSize, showSizeChanger: true, total, onChange: handleChange, onShowSizeChange: handleSizeChange }} {...props} />
                    <Table pagination={customPagination} {...props} />
                    :
                    <Table pagination={false} size="small" {...props} onRow={record => {
                        return {
                            onClick: handleClick.bind(this,record), // 点击行
                        };
                    }}/>
            }
        </Container>
    )
}

export default DeTable
