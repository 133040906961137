import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 340px;
  .back{
    position: relative;
    top: -35px;
    left: 55%;
  }
  .layout{
    //width: 100%!important;
    height: 100%!important;
    .layoutItem{
      text-align: center;
      background-color: #f4f4f4!important;
      border: 1px solid #606266;
      cursor: pointer;
      .itemText{
        position: relative;
        top: 40%;
        width: clac(100% - 1px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .risk{
        position: absolute;
        top: 5px;
        left: 0;
        font-size: 12px;
        color: white;
        div{
          float: left;
          height: 24px;
          width: 26px;
          line-height: 24px;
          //padding: 0 6px;
          cursor: pointer;
          //border-radius: 50%;
          margin-left: 5px;
          background-size: 100%;
        }
        //.highRisk{
        //  float: left;
        //  padding: 0 6px;
        //  cursor: pointer;
        //  border-radius: 50%;
        //  margin-left: 5px;
        //  background-size: 100%;
        //}
        //.mediumRisk{
        //  float: left;
        //  padding: 0 6px;
        //  cursor: pointer;
        //  border-radius: 50%;
        //  margin-left: 5px;
        //}
        //.lowRisk{
        //  float: left;
        //  padding: 0 6px;
        //  cursor: pointer;
        //  border-radius: 50%;
        //  margin-left: 5px;
        //}
      }
    }
  }
`
