import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------员工管理
    //获取人员列表
    getPeopleList(params) {
        return request({
            url: '/safety/people/list',
            method: 'post',
            params
        })
    }
	//获取当前登录用户下的猪场集合
	getPigfarmList(params){
		return request({
		    url: '/safety/area/getChildrenRegionInfo',
		    method: 'post',
		    params
		})
	}

    //新增员工
    addPeopleInfo(params) {
        return request({
            url: '/safety/people/add',
            method: 'post',
            params
        })
    }
    //编辑员工
    editPeopleInfo(params) {
        return request({
            url: '/safety/people/update',
            method: 'post',
            params
        })
    }
    //删除员工
    deletePeopleInfo(params) {
        return request({
            url: '/safety/people/delete',
            method: 'post',
            params
        })
    }
    //获取可访问设备
    selectVideoSn(params) {
        return request({
            url: '/safety/people/selectVideoNames',
            method: 'post',
            params
        })
    }

    /**
     * 生物安全检测
     */
    //新增
    addTestingList(params) {
        return request({
            url: '/safety/testing/add',
            method: 'post',
            contentType: 'application/json',
            data: params
        })
    }
    //修改
    updateTestingList(params) {
        return request({
            url: '/safety/testing/update',
            method: 'put',
            contentType: 'application/json',
            data: params
        })
    }
    //列表
    getTestingList(params) {
        return request({
            url: '/safety/testing/list',
            method: 'post',
            params
        })
    }
    //根据类型查询对象下拉列表
    getKeyName(params) {
        return request({
            url: '/safety/testing/getKeyName',
            method: 'get',
            params
        })
    }
    //查询检测报告对应的图片和文件
    getTestingDetail(params) {
        return request({
            url: '/safety/testing/detail',
            method: 'post',
            params
        })
    }
    //根据洗消点和类型查询生物安全在洗消流程中的位置
    getWashPosition(params) {
        return request({
            url: '/safety/testing/getWashPosition',
            method: 'post',
            params
        })
    }
}

export default StoreSettingHttp
