import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  .top{
    margin-bottom: 10px;
    .upload-wrap {
      display: inline-block;
      position: relative;
      width: 84px;
      color: #fff;
      background-color: #5A8BFF;
      border-color: #5A8BFF;
      margin-right: 40px;
      padding: 3px 5px;
      overflow: hidden;
      cursor: pointer;
    }

    .file-uploader {
      cursor: pointer;
      font-size: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      outline: none;
      opacity: 0;
      background-color: transparent;
    }

    .upload-text {
      cursor: pointer;
      z-index: 1;
      display: inline-block;
      margin-left: 5px;
    }
  }
  .bottom{
    height: calc(100% - 42px);
    overflow-y: scroll;
  }
`