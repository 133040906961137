import React, { useState, useRef, useEffect, useCallback, useImperativeHandle, forwardRef } from 'react';
import {Button, Col, Form, message, Modal} from 'antd';
import {Container1} from "./style.js";
import AICameraRequest from '../../../../request/AICamera-request'
import GridLayout from "react-grid-layout";
import {CloseOutlined,ExpandOutlined} from "@ant-design/icons"
// import ShowcaseLayout from "./gridLayout";
// import { Responsive, WidthProvider } from "react-grid-layout";
// const ResponsiveReactGridLayout = WidthProvider(Responsive);
import './styles.css'
import './styles2.css'
import _ from "lodash";


const _request = new AICameraRequest()
/**
 * 元素变化。 方法放在组件外部或者其他地方。
 * @param direction  方向 // move 移动 / 'e', 'w', 's', 'n', 'ne', 'nw', 'se', 'sw'
 * @param oriStyle 元素的属性 width height top left
 * @param oriPos   鼠标按下时所记录的坐标
 * @param e        事件event
 */
function transform (direction, oriStyle, oriPos, e, set) {
    const style = {...oriPos.current}
    const offsetX = e.clientX - oriPos.current.cX;
    const offsetY = e.clientY - oriPos.current.cY;
    switch (direction.current) {
        // 拖拽移动
        case 'move' :
            // 元素当前位置 + 偏移量
            const top = oriPos.current.top + offsetY;
            const left = oriPos.current.left + offsetX;
            // 限制必须在这个范围内移动 画板的高度-元素的高度
            style.top = Math.max(0, Math.min(top, oriStyle.height - style.height));
            style.left = Math.max(0, Math.min(left, oriStyle.width - style.width));
            break
        // 东
        case 'e':
            // 向右拖拽添加宽度
            style.width += offsetX;
            return style
        // 西
        case 'w':
            // 增加宽度、位置同步左移
            style.width -= offsetX;
            style.left += offsetX;
            return style
        // 南
        case 's':
            style.height += offsetY;
            return style
        // 北
        case 'n':
            style.height -= offsetY;
            style.top += offsetY;
            break
        // 东北
        case 'ne':
            style.height -= offsetY;
            style.top += offsetY;
            style.width += offsetX;
            break
        // 西北
        case 'nw':
            style.height -= offsetY;
            style.top += offsetY;
            style.width -= offsetX;
            style.left += offsetX;
            break
        // 东南
        case 'se':
            style.height += offsetY;
            style.width += offsetX;
            break
        // 西南
        case 'sw':
            style.height += offsetY;
            style.width -= offsetX;
            style.left += offsetX;
            break
    }
    return style
    // set(style)
}

const drag = (obj, set) => {
    // 鼠标被按下
    obj.onmousedown = (event) => {
        console.log(event);
        event = event || window.event;
        // 阻止默认事件
        event.preventDefault();

        // 鼠标手
        obj.style.cursor = "grabbing";

        // 最大移动距离
        // var maxMoveX = obj.clientWidth;
        // var maxMoveY = obj.clientHeight;
        let maxMoveX = 504
        let maxMoveY = 348;

        // 计算鼠标当前坐标 = 鼠标按下坐标 - 元素当前坐标(距离父元素距离)
        // div的水平偏移量  鼠标.clentX - 元素.offsetLeft
        // div的垂直偏移量  鼠标.clentY - 元素.offsetTop
        let ol = event.clientX - obj.offsetLeft;
        let ot = event.clientY - obj.offsetTop;

        // 绑定鼠标移动事件
        document.onmousemove = (event2) => {
            event2 = event2 || window.event;
            // 计算移动距离 = 当前鼠标坐标 - 鼠标按下坐标
            let left = event2.clientX - ol;
            let top = event2.clientY - ot;

            // 判断左右移动距离
            if (left >= maxMoveX) {
                left = maxMoveX;
            } else if (left <= 0) {
                left = 0;
            }
            // 判断上下移动距离
            if (top >= maxMoveY) {
                top = maxMoveY;
            } else if (top <= 0) {
                top = 0;
            }

            set({ left, top });
        }

        // 绑定一个鼠标松开事件
        document.onmouseup = () => {
            // 取消鼠标移动事件
            document.onmousemove = null;
            document.onmouseup = null;
            // 还原鼠标手
            obj.style.cursor = "grab";
        }
    }
}

const AreaItem = forwardRef((props,cRef) => {
    // 拖拽
    const [xyz, setXyz] = useState({ left: 0, top: 0 });
    const [style, setStyle] = useState({
        left: 100,
        top: 100,
        width: 100,
        height: 100,
    })
    const [areaData, setAreaData] = useState([])
    const [itemAreaData, setItemAreaData] = useState([])
    const [locationList, setLocationList] = useState([])
    const [itemLocationList, setItemLocationList] = useState([])
    const [layout, setLayout] = useState([])
    const [itemLayout, setItemLayout] = useState([])
    const [mounted, setMounted] = useState(false)
    const [layoutVisible, setLayoutVisible] = useState(false)
    const [itemIndex, setItemIndex] = useState(0)
    const [siteName, setSiteName] = useState('')
    const itemAreaClass = {
        textAlign: 'center',
        height: '340px',
        overflow: 'auto',
        marginRight: '10px',
        width: '72px',
    }
    const itemAreaClassDiv = {
        height: '40px',
        lineHeight: '40px',
        backgroundColor: '#f4f4f4',
        width: '70px',
        userSelect: 'none',
        cursor: 'pointer',
        border: '1px solid black',
        borderBottom: 'none',
        content:'attr(data-title)',
        maxWidth: '70px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
    const itemAreaClassDiv2 = {
        height: '40px',
        lineHeight: '40px',
        backgroundColor: '#f4f4f4',
        width: '70px',
        userSelect: 'none',
        cursor: 'pointer',
        border: '1px solid black',
        content:'attr(data-title)',
        maxWidth: '70px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
    const layoutClass = {
        border: '1px solid black',
        height: '340px',
        width: '500px',
    }


    useImperativeHandle(cRef, ()=>({
        layout
    }),[layout])
    // 盒子元素
    const container = useRef(null);
    const isDown = useRef(false);
    const direction = useRef('');
    const oriPos = useRef({
        top: 0, // 元素的坐标
        left: 0,
        cX: 0, // 鼠标的坐标
        cY: 0
    })

    // 放大

    useEffect(() => {
        // if (container) drag(container.current, setXyz);
        // if (container2) drag(container2.current, setXyz2);
        getAiLayoutList()
        // setMounted(true)
    }, []);

    const getAiLayoutList = () =>{
        _request.aiLayoutList().then(res =>{
            let arr = []
            res.forEach(item =>{
                if (typeof item.h === 'number' && typeof item.y === 'number' && typeof item.x === 'number' && typeof item.w === 'number'){
                    item.isGet = true
                    arr.push(item)
                }
            })
            setAreaData(res)
            setMounted(true)
            setLocationList(arr)
        })
    }

    const drag2 = (obj, dir, item, index) => {
        let arr = [...locationList]
        let styleObj = {...item}
        // 鼠标被按下
        obj = obj || window.event;
        // obj.nativeEvent = obj.nativeEvent || window.event;
        // 阻止默认事件
        // obj.nativeEvent.preventDefault();
        obj.preventDefault();
        // 鼠标手
        // obj.nativeEvent.path[0].style.cursor = "grabbing";

        // 最大移动距离
        // var maxMoveX = obj.clientWidth;
        // var maxMoveY = obj.clientHeight;
        let maxMoveX = 546 - styleObj.width;
        let maxMoveY = 390 - styleObj.height;
        console.log(obj);

        // 计算鼠标当前坐标 = 鼠标按下坐标 - 元素当前坐标(距离父元素距离)
        // div的水平偏移量  鼠标.clentX - 元素.offsetLeft
        // div的垂直偏移量  鼠标.clentY - 元素.offsetTop
        // let ol = obj.nativeEvent.clientX - obj.nativeEvent.path[0].offsetLeft;
        // let ot = obj.nativeEvent.clientY - obj.nativeEvent.path[0].offsetTop;
        // const ox = obj.nativeEvent.clientX;
        // const oy = obj.nativeEvent.clientY;
        let ol = obj.clientX - obj.path[0].offsetLeft;
        let ot = obj.clientY - obj.path[0].offsetTop;
        const ox = obj.clientX;
        const oy = obj.clientY;
        // 绑定鼠标移动事件
        document.onmousemove = (event2) => {
            event2 = event2 || window.event;
            let offsetX = event2.clientX - ox;
            let offsetY = event2.clientY - oy;
            switch (dir) {
                // 拖拽移动
                case 'move' :
                    // 计算移动距离 = 当前鼠标坐标 - 鼠标按下坐标
                    let left = event2.clientX - ol;
                    let top = event2.clientY - ot;

                    // 判断左右移动距离
                    if (left >= maxMoveX) {
                        left = maxMoveX;
                    } else if (left <= 0) {
                        left = 0;
                    }
                    // 判断上下移动距离
                    if (top >= maxMoveY) {
                        top = maxMoveY;
                    } else if (top <= 0) {
                        top = 0;
                    }
                    let moveObj = {
                        top,left,width: style.width,height: style.height
                    }
                    styleObj.top = moveObj.top
                    styleObj.left = moveObj.left
                    arr[index] = styleObj
                    // setStyle(styleObj)
                    setLocationList(arr);
                    break
                // 东
                case 'e':
                    // 向右拖拽添加宽度
                    styleObj = {...style}
                    styleObj.width = event2.clientX - ol;
                    setStyle(styleObj)
                    break
                // 西
                case 'w':
                    // 增加宽度、位置同步左移
                    styleObj = {...style}
                    styleObj.width -= offsetX;
                    styleObj.left += offsetX;
                    setStyle(styleObj)
                    break
                // 南
                case 's':
                    styleObj = {...style}
                    styleObj.height += offsetY;
                    // styleObj.lineHeight = styleObj.height + 'px'
                    setStyle(styleObj)
                    break
                // 北
                case 'n':
                    styleObj = {...style}
                    styleObj.height -= offsetY;
                    styleObj.top += offsetY;
                    // styleObj.lineHeight = styleObj.height + 'px'
                    setStyle(styleObj)
                    break
                // 东北
                case 'ne':
                    styleObj = {...style}
                    styleObj.height -= offsetY;
                    styleObj.top += offsetY;
                    styleObj.width += offsetX;
                    // styleObj.lineHeight = styleObj.height + 'px'
                    setStyle(styleObj)
                    break
                // 西北
                case 'nw':
                    styleObj = {...style}
                    styleObj.height -= offsetY;
                    styleObj.top += offsetY;
                    styleObj.width -= offsetX;
                    styleObj.left += offsetX;
                    // styleObj.lineHeight = styleObj.height + 'px'
                    setStyle(styleObj)
                    break
                // 东南
                case 'se':
                    styleObj = {...style}
                    styleObj.height += offsetY;
                    styleObj.width += offsetX;
                    // styleObj.lineHeight = styleObj.height + 'px'
                    setStyle(styleObj)
                    break
                // 西南
                case 'sw':
                    styleObj = {...style}
                    styleObj.height += offsetY;
                    styleObj.width -= offsetX;
                    styleObj.left += offsetX;
                    // styleObj.lineHeight = styleObj.height + 'px'
                    setStyle(styleObj)
                    break
            }
        }

        // 绑定一个鼠标松开事件
        document.onmouseup = () => {
            // 取消鼠标移动事件
            document.onmousemove = null;
            document.onmouseup = null;
        }
    }


    const clickArea = (i) =>{
        const arr = [...locationList]
        let flag = false
        arr.forEach(item =>{
            if (i.id === item.id){
                flag = true
            }
        })
        if (!flag){
            let obj = i
            setMounted(false)
            arr.push(obj)
            setLocationList(arr)
        }
    }

    const clickItemArea = (i) =>{
        const arr = [...itemLocationList]
        let flag = false
        arr.forEach(item =>{
            if (i.id === item.id){
                flag = true
            }
        })
        if (!flag){
            let obj = i
            setMounted(false)
            arr.push(obj)
            setItemLocationList(arr)
        }
    }

    const getLayout = () =>{
        console.log(layout);
    }

    const onMouseDown = (e, dir, item, index) => {
        // drag2(e, dir, item, index)
        e.stopPropagation();
        console.log(e.nativeEvent,'onMouseDown');
        let fun = throttle(drag2,100,e.nativeEvent, dir, item, index)
        fun(e.nativeEvent)
    }
    const throttle = (fun, wait=100,e, dir, item, index) => {
        let canRun
        console.log(e.nativeEvent,'throttle');
        return function (event){
            if(!canRun){
                console.log(event.nativeEvent,'setTimeout');
                canRun = setTimeout(() => {
                    fun(event, dir, item, index);
                    canRun = null;
                }, wait);
            }
        }
    }
    // 鼠标移动
    const onMouseMove = useCallback((e) => {
        // 判断鼠标是否按住
        if (!isDown.current) return
        const oriStyle = {
            width: 546,
            height: 390,
            top: 0,
            left: 0
        }
        transform(direction, oriStyle, oriPos, e);
        // let newStyle = throttle(transform, 100,direction, oriStyle, oriPos, e);
        // newStyle()
        let newStyle = transform(direction, oriStyle, oriPos, e);
        setStyle(newStyle)
    }, [])

    // 鼠标被抬起
    const onMouseUp = useCallback((e) => {
        isDown.current = false;
    },[])

    // 东南西北， 东北、西北、东南、西南
    const points = ['e', 'w', 's', 'n', 'ne', 'nw', 'se', 'sw']
    // const layouts = getLayoutsFromSomewhere();

    const generateDOM = () => {
        return _.map(locationList, function(l, i) {
            return (
                <div key={i}>
                    <span className="text">{i.siteName}</span>
                </div>
            );
        });
    }

    const onLayoutChange = (layout, layouts) => {
        let arr = [...layout]
        locationList.forEach((item,index) =>{
            arr[index].id = item.id
            arr[index].pigPenLayouts = item.pigPenLayouts
        })
        setLayout(arr)
    }

    const onItemLayoutChange = (layout1, layouts) => {
        let arr = [...layout1]
        itemLocationList.forEach((item,index) =>{
            arr[index].id = item.id
            arr[index].sitePigpenName = item.sitePigpenName
        })
        setItemLayout(arr)
    }

    const closeArea = (i) =>{
        const arr = [...locationList]
        arr.splice(i,1)
        setLocationList(arr)
    }

    const itemCloseArea = (i) =>{
        const arr = [...itemLocationList]
        arr.splice(i,1)
        setItemLocationList(arr)
    }

    const cancelEdit = (i) =>{
        setLayoutVisible(false);
        setItemLocationList([]);
        setItemIndex(0)
    }

    const openItemArea = (j,i) =>{
        console.log(j);
        console.log(i);
        setItemIndex(i)
        setLayoutVisible(true)
        setSiteName(j.siteName)
        let arr = [];
        j.pigPenLayouts.forEach(item =>{
            if (typeof item.h === 'number' && typeof item.y === 'number' && typeof item.x === 'number' && typeof item.w === 'number'){
                item.isGet = true
                arr.push(item)
            }
        })
        setItemAreaData(j.pigPenLayouts)
        setMounted(true)
        setItemLocationList(arr)
    }

    const layoutSave = () =>{
        let flag = false
        itemLayout.forEach(i =>{
            if (i.y > 5 || i.h > 6 || (i.y === 1 && i.h > 5) || (i.y === 2 && i.h > 4) || (i.y === 3 && i.h > 3) || (i.y === 4 && i.h > 2) || (i.y === 5 && i.h > 1)){
                flag = true
            }
        })
        if (flag) return message.error('区域布局不可超出固定范围！')
        const arr = [...locationList]
        const arr2 = [...layout]
        arr[itemIndex].pigPenLayouts = itemLayout
        arr2[itemIndex].pigPenLayouts = itemLayout
        setLocationList(arr)
        setLayoutVisible(false)
    }

    return (
        <Container1>
            <div className='areaName'>
                {
                    areaData.length > 0?areaData.map(i =>{
                        return <div style={{
                            maxWidth: '70px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }} onClick={()=>{clickArea(i)}} title={i.siteName}>
                            {i.siteName}
                        </div>
                    }):''
                }
            </div>
            <GridLayout
                className="layout"
                cols={3}
                width={500}
                rowHeight={44}
                onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts) }
            >
                {
                    mounted?locationList.length > 0?_.map(locationList, function(l, i) {
                        return (
                            <div key={i} data-grid={{ x: l.x, y: l.y, w: l.w, h: l.h }}>
                                <span className="text" style={{ width: '80%' }}>
                                    {l.siteName}
                                </span>
                                <div style={{ position: 'absolute',right: 2,top: 0,cursor: "pointer" }} onClick={(e)=>closeArea(i)}><CloseOutlined /></div>
                                <div style={{ position: 'absolute',right: 2,top: 15,cursor: "pointer" }} onClick={(e)=>openItemArea(l,i)}><ExpandOutlined /></div>
                            </div>
                        );
                    }):'':locationList.length > 0?_.map(locationList, function(l, i) {
                        return (
                            <div key={i}>
                                <span className="text" style={{ width: '80%' }}>
                                    {l.siteName}
                                </span>
                                <div style={{ position: 'absolute',right: 2,top: 0,cursor: "pointer" }} onClick={(e)=>closeArea(i)}><CloseOutlined /></div>
                                <div style={{ position: 'absolute',right: 2,top: 15,cursor: "pointer" }} onClick={(e)=>openItemArea(l,i)}><ExpandOutlined /></div>
                            </div>
                        );
                    }):''
                }
            </GridLayout>


            <Modal
                title={siteName + '栋舍布局设置'}
                visible={layoutVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'630px'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} onClick={layoutSave}>保存</Button>
                    </div>
                }
            >
                <div style={{ display: 'flex' }}>
                    <div style={itemAreaClass}>
                        {
                            itemAreaData.length > 0?itemAreaData.map((i,index) =>{
                                return <div style={index + 1 === itemAreaData.length?itemAreaClassDiv2:itemAreaClassDiv} onClick={()=>{clickItemArea(i)}} title={i.sitePigpenName}>
                                    {i.sitePigpenName}
                                </div>
                            }):''
                        }
                    </div>
                    <GridLayout
                        className="layout"
                        style={layoutClass}
                        cols={3}
                        width={500}
                        rowHeight={44}
                        onLayoutChange={(layout, layouts) => onItemLayoutChange(layout, layouts) }
                    >
                        {
                            mounted?itemLocationList.length > 0?_.map(itemLocationList, function(l, i) {
                                return (
                                    <div key={i} data-grid={{ x: l.x, y: l.y, w: l.w, h: l.h }}>
                                <span className="text" style={{ width: '80%' }}>
                                    {l.sitePigpenName}
                                </span>
                                        <div style={{ position: 'absolute',right: 2,top: 0,cursor: "pointer" }} onClick={(e)=>itemCloseArea(i)}><CloseOutlined /></div>
                                    </div>
                                );
                            }):'':itemLocationList.length > 0?_.map(itemLocationList, function(l, i) {
                                return (
                                    <div key={i}>
                                <span className="text" style={{ width: '80%' }}>
                                    {l.sitePigpenName}
                                </span>
                                        <div style={{ position: 'absolute',right: 2,top: 0,cursor: "pointer" }} onClick={(e)=>itemCloseArea(i)}><CloseOutlined /></div>
                                    </div>
                                );
                            }):''
                        }
                    </GridLayout>
                </div>
            </Modal>

            {/*<ShowcaseLayout onLayoutChange={onLayoutChange}/>*/}

            {/*<ResponsiveReactGridLayout*/}
            {/*    layouts={this.state.layouts}*/}
            {/*    onBreakpointChange={this.onBreakpointChange}*/}
            {/*    onLayoutChange={this.onLayoutChange}*/}
            {/*    // WidthProvider option*/}
            {/*    measureBeforeMount={false}*/}
            {/*    // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)*/}
            {/*    // and set `measureBeforeMount={true}`.*/}
            {/*    useCSSTransforms={this.state.mounted}*/}
            {/*    compactType={this.state.compactType}*/}
            {/*    preventCollision={!this.state.compactType}*/}
            {/*>*/}
            {/*    {this.generateDOM()}*/}
            {/*</ResponsiveReactGridLayout>*/}
            {/*<div id="LeftXyzContainer" className="container_box">*/}
                {/*{*/}
                {/*    locationList.length > 0?locationList.map((item,index) =>{*/}
                {/*        return <div*/}
                {/*            onMouseDown={(e)=>onMouseDown(e,'move',item,index)}*/}
                {/*            className='drawing-item'*/}
                {/*            style={{width: item.width, height: item.height, top: item.top, left: item.left}}*/}
                {/*        >*/}
                {/*            {points.map(i =>*/}
                {/*                <div className={`control-point point-${i}`} key={i}*/}
                {/*                     onMouseDown={(e)=>drag2(e,i)}*/}
                {/*                ></div>*/}
                {/*            )}*/}
                {/*            <div className='name'>{item.siteName}</div>*/}
                {/*        </div>*/}
                {/*    }):''*/}
                {/*}*/}
                {/*<GridLayout list={locationList} layout={layout} setLayout={setLayout}/>*/}
                {/*<div*/}
                {/*    ref={container2}*/}
                {/*    style={{*/}
                {/*        position: "absolute",*/}
                {/*        left: xyz2.left, top: xyz2.top,*/}
                {/*        border: '1px solid red',*/}
                {/*        height: 40, width: 40*/}
                {/*    }}*/}
                {/*>*/}
                {/*</div>*/}
            {/*</div>*/}
        </Container1>
    )
})

export default AreaItem
