import React, { useState, useEffect } from 'react'
import {Container} from './style'

import {
    Button,
    Input,
    Modal,
    Pagination,
    Popconfirm,
    Col,
    Form,
    message,
    Icon,
    Upload,
    Select,
    DatePicker,
    TreeSelect
} from 'antd'

import FlvVideo from '../../component/flvVideo'
import moment from 'moment'

import AICameraRequest from '../../request/AICamera-request'

import DeTable from '../../component/DeTable';
import {stringFormat,getPeriodTime} from "../../utils/homeFormat";
import CommonRequest from "../../request/common-request";
import WashTotalRequest from "../../request/washTotal-request";

const _request = new AICameraRequest()
const _common = new CommonRequest()
const _washRequest = new WashTotalRequest()
const { MonthPicker, RangePicker } = DatePicker;

const {Option} = Select;
const { TextArea } = Input;
const { TreeNode } = TreeSelect;
function AIAlertRecord(props) {
    const { AIData,nextClick } = props
    const [name, setName] = useState('')
    const [startTime, setStartTime] = useState(moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD') + ' 00:00:00')
    const [endTime, setEndTime] = useState(moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59')
    const [confirmLoading,setConfirmLoading] = useState(false)
    const [editVisible,setEditVisible] = useState(false)
    const [searchSiteId,setSearchSiteId] = useState('')
    const [searchSitePositionId,setSearchSitePositionId] = useState('')
    const [searchSiteType,setSearchSiteType] = useState('')
    const [searchSiteValue,setSearchSiteValue] = useState(null)
    const [AIVideoSiteList, setAIVideoSiteList] = useState([])
    const [alarmTypesList, setAlarmTypesList] = useState([])
    const [searchSitePigpenId,setSearchSitePigpenId] = useState('')
    const [alarmTypes, setAlarmTypes] = useState()
    const [alarmRiskLevelList, setAlarmRiskLevelList] = useState([])
    const [alarmRiskLevel, setAlarmRiskLevel] = useState()
    const [remake, setRemake] = useState('')
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(6)
    const [tableData, setTableData] = useState([]) //表格数据
    const [washList, setWashList] = useState([])
    const [promptEntryList, setPromptEntryList] = useState([])
    const [detailsData, setDetailsData] = useState({})
    useEffect(() => {
        washSpotList()
        getAIVideoSiteList()
        getWashList()
        getAlarmTypes()
        getAlarmRiskLevel()
    }, [AIData])
    //获取员工列表
    const getWashList = (ButtonType, pageNo = 1, pageSize = 6,obj) => {
        // console.log(new Date(endTime).format('YYYY-MM-DD HH:mm:ss'));
        let params = {
            startDate:startTime,
            endDate: endTime,
            // siteType: searchSiteType,
            siteId: searchSiteId,
            sitePositionId: searchSitePositionId,
            alarmType: alarmTypes,
            riskLevel: alarmRiskLevel,
            videoName: name,
            sitePigpenId: searchSitePigpenId,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {
                startDate:startTime,
                endDate: endTime,
                pageNo,
                pageSize
            }
        }
        if (obj){
            params.siteType = obj.siteType?obj.siteType:'';
            params.siteId = obj.siteId?obj.siteId:'';
            params.sitePositionId = obj.sitePositionId?obj.sitePositionId:'';
            params.sitePigpenId = obj.sitePigpenId?obj.sitePigpenId:'';
            params.alarmType = obj.alarmType?obj.alarmType:'';
            params.riskLevel = obj.riskLevel?obj.riskLevel:'';
            params.startDate = obj.startDate?obj.startDate:'';
            params.endDate = obj.endDate?obj.endDate:'';
            if (obj.startDate){
                setStartTime(obj.startDate)
                setEndTime(obj.endDate)
            }else {
                setStartTime('');
                setEndTime('');
            }
            if (obj.alarmType){
                setAlarmTypes(obj.alarmType);
            }else {
                setAlarmTypes();
            }
            if (obj.riskLevel){
                setAlarmRiskLevel(obj.riskLevel);
            }else {
                setAlarmRiskLevel();
            }
        }
        _request.getAiAlarmRecordList(params).then(res => {
            setTableTotal(res.total || 0);
            setTableData(res.list);
        })
    }

    //获取警报类型
    const getAlarmTypes = () =>{
        _request.getAlarmTypes().then(res => {
            const arr = res.map(i =>{
                return {name: i}
            })
            setAlarmTypesList(arr)
        })
    }
    //获取风险等级
    const getAlarmRiskLevel = () =>{
        _request.getAlarmRiskLevel().then(res => {
            const arr = res.map(i =>{
                return {name: i}
            })
            setAlarmRiskLevelList(arr)
        })
    }

    //获取推荐词条
    const getPromptEntry = () =>{
        _request.promptEntry().then(res =>{
            setPromptEntryList(res)
        })
    }

    //搜索
    const clickSearch = () => {
        setCurrent(1)
        getWashList('',1,pageSize)
    }

    const getAIVideoSiteList = () => {
        _request.getAIVideoSiteList().then(res =>{
            setAIVideoSiteList(res)
            if (AIData){
                let obj = AIData
                if (AIData.siteId){
                    // setSearchSiteValue(AIData.siteId)
                    setSearchSiteId(AIData.siteId)
                    setSearchSitePositionId(AIData.sitePositionId)
                    setSearchSitePigpenId(AIData.sitePigpenId)
                    // setSearchSitePigpenId()
                    // sitePositionId: searchSitePositionId,
                    //     sitePigpenId: searchSitePigpenId,
                    res.forEach(item =>{
                        if (item.siteId === AIData.siteId){
                            setSearchSiteValue(item.siteId)
                            if (item.positionList && item.positionList.length > 0){
                                item.positionList.forEach(j =>{
                                    if (j.sitePositionId === AIData.sitePositionId){
                                        setSearchSiteValue(j.sitePositionId + '-'+ item.siteId)
                                    }
                                })
                            }else if (item.pigpenList && item.pigpenList.length > 0){
                                item.pigpenList.forEach(j =>{
                                    if (j.sitePigpenId === AIData.sitePigpenId){
                                        setSearchSiteValue(j.sitePigpenId + '-'+ item.siteId)
                                    }else {
                                        if (j.positionList){
                                            j.positionList.forEach(i =>{
                                                if (i.sitePositionId === AIData.sitePositionId){
                                                    setSearchSiteValue(i.sitePositionId + '-'+ item.siteId)
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                            getWashList(null,1, 6,obj)
                        }
                        // if (item.siteId === AIData.siteId){
                        //     obj.siteId = item.siteId
                        //     setSearchSiteId(item.siteId)
                        //     getWashList(null,1, 10,obj)
                        // }else {
                        //     if (i.sitePositionList){
                        //         i.sitePositionList.forEach(j =>{
                        //             if (j.sitePositionId === AIData.siteId){
                        //                 obj.sitePositionId = j.sitePositionId
                        //                 obj.siteType = j.siteType
                        //                 setSearchSitePositionId(j.sitePositionId)
                        //                 setSearchSiteType(j.siteType)
                        //                 getWashList(null,1, 10,obj)
                        //             }
                        //         })
                        //     }
                        // }
                    })
                }else {
                    setSearchSiteValue(null)
                    getWashList(null,1, 6,obj)
                }
            }
        })
    }

    //重置
    const clickReset = () => {
        setName('')
        setStartTime(moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD') + ' 00:00:00')
        setEndTime(moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59')
        setSearchSiteId('')
        setSearchSiteType('')
        setSearchSitePositionId('')
        setSearchSitePigpenId('')
        setSearchSiteValue(null)
        setAlarmRiskLevel()
        setAlarmTypes()
        setCurrent(1)
        setPageSize(6)
        getWashList('reset',1,6)
    }

    const cancelEdit = () => {
        setEditVisible(false)
        setDetailsData({})
    }
    const editConFire = () => {
        setConfirmLoading(true)
        let param = {
            id: detailsData.id,
            remarks: remake,
            state: '1'
        }
        _request.updateAiAlarmRecord(param).then(res =>{
            if (res.code){
                setConfirmLoading(false)
                message.info(res.msg)
                getWashList()
                cancelEdit()
            }
        })
    }

    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }
    const onPagination = (page,pageSize) => {
        setCurrent(page)
        getWashList('',page,pageSize)
    }
    const onShowSizeChange = (current,size) => {
        setPageSize(size)
        getWashList('',current,size)
    }

    const skipCamera = () =>{
        let param = {
            key: detailsData.isEvr ? '165&EVR设备管理' : '162&摄像头管理'
        }
        nextClick(param,detailsData.videoSn)
        setEditVisible(false)
    }

    //递归对比
    const searchComparison = (e) =>{
        setSearchSiteId('')
        setSearchSiteType('')
        setSearchSitePositionId('')
        setSearchSitePigpenId('')
        let siteId = e.split('-')[1]
        let ID = e.split('-')[0]
        AIVideoSiteList.forEach(item =>{
            if (item.siteId === e){
                setSearchSiteId(item.siteId)
            }else if (item.positionList && item.positionList.length > 0){
                item.positionList.forEach(j =>{
                    if (j.sitePositionId === ID && j.siteId === siteId){
                        setSearchSiteId(j.siteId)
                        setSearchSitePositionId(j.sitePositionId)
                    }
                })
            }else if (item.pigpenList && item.pigpenList.length > 0){
                item.pigpenList.forEach(j =>{
                    if (j.sitePigpenId === ID && j.siteId === siteId){
                        setSearchSiteId(j.siteId)
                        if (j.type === 0){
                            setSearchSitePigpenId(j.sitePigpenId)
                        }else {
                            setSearchSitePositionId(j.sitePigpenId)
                        }
                    }else {
                        if (j.positionList){
                            j.positionList.forEach(i =>{
                                if (i.sitePositionId === ID && i.siteId === siteId){
                                    setSearchSiteId(i.siteId)
                                    setSearchSitePositionId(i.sitePositionId)
                                }
                            })
                        }
                    }
                })
            }
        })
    }


    function rangeTimeChange(date){
        if(date){
            if(date[0]!=undefined){
                setStartTime(date[0].format('YYYY-MM-DD HH:mm:ss'));
            }else{
                setStartTime('');
            }
            if(date[1]!=undefined){
                setEndTime(date[1].format('YYYY-MM-DD HH:mm:ss'));
            }else{
                setEndTime('');
            }
        }
    }

    const checkDetails = (i) =>{
        getPromptEntry()
        setEditVisible(true)
        setRemake(i.remarks)
        setDetailsData(i)
    }


    return (
        <Container>
            <div className="top">
                <TreeSelect
                    style={{ width: 150, marginRight: 10 }}
                    dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                    placeholder="请选择区域"
                    value={searchSiteValue}
                    onChange={(value,options,extra)=>{
                        setSearchSiteValue(value)
                        searchComparison(value)
                    }}
                >
                    {
                        AIVideoSiteList.length > 0 ?
                            AIVideoSiteList.map((item,index) =>{
                                return <TreeNode value={item.siteId} title={item.siteName} key={item.siteId}>
                                    {
                                        item.positionList && item.positionList.length > 0?item.positionList.map(i =>{
                                            return <TreeNode value={i.sitePositionId+'-'+i.siteId} title={i.sitePositionName} key={i.sitePositionId}>
                                            </TreeNode>
                                        }):(item.pigpenList && item.pigpenList.length > 0?item.pigpenList.map(i =>{
                                            return <TreeNode value={i.sitePigpenId+'-'+i.siteId} title={i.sitePigpenName} key={i.sitePigpenId}>
                                                {
                                                    i.positionList && i.positionList.length > 0?i.positionList.map(j =>{
                                                        return <TreeNode value={j.sitePositionId+'-'+j.siteId} title={j.sitePositionName} key={j.sitePositionId} />
                                                    }):''
                                                }
                                            </TreeNode>
                                        }):'')
                                    }
                                </TreeNode>
                            })
                            :''
                    }
                </TreeSelect>
                <Select placeholder="请选择报警类型" style={{width: 150, marginRight: 10}} showSearch value={alarmTypes}
                        onChange={e => setAlarmTypes(e)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                    {
                        alarmTypesList.map((item, index) => {
                            return <Option key={item.name} value={item.name}>{item.name}</Option>
                        })
                    }
                </Select>
                <Input placeholder="请输入设备名称" style={{ width: 150, marginRight: 10 }} value={name} onPressEnter={clickSearch} onChange={(e) => setName(e.target.value)}></Input>
                <RangePicker
                    ranges={{
                        '今天': [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                        '一个月': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD HH:mm:ss"), moment(endTime, "YYYY-MM-DD HH:mm:ss")]}
                    /*disabledTime={disabledRangeTime}*/
                    onChange={rangeTimeChange}
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                />
                <Select placeholder="请选择风险等级"
                        mode="multiple"
                        style={{width: 150, marginRight: 10, marginLeft: 10}} showSearch value={alarmRiskLevel}
                        onChange={e => setAlarmRiskLevel(e)}>
                    {
                        alarmRiskLevelList.map((item, index) => {
                            return <Option key={item.name} value={item.name}>{item.name}</Option>
                        })
                    }
                </Select>

                &nbsp;&nbsp;
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div style={{marginBottom: 5}}>合计&nbsp;{tableTotal}&nbsp;条</div>
            <div className="bottom1">
                {
                    tableData.length > 0?
                        tableData.map(i =>{
                            return <div className="dataDiv" onClick={checkDetails.bind(this,i)}>
                                <img src={i.pictureUrl}/>
                                <div className="dataTitle">
                                    <div>位置：{i.sitePositionName}</div>
                                    <div>事件：{i.alarmContent}</div>
                                    <div>风险等级：{i.riskLevel}</div>
                                    <div>事件时间：{moment(i.createdDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                                </div>
                                {
                                    i.state === 0?
                                        <div className='unread'></div>
                                        :''
                                }
                            </div>
                        })
                        :'暂无数据'
                }
                <div style={{ clear: "both" }}></div>
            </div>
            <Pagination size="small" total={tableTotal} pageSize={pageSize} current={current} pageSizeOptions={['6','12','24','48']} onShowSizeChange={onShowSizeChange} onChange={onPagination} showSizeChanger />


            <Modal
                title='查看详情'
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    detailsData.state === 0?
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 100 }} loading={confirmLoading} onClick={editConFire}>处理</Button>
                        <Button style={{ width: 100,marginLeft: 10 }} onClick={cancelEdit}>暂不处理</Button>
                    </div>:''
                }
            >
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div><b>设备位置</b>：{detailsData.sitePositionName}</div>
                        <div><b>风险等级</b>：{detailsData.riskLevel}&emsp;
                            <span
                                style={{ color: '#169BD5',cursor: 'pointer' }}
                                onClick={skipCamera}
                            >修改设备风险等级</span>
                        </div>
                        <div><b>设备类型</b>：{detailsData.alarmType}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div><b>事件内容</b>：{detailsData.alarmContent}</div>
                        <div><b>事件时间</b>：{moment(detailsData.createdDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                        <div><b>设备名称</b>：{detailsData.videoName}（{detailsData.videoSn}）</div>
                    </div>
                </div>
                <div style={{ width: 'calc(100% + 48px)',height: 0.5,backgroundColor: '#e7e7e7',marginLeft: -24,marginTop: 10 }}></div>
                <div style={{ display: 'flex',marginTop: 15 }}>
                    <div style={{ width: '49%' }}>
                        <img style={{ width: '100%' }} src={detailsData.pictureUrl}/>
                    </div>
                    <div style={{ width: '49%',marginLeft: '1%' }}>
                        {/*{editVisible?<video width="100%" controls>*/}
                        {/*    <source src={detailsData.playbackUrl} type="video/mp4"></source>*/}
                        {/*</video>:''}*/}
                        {editVisible?detailsData.playbackUrl?detailsData.videoUrlType==='mp4'?
                            <video width="100%" controls>
                                <source src={detailsData.playbackUrl} type="video/mp4"></source>
                            </video>
                            :<FlvVideo url={detailsData.playbackUrl} type={detailsData.videoUrlType}/>:'暂无视频':''}
                    </div>
                </div>
                <div style={{ width: 'calc(100% + 48px)',height: 0.5,backgroundColor: '#e7e7e7',marginLeft: -24,marginTop: 15,marginBottom: 15 }}></div>
                {detailsData.state === 0?<div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: 46}}>备注：</div>
                        <TextArea rows={4} value={remake} onChange={(e) => {
                            setRemake(e.target.value)
                        }}/>
                    </div>
                    <div style={{display: 'flex',marginTop: 10,}}>
                        <div style={{width: 100}}>推荐词条：</div>
                        <div style={{display: 'flex',flexFlow: 'wrap',color: 'rgba(0,0,0, 0.4)'}}>
                            {
                                promptEntryList.length > 0?promptEntryList.map(i =>{
                                    return (
                                        i?<div style={{cursor: 'pointer',width: '50%'}} onClick={() => {
                                            setRemake(i)
                                        }}>
                                            {i}
                                        </div>:''
                                    )
                                }):''
                            }
                        </div>
                    </div>
                </div>:<div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: 46}}>备注：</div>
                        <div>{remake}</div>
                    </div>
                </div>}
            </Modal>
        </Container>

    )
}

export default Form.create()(AIAlertRecord)
