import React, { useState, useEffect } from 'react'
import {Container} from './style'
import axios from 'axios'

import {
    Button,
    Input,
    Modal,
    InputNumber,
    TreeSelect,
    Popconfirm,
    Col,
    Form,
    message,
    Icon,
    Upload,
    Select,
    Radio, Popover
} from 'antd'

import moment from 'moment'

import AICameraRequest from '../../../request/AICamera-request'

import CommonRequest from '../../../request/common-request'

import DeTable from '../../../component/DeTable';
import DeVideo from '../../../component/DeVideo'
import CommonHttp from '../../../request/common-request'
import {connect} from "react-redux";

const _request = new AICameraRequest()
const _common = new CommonRequest()
const _commonrequest = new CommonHttp()
const { TreeNode } = TreeSelect;

function Camera(props) {
    const { sn, baseURL } = props
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [levelEditVisible, setLevelEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [deviceAddress, setDeviceAddress] = useState([])//设备位置
    const [washName, setWashName] = useState('')
    const [siteType,setSiteType] = useState('')
    const [sitePosition,setSitePosition] = useState({})
    const [siteId,setSiteId] = useState('')
    const [siteName,setSiteName] = useState('')
    const [searchSiteId,setSearchSiteId] = useState('')
    const [searchSitePositionId,setSearchSitePositionId] = useState('')
    const [searchSiteType,setSearchSiteType] = useState('')
    const [levelValue,setLevelValue] = useState('高')
    const [levelData,setLevelData] = useState({})
    const [sitePigpenName,setSitePigpenName] = useState('')
    const [sitePigpenId,setSitePigpenId] = useState('')
    const [alarmTypesList, setAlarmTypesList] = useState([])
    const [childRegionData, setChildRegionData] = useState([])
    const [searchSiteValue,setSearchSiteValue] = useState(null)
    const [searchSitePigpenId,setSearchSitePigpenId] = useState('')
    const [destoryVideo, setDestoryVideo] = useState(false)
    const [detailVideoUrl, setDetailVideoUrl] = useState('')
    const [regionName, setRegionName] = useState('')

    const tableColunm =
        [{
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
            {
                title: '所属区域',
                dataIndex: 'siteName',
                key: 'siteName',
            },
            {

                title: '设备位置',
                key: 'sitePositionName',
                dataIndex: 'sitePositionName',
            },
            {
                title: '设备编号',
                key: 'sn',
                dataIndex: 'sn',
            },
            {
                title: '设备名称',
                key: 'name',
                dataIndex: 'name',
            },
            {
                title: '设备类型',
                key: 'type',
                dataIndex: 'type',
            },
            {
                title: '在线状态',
                key: 'isOnline',
                dataIndex: 'isOnline',
                render: (text, record, index) => (
                    <div style={{ position: 'relative' }}>
                        {
                            text !== null?text?
                                <Popover content={'最后通讯时间：' + (record.offlineTime?moment(record.offlineTime).format("YYYY-MM-DD HH:mm:ss"):'-')} title="设备在线">
                                    <span style={{color: 'green'}}>在线</span>
                                </Popover>
                                :<Popover content={'设备离线时间：' + (record.offlineTime?moment(record.offlineTime).format("YYYY-MM-DD HH:mm:ss"):'-')} title="设备已离线">
                                    <span style={{color: 'red'}}>离线</span>
                                </Popover>:'-'
                        }
                    </div>
                )
            },
            {
                title: '操作时间',
                key: 'updatedDate',
                dataIndex: 'updatedDate',
                render: (text, record, index) => (
                    <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
                )
            },
            {
                title: '操作人',
                key: 'updatedName',
                dataIndex: 'updatedName',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <span style={{ marginRight: 8 }} className='tableA' onClick={openModal.bind(this, 'level', record)}>风险等级</span>
                        <span style={{ marginRight: 8 }} className='tableA' onClick={openModal.bind(this, 'edit', record)}>编辑</span>
                        <span style={{ marginRight: 8 }} className='tableA' onClick={openModal.bind(this, 'play', record)}>播放直播</span>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                            <span className='tableADelete'>删除</span>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据

    // 闸机记录表格结构
    const [AIVideoSiteList, setAIVideoSiteList] = useState([])
    const [addAIVideoSiteList, setAddAIVideoSiteList] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)


    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getVideoMonitorList()
        getAIVideoSiteList()
        getAddAIVideoSiteList()
        getPigfarm()
        getAlarmTypes()
        getChildRegionList()
    }, [sn])
    //获取闸机列表
    const getVideoMonitorList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            sn: washName,
            sitePigpenId: searchSitePigpenId,
            siteId: searchSiteId,
            sitePositionId: searchSitePositionId,
            pageNo,
            pageSize
        }

        if (!ButtonType && sn){
            setWashName(sn)
            params.sn = sn
        }

        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getAIVideoList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }


    //获取下属所有机构
    const getChildRegionList = () => {
        let params = {
            regionId: sessionStorage.getItem('regionId')
        }
        _commonrequest.getChildRegionListAll(params).then(res => {
            setChildRegionData(res)
        })
    }

    const getAIVideoSiteList = () => {
        _request.getAIVideoSiteList().then(res =>{
            setAIVideoSiteList(res)
        })
    }

    const getTBdAiPositions = (zoneId,pigpenId) => {
        let param = {
            zoneId,
            pigpenId
        }
        _request.getTBdAiPositions(param).then(res =>{
            setDeviceAddress(res)
        })
    }

    const getAddAIVideoSiteList = (e) => {
        const { setFieldsValue } = props.form
        setTimeout(()=>{
            setFieldsValue({
                washSpotId : null
            })
        },0)
        setAddAIVideoSiteList([])
        let region = []
        if (e){
            region = e.split('&')
            setRegionName(region[1])
        }
        let param = {
            regionId: e?region[0]:''
        }
        _request.getAddAIVideoSiteList(param).then(res =>{
            setAddAIVideoSiteList(res)
        })
    }

    //获取人员摄像头关联的猪场栋舍列表
    const getPigfarm = () => {
        let params = {
            regionType : 5,
            enterpriseType : 2
        }
        _commonrequest.getFramBuilddingList(params).then(res => {
            let list = []
            res.forEach(item => {
                let childList = []
                item.buildList.forEach(child => {
                    childList.push({
                        title: child.buildingName,
                        value: child.buildingId,
                        isLeaf: true
                    })
                })
                list.push({
                    title: item.pigFramName,
                    value: item.pigFramId,
                    children: childList,
                    // selectable: false,
                    // disableCheckbox: true
                })
            })
        })
    }

    //删除最外的表格行
    const deleteRow = (id) => {
        _request.deleteAIVideo(id).then(res => {
            message.info('删除成功！')
            getVideoMonitorList('del')
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        if(type === 'edit'){ // 编辑页面
            setEditVisible(true)
            getTBdAiPositions(record.siteId,record.sitePigpenId)
            setTimeout(() => {
                setSiteType(record.siteType)
                setSiteName(record.siteName)
                setSiteId(record.siteId)
                setSitePigpenName(record.sitePigpenName)
                setSitePigpenId(record.sitePigpenId)
                setSitePosition({
                    children: record.sitePositionName,
                    value: record.sitePositionId
                })
                setRegionName(record.regionName)
                setFieldsValue({
                    equipmentNo: record.sn,
                    regionId: record.regionId+'&'+record.regionName,
                    equipmentPosition: [record.sitePositionId],
                    washSpotId : record.sitePigpenId?record.sitePigpenId+'-'+record.siteId:record.siteId,
                    id: record.id,
                    name:record.name,
                    type:record.type,
                })
            }, 0)
        }else if (type === 'play'){
            setEditVisible(true)
            setDestoryVideo(false)
            // 播放
            setDetailVideoUrl(record.liveUrl)
        }else if (type === 'level'){
            setLevelEditVisible(true)
            setTimeout(() => {
                setLevelData(record)
                setLevelValue(record.riskLevel)
            },10)
        }else {
            setEditVisible(true)
        }
    }

    const { getFieldValue, validateFields, setFieldsValue } = props.form

    const saveLevel = () => {
        const formData = new FormData();
        formData.append('sn', levelData.sn)
        formData.append('sitePositionName', levelData.sitePositionName)
        if (levelData.sitePositionId){
            formData.append('sitePositionId', levelData.sitePositionId)
        }
        if (levelData.sitePigpenId){
            formData.append('sitePigpenId', levelData.sitePigpenId)
            formData.append('sitePigpenName', levelData.sitePigpenName)
        }
        formData.append('siteId', levelData.siteId)
        formData.append('siteName', levelData.siteName)
        formData.append('name', levelData.name)
        formData.append('type', levelData.type)
        formData.append('id', levelData.id)
        formData.append('riskLevel', levelValue)
        _request.updateAIVideo(formData).then(res => {
            setConfirmLoading(false)
            message.info('修改成功！')
            setLevelEditVisible(false)
            getAIVideoSiteList()
            getVideoMonitorList('qwe')
        }).catch(err =>{
            setConfirmLoading(false)
        })
    }

    //编辑页提交
    const editConfire = () => {
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (modalType === 'add') {
                    const formData = new FormData();
                    formData.append('sn', getFieldValue('equipmentNo'))
                    formData.append('sitePositionName', sitePosition.children)
                    if (sitePosition.value){
                        formData.append('sitePositionId', sitePosition.value)
                    }
                    if (sitePigpenId){
                        formData.append('sitePigpenId', sitePigpenId)
                        formData.append('sitePigpenName', sitePigpenName)
                    }
                    formData.append('siteId', siteId)
                    formData.append('siteName', siteName)
                    formData.append('name', getFieldValue('name'))
                    formData.append('type', getFieldValue('type'))
                    formData.append('regionId', getFieldValue('regionId').split('&')[0])
                    formData.append('regionName', regionName)
                    formData.append('devUsername', getFieldValue('DEV_USERNAME'))
                    formData.append('devPassword', getFieldValue('DEV_PASSWORD'))
                    // formData.append('regionId', sessionStorage.getItem('regionId'))
                    let params = {
                        sn: getFieldValue('equipmentNo'),
                        sitePositionName : sitePosition.children,
                        sitePositionId : sitePosition.value?sitePosition.value:null,
                        sitePigpenId,
                        sitePigpenName,
                        siteId,
                        siteName,
                        name:getFieldValue('name'),
                        type:getFieldValue('type'),
                    }
                    _request.addAIVideo(formData).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getAIVideoSiteList()
                        getVideoMonitorList('123')
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })
                } else {
                    const formData = new FormData();
                    formData.append('sn', getFieldValue('equipmentNo'))
                    formData.append('sitePositionName', sitePosition.children)
                    if (sitePosition.value){
                        formData.append('sitePositionId', sitePosition.value)
                    }
                    if (sitePigpenId){
                        formData.append('sitePigpenId', sitePigpenId)
                        formData.append('sitePigpenName', sitePigpenName)
                    }
                    formData.append('siteId', siteId)
                    formData.append('siteName', siteName)
                    formData.append('name', getFieldValue('name'))
                    formData.append('type', getFieldValue('type'))
                    formData.append('regionId', getFieldValue('regionId').split('&')[0])
                    formData.append('regionName', regionName)
                    formData.append('id', getFieldValue('id'))
                    // formData.append('regionId', sessionStorage.getItem('regionId'))
                    let params = {
                        sn: getFieldValue('equipmentNo'),
                        sitePositionName : sitePosition.children,
                        sitePositionId : sitePosition.value?sitePosition.value:null,
                        siteType,
                        siteId,
                        sitePigpenId,
                        sitePigpenName,
                        siteName,
                        name:getFieldValue('name'),
                        id: getFieldValue('id'),
                    }
                    _request.updateAIVideo(formData).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getAIVideoSiteList()
                        getVideoMonitorList('213')
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
        if (modalType === 'play'){
            setDestoryVideo(true)
        }
    }
    //编辑页取消
    const cancelLevelEdit = () => {
        setLevelEditVisible(false)
        setLevelData({})
        setLevelValue('高')
    }


    //选择区域
    const selectSite = (e) => {
        setFieldsValue({
            equipmentPosition: []
        })
        const obj = comparison(e);
        setSiteId(obj.siteId)
        setSiteName(obj.siteName)
        // setSitePosition({
        //     value: obj.sitePigpenId,
        //     children: obj.sitePigpenName
        // })
        setSitePigpenName(obj.sitePigpenName)
        setSitePigpenId(obj.sitePigpenId)
        getTBdAiPositions(obj.siteId,obj.sitePigpenId)
        // setSiteType(obj.siteType)
        // if (obj.siteType === 0){
        //     setSiteId(e)
        //     setSiteName(obj.siteName)
        //     AIVideoSiteList[1].siteList.forEach(i =>{
        //         if (i.siteId === e){
        //             if (i.sitePositionList){
        //                 setDeviceAddress(i.sitePositionList)
        //             }
        //         }
        //     })
        // }else {
        //     setSiteId(obj.siteId)
        //     setSiteName(obj.siteName)
        //     setSitePosition({
        //         value: obj.sitePositionId,
        //         children: obj.sitePositionName
        //     })
        // }
    }

    //递归对比
    const comparison = (e) =>{
        let obj = {}
        let siteId = e.split('-')[1]
        let ID = e.split('-')[0]
        addAIVideoSiteList.forEach(item =>{
            // item.siteList.forEach(i =>{
            //     if (i.siteId === e){
            //         obj = i
            //     }else {
            //         if (i.sitePositionList){
            //             i.sitePositionList.forEach(j =>{
            //                 if (j.sitePositionId === e){
            //                     obj = j
            //                 }
            //             })
            //         }
            //     }
            if (item.siteId === e){
                obj = item
            }else if (item.positionList && item.positionList.length > 0){
                item.positionList.forEach(j =>{
                    if (j.sitePositionId === ID && j.siteId === siteId){
                        obj = j
                    }
                })
            }else if (item.pigpenList && item.pigpenList.length > 0){
                item.pigpenList.forEach(j =>{
                    if (j.sitePigpenId === ID && j.siteId === siteId){
                        obj = j
                    }
                })
            }
            // })
        })
        return obj
    }
    //递归对比
    const searchComparison = (e) =>{
        setSearchSiteId('')
        setSearchSiteType('')
        setSearchSitePositionId('')
        setSearchSitePigpenId('')
        let siteId = e.split('-')[1]
        let ID = e.split('-')[0]
        AIVideoSiteList.forEach(item =>{
            if (item.siteId === e){
                setSearchSiteId(item.siteId)
            }else if (item.positionList && item.positionList.length > 0){
                item.positionList.forEach(j =>{
                    if (j.sitePositionId === ID && j.siteId === siteId){
                        setSearchSiteId(j.siteId)
                        setSearchSitePositionId(j.sitePositionId)
                    }
                })
            }else if (item.pigpenList && item.pigpenList.length > 0){
                item.pigpenList.forEach(j =>{
                    if (j.sitePigpenId === ID && j.siteId === siteId){
                        setSearchSiteId(j.siteId)
                        if (j.type === 0){
                            setSearchSitePigpenId(j.sitePigpenId)
                        }else {
                            setSearchSitePositionId(j.sitePigpenId)
                        }
                    }else {
                        if (j.positionList){
                            j.positionList.forEach(i =>{
                                if (i.sitePositionId === ID && i.siteId === siteId){
                                    setSearchSiteId(i.siteId)
                                    setSearchSitePositionId(i.sitePositionId)
                                }
                            })
                        }
                    }
                })
            }
        })
    }

    //获取警报类型
    const getAlarmTypes = () =>{
        _request.getAlarmTypes().then(res => {
            const arr = res.map(i =>{
                return {name: i}
            })
            setAlarmTypesList(arr)
        })
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getVideoMonitorList('123')
    }

    //重置
    const clickReset = () => {
        setWashName('')
        setSearchSiteId('')
        setSearchSiteType('')
        setSearchSitePositionId('')
        setSearchSiteValue(null)
        setPageNo(1)
        setPageSize(10)
        getVideoMonitorList('reset')
    }
    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getVideoMonitorList('',pageNo,pageSize);
    }


    const { Option } = Select;

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <TreeSelect
                    style={{ width: 200, marginRight: 10 }}
                    dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                    placeholder="请选择区域"
                    value={searchSiteValue}
                    onChange={(value,options,extra)=>{
                        setSearchSiteValue(value)
                        searchComparison(value)
                    }}
                >
                    {
                        AIVideoSiteList.length > 0 ?
                            AIVideoSiteList.map((item,index) =>{
                                return <TreeNode value={item.siteId} title={item.siteName} key={item.siteId}>
                                    {
                                        item.positionList && item.positionList.length > 0?item.positionList.map(i =>{
                                            return <TreeNode value={i.sitePositionId+'-'+i.siteId} title={i.sitePositionName} key={i.sitePositionId}>
                                            </TreeNode>
                                        }):(item.pigpenList && item.pigpenList.length > 0?item.pigpenList.map(i =>{
                                            return <TreeNode value={i.sitePigpenId+'-'+i.siteId} title={i.sitePigpenName} key={i.sitePigpenId}>
                                                {
                                                    i.positionList && i.positionList.length > 0?i.positionList.map(j =>{
                                                        return <TreeNode value={j.sitePositionId+'-'+j.siteId} title={j.sitePositionName} key={j.sitePositionId} />
                                                    }):''
                                                }
                                            </TreeNode>
                                        }):'')
                                    }
                                </TreeNode>
                            })
                            :''
                    }
                </TreeSelect>
                <Input placeholder="请输入设备编号" style={{ width: 200, marginRight: 10 }} value={washName} onPressEnter={clickSearch} onChange={(e) => setWashName(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span className='iconfont icon-zhongzhi buttonIcon' style={{fontSize: 14}}></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增AI摄像头' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={modalType === 'open' ? false : true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    modalType === 'record' || modalType === 'play' ? null :
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                        </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{ height: 300 }}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备序列号：">
                                    {getFieldDecorator('equipmentNo', {
                                        rules: [{ required: true, message: '请输入设备序列号！' }],
                                    })(
                                        <Input
                                            placeholder="请输入设备序列号"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备名称：">
                                    {getFieldDecorator('name', {
                                        rules: [{ required: true, message: '设备名称！' }],
                                    })(
                                        <Input
                                            placeholder="设备名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属机构：">
                                    {getFieldDecorator('regionId', {
                                        rules: [{ required: true, message: '请选择所属机构!' }],
                                    })(
                                        <Select placeholder="请选择所属机构" onChange={getAddAIVideoSiteList}>
                                            {
                                                childRegionData.map((item,index) => {
                                                    return (
                                                        <Option value={item.regionId+'&'+item.regionName} key={item.regionId} >{item.regionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属区域：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择区域！' }],
                                    })(
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                                            placeholder="请选择区域"
                                            allowClear
                                            value={searchSiteValue}
                                            onChange={selectSite}
                                        >
                                            {
                                                addAIVideoSiteList.length > 0 ?
                                                    addAIVideoSiteList.map((item,index) =>{
                                                        return <TreeNode value={item.siteId} title={item.siteName} key={item.siteId}>
                                                            {
                                                                item.positionList && item.positionList.length > 0?item.positionList.map(i =>{
                                                                    return <TreeNode value={i.sitePositionId+'-'+i.siteId} title={i.sitePositionName} key={i.sitePositionId+'-'+i.siteId}>
                                                                    </TreeNode>
                                                                }):(item.pigpenList && item.pigpenList.length > 0?item.pigpenList.map(i =>{
                                                                    return <TreeNode value={i.sitePigpenId+'-'+i.siteId} title={i.sitePigpenName} key={i.sitePigpenId+'-'+i.siteId}>
                                                                        {
                                                                            i.positionList && i.positionList.length > 0?i.positionList.map(j =>{
                                                                                return <TreeNode value={j.sitePositionId+'-'+j.siteId} title={j.sitePositionName} key={j.sitePositionId+'-'+j.siteId} />
                                                                            }):''
                                                                        }
                                                                    </TreeNode>
                                                                }):'')
                                                            }
                                                        </TreeNode>
                                                    })
                                                    :''
                                            }
                                        </TreeSelect>
                                    )}
                                </Form.Item>
                                {/*<Col span={12}>*/}
                                {/*    <Form.Item {...formItemLayout} label="设备验证码：">*/}
                                {/*        {getFieldDecorator('name', {*/}
                                {/*            rules: [{ required: true, message: '请输入设备验证码！' }],*/}
                                {/*        })(*/}
                                {/*            <Input*/}
                                {/*                placeholder="设备验证码"*/}
                                {/*            />,*/}
                                {/*        )}*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备位置：">
                                    {getFieldDecorator('equipmentPosition', {
                                        rules: [{required: true, message: '请选择设备位置！'}],
                                    })(
                                        <Select placeholder="请输入设备位置" mode="tags"
                                                onChange={(selectValue,options) => {
                                                    if (selectValue.length > 1) {
                                                        selectValue.pop(); // 删除多余项
                                                    }
                                                    setSitePosition({})
                                                    if (selectValue.length > 0){
                                                        if (options[0].props.children === options[0].props.value){
                                                            setSitePosition({
                                                                children: options[0].props.children
                                                            })
                                                        }else {
                                                            setSitePosition({
                                                                children: options[0].props.children,
                                                                value: options[0].props.value
                                                            })
                                                        }
                                                    }
                                                }}>
                                            {
                                                deviceAddress.map((item, index) => {
                                                    return <Option key={item.id}
                                                                   value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备类型：">
                                    {getFieldDecorator('type', {
                                        rules: [{required: true, message: '请选择设备类型！'}],
                                    })(
                                        <Select placeholder="请选择设备类型" showSearch>
                                            {
                                                alarmTypesList.map((item, index) => {
                                                    return <Option key={item.name} value={item.name}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                getFieldValue('type') === '工服识别'?
                                    <div>
                                        <Col span={12}>
                                            <Form.Item {...formItemLayout} label="设备账号：">
                                                {getFieldDecorator('DEV_USERNAME', {
                                                    rules: [{ required: true, message: '请输入设备账号！' }],
                                                })(
                                                    <Input
                                                        placeholder="设备账号"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item {...formItemLayout} label="设备密码：">
                                                {getFieldDecorator('DEV_PASSWORD', {
                                                    rules: [{ required: true, message: '请输入设备密码！' }],
                                                })(
                                                    <Input
                                                        placeholder="设备密码"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </div>:
                                    <div>
                                        <Col span={12}>
                                            <Form.Item {...formItemLayout} label="设备账号：">
                                                {getFieldDecorator('DEV_USERNAME')(
                                                    <Input
                                                        placeholder="设备账号"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item {...formItemLayout} label="设备密码：">
                                                {getFieldDecorator('DEV_PASSWORD')(
                                                    <Input
                                                        placeholder="设备密码"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </div>
                            }
                        </Form>
                        :modalType === 'edit'?
                        <Form style={{ height: 300 }}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备序列号：">
                                    {getFieldDecorator('equipmentNo', {
                                        rules: [{ required: true, message: '请输入设备序列号！' }],
                                    })(
                                        <Input
                                            disabled
                                            placeholder="请输入设备序列号"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备名称：">
                                    {getFieldDecorator('name', {
                                        rules: [{ required: true, message: '设备名称！' }],
                                    })(
                                        <Input
                                            placeholder="设备名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属机构：">
                                    {getFieldDecorator('regionId', {
                                        rules: [{ required: true, message: '请选择所属机构!' }],
                                    })(
                                        <Select placeholder="请选择所属机构" onChange={getAddAIVideoSiteList}>
                                            {
                                                childRegionData.map((item,index) => {
                                                    return (
                                                        <Option value={item.regionId+'&'+item.regionName} key={item.regionId} >{item.regionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属区域：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择区域！' }],
                                    })(
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                                            placeholder="请选择区域"
                                            allowClear
                                            onChange={selectSite}
                                        >
                                            {
                                                addAIVideoSiteList.length > 0 ?
                                                    addAIVideoSiteList.map((item,index) =>{
                                                        return <TreeNode value={item.siteId} title={item.siteName} key={item.siteId}>
                                                            {
                                                                item.positionList && item.positionList.length > 0?item.positionList.map(i =>{
                                                                    return <TreeNode value={i.sitePositionId+'-'+i.siteId} title={i.sitePositionName} key={i.sitePositionId+'-'+i.siteId}>
                                                                    </TreeNode>
                                                                }):(item.pigpenList && item.pigpenList.length > 0?item.pigpenList.map(i =>{
                                                                    return <TreeNode value={i.sitePigpenId+'-'+i.siteId} title={i.sitePigpenName} key={i.sitePigpenId+'-'+i.siteId}>
                                                                        {
                                                                            i.positionList && i.positionList.length > 0?i.positionList.map(j =>{
                                                                                return <TreeNode value={j.sitePositionId+'-'+j.siteId} title={j.sitePositionName} key={j.sitePositionId+'-'+j.siteId} />
                                                                            }):''
                                                                        }
                                                                    </TreeNode>
                                                                }):'')
                                                            }
                                                        </TreeNode>
                                                    })
                                                    :''
                                            }
                                        </TreeSelect>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备位置：">
                                    {getFieldDecorator('equipmentPosition', {
                                        rules: [{required: true, message: '请选择设备位置！'}],
                                    })(
                                        <Select placeholder="请输入设备位置" mode="tags"
                                                onChange={(selectValue,options) => {
                                                    if (selectValue.length > 1) {
                                                        selectValue.pop(); // 删除多余项
                                                    }
                                                    if (selectValue.length > 0){
                                                        if (options[0].props.children === options[0].props.value){
                                                            setSitePosition({
                                                                children: options[0].props.children
                                                            })
                                                        }else {
                                                            setSitePosition({
                                                                children: options[0].props.children,
                                                                value: options[0].props.value
                                                            })
                                                        }
                                                    }
                                                }}>
                                            {
                                                deviceAddress.map((item, index) => {
                                                    return <Option key={item.id}
                                                                   value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备类型：">
                                    {getFieldDecorator('type', {
                                        rules: [{required: true, message: '请选择设备类型！'}],
                                    })(
                                        <Select placeholder="请选择设备类型" showSearch>
                                            {
                                                alarmTypesList.map((item, index) => {
                                                    return <Option key={item.name} value={item.name}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>:<DeVideo destory={destoryVideo} style={{width: '100%'}}
                                         url={detailVideoUrl}></DeVideo>
                }
            </Modal>

            <Modal
                title='风险等级'
                visible={levelEditVisible}
                onCancel={cancelLevelEdit}
                maskClosable={false}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={saveLevel}>提交</Button>
                    </div>
                }>
                <div style={{margin: '0 auto',width: '260px'}}>
                    <Radio.Group onChange={(e)=>{
                        setLevelValue(e.target.value)
                    }} value={levelValue}>
                        <Radio value={'高'}>高风险</Radio>
                        <Radio value={'中'}>中风险</Radio>
                        <Radio value={'低'}>低风险</Radio>
                    </Radio.Group>
                </div>
            </Modal>
        </Container>

    )
}
// export default Form.create()(Camera)
const mapState = (state) => ({
    baseURL: state.baseURL,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Form.create()(Camera))
