import styled from 'styled-components'

export const Container = styled.div`
  height: 50px;
  width: 100%;
  .iconImg{
    img {
      margin-right: 3px;
      vertical-align: middle!important;
      margin-top: -2px;
    }
  }
`