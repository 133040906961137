import styled from 'styled-components'

export const Container = styled.div `
  height: 100%;
  .top{
    margin-bottom: 10px;
  }
  .bottom{
    height: calc(100% - 42px);
    overflow-y: scroll;
  }
`