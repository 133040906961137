import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------员工管理
    //获取人员列表
    getWashList(params) {
        return request({
            url: 'safety/wash/list',
            method: 'post',
            params
        })
    }
    //获取首页人员列表
    getHomeWashList(params) {
        return request({
            url: 'safety/wash/people/record',
            method: 'post',
            params
        })
    }
    //获取淋浴记录
    getSmartShowerById(params) {
        return request({
            url: 'safety/smartshowerRecord/byId',
            method: 'post',
            params
        })
    }
}

export default StoreSettingHttp
