import request from '../https'

class ShareRequest {

    //获取列表
    getMessageBoard(params) {
        return request({
            // url: 'safety/messageBoard/list',
            url: 'system/Communication/page',
            method: 'get',
            params
        })
    }
    //新增
    addMessageBoard(params) {
        return request({
            // url: 'safety/messageBoard/add',
            url: 'system/Communication/publish',
            contentType: 'application/json; charset=utf-8',
            method: 'post',
            data:params
        })
    }
    //获取最新一条
    getSelectOne(params) {
        return request({
            url: 'safety/messageBoard/selectOne',
            method: 'post',
            data:params
        })
    }
    //删除最新一条
    delMessageBoard(params) {
        return request({
            // url: 'safety/messageBoard/delete',
            url: 'system/Communication/' + params,
            method: 'DELETE',
            // data:params
        })
    }
}

export default ShareRequest