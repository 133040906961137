import React, {useEffect, useState} from "react";
import {Form, Button, Col, Select, Modal, Icon} from "antd";
import {Container} from './style'
import VideoRequest from '../../../../request/video-request'
import CommonHttp from '../../../../request/common-request'
import DeVideo from "../../../../component/DeVideo";
import Video01 from '../../../../img/video01.jpg'
import Video02 from '../../../../img/video02.jpg'
import Video03 from '../../../../img/video03.jpg'
import {EnvironmentOutlined,CaretRightOutlined} from '@ant-design/icons';
import { stringFormat } from "../../../../utils/homeFormat";

const _request = new VideoRequest();
const _commonRequest = new CommonHttp()


function Video(props) {

    const [videoData,setVideoData] = useState([])
    const [deviceAddress, setDeviceAddress] = useState([])   //设备位置
    const [deStoryVideo, setDeStoryVideo] = useState(false)
    const [detailVideoUrl, setDetailVideoUrl] = useState('')
    const [editVisible, setEditVisible] = useState(false)
    useEffect(()=>{
        getVideoMonitorList()
        getCarDeviceType()
    },[])
    const getVideoMonitorList = () => {
        let params = {
            current:1,
            size:2
        }
        _request.getVideoList(params).then(res => {
            setVideoData(res.list)
        })
    }

    //获取车辆设备位置列表
    const getCarDeviceType = () => {
        let params = {
            dictType: 'CAR_WASH_TYPE',
        }
        _commonRequest.getDictList(params).then(res => {
            setDeviceAddress(res.list)
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setDeStoryVideo(true)
        setEditVisible(false)
    }

    const playVideo = (e) => {
        setDeStoryVideo(false)
        setDetailVideoUrl(e)
        setEditVisible(true)
    }

    return (
        <Container>
            {
                videoData.length === 0?<div style={{width: '100%',textAlign: 'center',lineHeight: '260px',height: 270}}>暂无数据</div>:
                    videoData.map((item,index) =>{
                        return (
                            <div className='videoDiv'>
                                <img width='100%' style={{ borderRadius: '8px 8px 0 0' }} src={index === 1?Video01:(index === 2?Video02:Video03)}/>
                                <div style={{fontWeight: 600, color: 'rgba(0, 0, 0, 0.8)',fontSize: '14px',marginLeft: 10}}>{ stringFormat(item.setName,8) }</div>
                                <div style={{ fontSize: '12px',marginLeft: 10 }}><EnvironmentOutlined/> {stringFormat(item.location)}</div>
                                <div style={{display: 'flex',justifyContent: 'flex-end',marginTop: -5}}>
                                    <div className='playVideo' onClick={playVideo.bind(this,item.previewUrl)}>
                                        <CaretRightOutlined />
                                         播放视频
                                    </div>
                                </div>
                            </div>
                        )
                    })
            }

            <Modal
                title='监控视频'
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={''}
            >
                <DeVideo destory={deStoryVideo} style={{width: '100%'}}  url={detailVideoUrl}></DeVideo>
            </Modal>

        </Container>
    )
}

export default Form.create()(Video)