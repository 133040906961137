import React, { useState, useEffect } from 'react'
import {Container} from './style'

import {Button, Input, Modal, Table, Popconfirm, Col, Form, message, Icon, Select} from 'antd'
import DeTable from '../../../component/DeTable'
import { DatePicker } from 'antd';
import Qualified from '../../../img/goods/qualified.png'//合格图片
import NotQualified from '../../../img/goods/notQualified.png'//不合格
import Sterilizing from '../../../img/goods/sterilizing.png'//消毒中
import Reminder from '../../../img/goods/reminder.png'//提示
import NotImg from '../../../img/goods/notImg.png'//暂无图片
import Pass from '../../../img/goods/pass.png'//消毒通过
import NotPass from '../../../img/goods/notPass.png'//消毒通过

import moment from 'moment'

import StoreSettingHttp from '../../../request/demo'
import {stringFormat} from "../../../utils/homeFormat";
import CommonRequest from "../../../request/common-request";
import echarts from "../../../echarts_init";
import {quantile} from "echarts/lib/util/number";

const _common = new CommonRequest()
const _request = new StoreSettingHttp()
const { MonthPicker, RangePicker } = DatePicker;
const CutOff = {borderTop: '1px solid #E5E6EA', width: 'calc(100% + 48px)', marginLeft: -24}
const CutOff2 = {borderTop: '1px solid #E5E6EA', width: 'calc(100% + 48px)', marginLeft: -24, marginTop: 14}
const titleColor = {color: 'rgba(0, 0, 0, 0.65)'}
const colColor = {color: 'rgba(0, 0, 0, 0.95)'}

const Option = Select.Option;
function Demo(props) {
    const [goodsName, setName] = useState('')
    const [washSpotName, setWashSpotName] = useState('')
    const [startTime, setStartTime] = useState(moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD') + ' 00:00:00')
    const [endTime, setEndTime] = useState(moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59')
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '批次号',
                dataIndex: 'batchNumber',
                key: 'batchNumber',
            },
            {
                title: '类型',
                dataIndex: 'goodsType',
                key: 'goodsType',
            },
            {
                title: '状态',
                key: 'status',
                dataIndex: 'status',
                render: (text,record,index) => (
                    <span>{text === 0?'消毒中':'已结束'}</span>
                )
            },
            {
                title: '结果',
                dataIndex: 'result',
                key: 'result',
                render: (text,record,index) => (
                    <span>{text === 0?'不合格':(text === 1?'合格':'/')}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
                render: (text,record,index) => (
                    stringFormat(text,9)
                )
            },
            /*{
                title: '物品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
            },
            {
                title: '操作流程',
                dataIndex: 'workflowName',
                key: 'workflowName',
            },
            {
                title: '所属猪场',
                key: 'pigFramName',
                dataIndex: 'pigFramName',
            },
            {
                title: '操作人',
                key: 'operator',
                dataIndex: 'operator',
            },
            {
                title: '操作时间',
                key: 'operationDate',
                dataIndex: 'operationDate',
				render: (text, record, index) => {
					return <span>{moment(record.operationDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },*/
            {
                title: '开始时间',
                key: 'startDate',
                dataIndex: 'startDate',
				render: (text, record, index) => {
					return <span>{text?moment(text).format('YYYY-MM-DD HH:mm:ss'):''}</span>;
				}
            },
            {
                title: '结束时间',
                key: 'endDate',
                dataIndex: 'endDate',
				render: (text, record, index) => {
					return <span>{text?moment(text).format('YYYY-MM-DD HH:mm:ss'):''}</span>;
				}
            },
            {
                title: '操作',
                key: 'action',
                dataIndex: 'action',
				render: (text, record, index) => {
					return record.goodsType?<span className='tableA' onClick={goodsDetails.bind(this,record)}>详情</span>:''
				}
            },
        ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [workflowId, setWorkflowId] = useState()
    const [goodsVisible, setGoodsVisible] = useState(false)
    const [imageVisible, setImageVisible] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [washList, setWashList] = useState([])
    const [goodsObj, setGoodsObj] = useState({})
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const {washSpotId,time,endDate} = props
    useEffect(() => {
        washSpotList()
        if (washSpotId){
            setWorkflowId(washSpotId.split('&')[1])
            if (endDate){
                setStartTime(moment(time).format('YYYY-MM-DD HH:mm:ss'))
                setEndTime(moment(endDate).format('YYYY-MM-DD HH:mm:ss'))
            }else {
                let endDate2 = moment(time).format('YYYY-MM-DD ') + '23:59:59'
                setStartTime(moment(time).format('YYYY-MM-DD HH:mm:ss'))
                setEndTime(endDate2)
            }
            getAllDecontamination('',1,10,washSpotId,time,endDate)
        }else {
            getAllDecontamination()
        }
    }, [washSpotId,time,endDate])
    //获取物资列表
    const getAllDecontamination = (ButtonType, pageNo = 1, pageSize = 10,id,startDate,endDate2) => {
        let params = {
            goodsType:goodsName,
            washSpotId:workflowId,
            startDate:startTime,
            endDate: endTime,
            pageNo,
            pageSize
        }
        if (id){
            params.washSpotId = id.split('&')[1]
            params.result = id.split('&')[2]
            if (endDate2){
                params.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
                params.endDate = moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
            }else {
                let endDate3 = moment(startDate).format('YYYY-MM-DD ') + '23:59:59'
                params.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
                params.endDate = endDate3
            }
            // params.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
            // params.endDate = moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
        }
        // if (homeState){
        //     if (washSpotId){
        //         params.washSpotId = washSpotId.split('&')[1]
        //         if (endDate){
        //             params.startDate = moment(time).format('YYYY-MM-DD HH:mm:ss')
        //             params.endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss')
        //         }else {
        //             let endDate2 = moment(time).format('YYYY-MM-DD ') + '23:59:59'
        //             params.startDate = moment(time).format('YYYY-MM-DD HH:mm:ss')
        //             params.endDate = endDate2
        //         }
        //     }
        // }
        if (ButtonType === 'reset') {
            params = {
                startDate: moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD') + ' 00:00:00',
                endDate: moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59' ,
                pageNo,
                pageSize
            }
        }
        _request.findMaterialDecontamination(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

    //详情
    const goodsDetails = (e) =>{
        getGoodsDetails(e.id)
        setGoodsVisible(true)
    }

    const getGoodsDetails = (id) => {
        _request.goodsDetail({id}).then(res => {
            setGoodsObj(res)
            let ozoneDate = [];
            let ozoneData = [];
            let ultravioletDate = [];
            let ultravioletData = [];
            if (res.ozoneRecords.length !== 0){
                res.ozoneRecords.map(item => {
                    ozoneData.push(item.value);
                    ozoneDate.push(moment(item.createDate).format('HH:mm'))
                })
                setTimeout(()=>{
                    initEcharts1(ozoneDate,ozoneData)
                },100)
            }
            if (res.ultravioletRecords.length !== 0){
                res.ultravioletRecords.map(item => {
                    ultravioletData.push(item.value);
                    ultravioletDate.push(moment(item.createDate).format('HH:mm'))
                })
                setTimeout(()=>{
                    initEcharts2(ultravioletDate,ultravioletData)
                },100)
            }
        })
    }

    const goodsCancel = () => {
        setGoodsVisible(false)
    }
    const imageCancel = () => {
        setImageVisible(false)
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getAllDecontamination()
    }
    //重置
    const clickReset = () => {
		setName('')
		setWashSpotName('')
        setStartTime(moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD') + ' 00:00:00')
        setEndTime(moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59')
        setWorkflowId()
        setPageNo(1)
        setPageSize(10)
        getAllDecontamination('reset')
    }

    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getAllDecontamination('',pageNo,pageSize);
    }

    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }


    const initEcharts1 = (date, data) => {
        echarts.init(document.getElementById('charts-1'), 'co-op-theme').setOption({
            dataZoom: [{
                type: 'slider',
                bottom: 7,
                height: 10,
                startValue: 0,
                endValue: 8,
                showDetail: false,
            }],
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return '时间：' + params[0].axisValue + '<br/>' + '浓度：' + params[0].value + 'ppm'
                },
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                height: '76%',
                width: '90%',
                y2: 0,
                bottom:20,
                x: '4%'
            },
            yAxis: {
                type: 'value',
                name: '浓度（ppm）'
            },
            xAxis: {
                type: 'category',
                data: date,
                name: '时间',
                axisLabel: {
                    interval: 0
                },
            },
            series: [
                {
                    name: '臭氧浓度',
                    type: 'line',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: data
                }
            ]
        })
    }
    const initEcharts2 = (date, data) => {
        echarts.init(document.getElementById('charts-2'), 'co-op-theme').setOption({
            dataZoom: [{
                type: 'slider',
                bottom: 7,
                height: 10,
                startValue: 0,
                endValue: 8,
                showDetail: false,
            }],
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return '时间：' + params[0].axisValue + '<br/>' + '紫外线值：' + params[0].value + 'μW.s/cm²'
                },
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                height: '76%',
                width: '90%',
                y2: 0,
                bottom:20,
                x: '4%'
            },
            yAxis: {
                type: 'value',
                name: '紫外线值（μW.s/cm²）'
            },
            xAxis: {
                type: 'category',
                data: date,
                name: '时间',
                axisLabel: {
                    interval: 0
                },
            },
            series: [
                {
                    name: '紫外线',
                    type: 'line',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: data
                }
            ]
        })
    }

    //日期控件搜索
    function range(start, end) {
	  const result = [];
	  for (let i = start; i < end; i++) {
	    result.push(i);
	  }
	  return result;
	}

	function disabledDate(current) {
	  // Can not select days before today and today
	  return current && current < moment().endOf('day');
	}

	const openImg = (url) => {
        setImageVisible(true)
        setImageUrl(url)
    }

    function disabledDateTime() {
	  return {
	    disabledHours: () => range(0, 24).splice(4, 20),
	    disabledMinutes: () => range(30, 60),
	    disabledSeconds: () => [55, 56],
	  };
	}

	function disabledRangeTime(_, type) {
	  if (type === 'start') {
	    return {
	      disabledHours: () => range(0, 60).splice(4, 20),
	      disabledMinutes: () => range(30, 60),
	      disabledSeconds: () => [55, 56],
	    };
	  }
	  return {
	    disabledHours: () => range(0, 60).splice(20, 4),
	    disabledMinutes: () => range(0, 31),
	    disabledSeconds: () => [55, 56],
	  };
	}

    function rangeTimeChange(date){
    	if(date){
    		if(date[0]!=undefined){
    			setStartTime(date[0].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setStartTime('');
    		}
    		if(date[1]!=undefined){
    			setEndTime(date[1].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setEndTime('');
    		}
    	}
    }

    return (
        <Container>
            <div className="top">
                <Select placeholder="请选择洗消点" style={{width: 200, marginRight: 10}} showSearch value={workflowId}
                        onChange={e => setWorkflowId(e)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                    {
                        washList.map((item, index) => {
                            return <Option key={item.key} value={item.id}>{item.name}</Option>
                        })
                    }
                </Select>
                {/*<Input placeholder="请输入洗消点" style={{ width: 200, marginRight: 10 }} value={washSpotName} onPressEnter={clickSearch} onChange={(e) => setWashSpotName(e.target.value)}></Input>*/}
                <Input placeholder="请输入物品类型" style={{ width: 200, marginRight: 10 }} value={goodsName} onPressEnter={clickSearch} onChange={(e) => setName(e.target.value)}></Input>
                <RangePicker
                    ranges={{
                        '今天': [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                        '一个月': [moment().startOf('month'), moment().endOf('month')],
                    }}
	                value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD HH:mm:ss"), moment(endTime, "YYYY-MM-DD HH:mm:ss")]}
				    /*disabledTime={disabledRangeTime}*/
				    onChange={rangeTimeChange}
				    showTime={{
				    	hideDisabledOptions: true,
				        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				    }}
				    format="YYYY-MM-DD"
				/>

               	&nbsp;&nbsp;
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>查找</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div style={{marginBottom: 5}}>合计&nbsp;{tableTotal}&nbsp;条</div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>

            <Modal
                title={'物资详情'}
                visible={goodsVisible}
                centered={true}
                width={'50%'}
                onCancel={goodsCancel}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
            >
                <div style={{marginTop: -10}}>
                    <div style={{lineHeight: 2}}>
                        <Col span={12} style={colColor}><span style={titleColor}>物品批次：</span>{goodsObj.batchNumber}</Col>
                        <Col span={12} style={colColor}><span style={titleColor}>洗 &nbsp;消 &nbsp;点：</span>{goodsObj.washSpotName}</Col>
                        <Col span={12} style={colColor}><span style={titleColor}>开始信息：</span>{goodsObj.operator}于 {goodsObj.startDate?moment(goodsObj.startDate).format('MM/DD HH:mm'):''} 开始消毒</Col>
                        {/*<Col span={12}>开始操作人：{goodsObj.operator}</Col>*/}
                        {/*<Col span={12}>开始时间：{goodsObj.startDate?moment(goodsObj.startDate).format('MM/DD HH:mm'):''}</Col>*/}
                        {
                            goodsObj.endDate?
                                <Col span={12} style={colColor}><span style={titleColor}>结束信息：</span>{goodsObj.endOperator}于 {moment(goodsObj.endDate).format('MM/DD HH:mm')} 结束消毒</Col>
                                :
                                <Col span={12} style={colColor}><span style={titleColor}>结束信息：</span>消毒中</Col>
                        }
                        {/*<Col span={12}>结束操作人：{goodsObj.endOperator}</Col>*/}
                        {/*<Col span={12}>结束时间：{goodsObj.endDate?moment(goodsObj.endDate).format('MM/DD HH:mm'):''}</Col>*/}
                        <Col span={24} style={{color: 'rgba(0,0,0,0.95)',marginBottom: 14}}><span style={titleColor}>说&emsp;&emsp;明：</span>{goodsObj.remark}</Col>
                        {/*<div>状态：{goodsObj.status === 0?'消毒中':'已结束'}</div>*/}
                    </div>
                    <div style={{clear: 'both'}}></div>
                    <div style={CutOff}></div>
                    <div style={{marginTop: 14,fontWeight: 600,fontSize: 16}}>开始消毒图片</div>
                    <div style={{width: '100%',marginTop: 14,marginBottom: 14}}>
                        {
                            goodsObj.startImg ?
                                (goodsObj.startImg.length === 0 ? <div style={{width: 120,textAlign: 'center',borderRadius: '4px',border: '1px solid rgba(0,0,0,0.1)'}}>
                                            <img src={NotImg}/>
                                        </div> :
                                    goodsObj.startImg.map(item => {
                                        return <img style={{cursor: 'pointer', marginRight: '10px',borderRadius: '4px',border: '1px solid rgba(0,0,0,0.1)'}} width='120' height='80' onClick={openImg.bind(this,item)}
                                                    src={item}/>
                                    })
                                ) : ''
                        }
                    </div>
                    <div style={CutOff}></div>
                    <div style={{marginTop: 14,fontWeight: 600,fontSize: 16}}>消毒结果</div>
                    {/*{*/}
                    {/*    goodsObj.result === 1 ?*/}
                    {/*        <span style={{color: 'green',fontWeight: 500}}>合格</span> : (goodsObj.result === 0 ?*/}
                    {/*        <span style={{color: 'red',fontSize: '16px',fontWeight: 500}}>不合格</span> : '')*/}
                    {/*}*/}
                    {
                        goodsObj.ozoneRuleValue?
                        <div style={{marginTop: 14,display: 'flex',justifyContent: 'space-between'}}>
                            <div>
                                <div style={parseInt(goodsObj.ozoneResultTime) < parseInt(goodsObj.ozoneRuleTime)?{color: '#EE8D2C',fontWeight: '500'}:{color: '#237F2C',fontWeight: '500'}}>
                                    <img style={{marginTop: -2,marginRight: 5}} src={parseInt(goodsObj.ozoneResultTime) < parseInt(goodsObj.ozoneRuleTime)?NotPass:Pass}/>
                                    臭氧浓度{goodsObj.ozoneRuleValue}ppm，持续{goodsObj.ozoneResultTime}分钟
                                </div>
                                <div style={{color: 'rgba(60, 66, 73, 0.6)',fontSize: 12,marginLeft: 20}}>
                                    <img src={Reminder} style={{marginTop: -2,marginRight: 5}}/>
                                    合格消毒标准：臭氧浓度 {goodsObj.ozoneRuleValue}ppm 持续{goodsObj.ozoneRuleTime}分钟。
                                </div>
                            </div>
                            <div style={{marginTop: -40}}>
                                <img width='100' src={goodsObj.result === 0?NotQualified:(goodsObj.result === 1?Qualified:Sterilizing)}/>
                            </div>
                        </div>:''
                    }
                    {
                        goodsObj.ozoneRecords ?
                            (goodsObj.ozoneRecords.length !== 0 ?
                                <div>
                                    {/*<div style={{width: '100%',textAlign: 'center',marginTop: -40,fontWeight: 600}}>臭氧</div>*/}
                                    <div id='charts-1' style={{width: '100%', height: 200,marginBottom: 20}}></div>
                                </div> : '') : ''
                    }

                    {
                        goodsObj.ultravioletRuleValue?
                            <div style={{marginTop: 14,display: 'flex',justifyContent: 'space-between'}}>
                                <div>
                                    <div style={parseInt(goodsObj.ultravioletResultTime) < parseInt(goodsObj.ultravioletRuleTime)?{color: '#EE8D2C',fontWeight: '500'}:{color: '#237F2C',fontWeight: '500'}}>
                                        <img style={{marginTop: -2,marginRight: 5}} src={parseInt(goodsObj.ultravioletResultTime) < parseInt(goodsObj.ultravioletRuleTime)?NotPass:Pass}/>
                                        紫外线值{goodsObj.ultravioletRuleValue}μW.s/c㎡，持续{goodsObj.ultravioletResultTime}分钟
                                    </div>
                                    <div style={{color: 'rgba(60, 66, 73, 0.6)',fontSize: 12,marginLeft: 20}}>
                                        <img src={Reminder} style={{marginTop: -2,marginRight: 5}}/>
                                        合格消毒标准：紫外线值{goodsObj.ultravioletRuleValue}μW.s/cm² 持续{goodsObj.ultravioletRuleTime}分钟。
                                    </div>
                                </div>
                                <div style={{marginTop: -40}}>
                                    {
                                        goodsObj.ozoneRuleValue?<div style={{ height: 100 }}></div>:
                                            <img width='100' src={goodsObj.result === 0?NotQualified:(goodsObj.result === 1?Qualified:Sterilizing)}/>
                                    }
                                </div>
                            </div>:''
                    }
                    {/*<div style={goodsObj.result === 0?{color: 'red'}:{marginLeft: '10%'}}>紫外线值{goodsObj.ultravioletRuleValue}μW.s/c㎡，持续{goodsObj.ultravioletResultTime}分钟</div>*/}
                    {/*<div style={{color: 'rgba(0,0,0,0.4)',marginLeft: '20px'}}>合格消毒标准：紫外线值{goodsObj.ultravioletRuleValue}μW.s/c㎡ 持续{goodsObj.ultravioletRuleTime}分钟。</div>*/}
                    {
                        goodsObj.ultravioletRecords ?
                        (goodsObj.ultravioletRecords.length !== 0 ?
                            <div>
                                {/*{*/}
                                {/*    goodsObj.ozoneRuleValue?*/}
                                {/*        <div style={{width: '100%',textAlign: 'center',marginTop: 20,fontWeight: 600}}>紫外线</div>:*/}
                                {/*        <div style={{width: '100%',textAlign: 'center',marginTop: -40,fontWeight: 600}}>紫外线</div>*/}
                                {/*}*/}
                                <div id='charts-2' style={{width: '100%', height: 200}}></div>
                            </div> : '') : ''
                    }
                </div>
                <div style={CutOff2}></div>
                <div style={{marginTop: 14,fontWeight: 600,fontSize: 16}}>结束消毒图片</div>
                <div style={{width: '100%',marginTop: 14}}>
                    {
                        goodsObj.endImg ?
                            (goodsObj.endImg.length === 0 ? <div style={{width: 120,textAlign: 'center',borderRadius: '4px',border: '1px solid rgba(0,0,0,0.1)'}}>
                                        <img src={NotImg}/>
                                    </div> :
                                    goodsObj.endImg.map(item => {
                                        return <img style={{cursor: 'pointer', marginRight: '10px',borderRadius: '4px',border: '1px solid rgba(0,0,0,0.1)'}} width='120' height='80' onClick={openImg.bind(this,item)}
                                                    src={item}/>
                                    })
                            ) : ''
                    }
                </div>
                <Modal
                    title={'查看图片'}
                    visible={imageVisible}
                    centered={true}
                    width={'50%'}
                    onCancel={imageCancel}
                    maskClosable={false}
                    destroyOnClose={true}
                    footer={null}
                >
                    <img src={imageUrl} width='100%'/>
                </Modal>
            </Modal>
        </Container>

    )
}

export default Form.create()(Demo)
