import React, {useEffect, useState} from 'react'
import {Col, Form, Modal, Button} from "antd";
import { Container } from './style'
import AlertRequest from '../../../../request/AICamera-request'
import GridLayout from "react-grid-layout";
import _ from "lodash";
import {CloseOutlined} from "@ant-design/icons";
// import '../../../AICamera/area/subpage/styles.css'
// import '../../../AICamera/area/subpage/styles2.css'
import low from '../../../../img/low.png'
import high from '../../../../img/high.png'
import medium from '../../../../img/medium.png'
const _request = new AlertRequest()

function AICaution(props){

    const { clickDetails } = props

    const [tableData, setTableData] = useState([]) //表格数据
    const [itemTableData, setItemTableData] = useState([]) //表格数据
    const [layout, setLayout] = useState({})
    const [layoutWidth, setLayoutWidth] = useState(500)
    const [modalType, setModalType] = useState(true) //


    useEffect(()=>{
        getVideoMonitorList()
        let div = document.getElementById("cont");
        let w = div.offsetWidth;    // 返回元素的总宽度
        setLayoutWidth(w)
    },[])

    //获取列表
    const getVideoMonitorList = (siteId) => {
        let params = {
            // current: 1,
            // size: 7,
            siteId
        }
        _request.aiAlarmStatistics(params).then(res => {
            let arr = []
            if (res.length !== 0){
                res.forEach(item =>{
                    if (typeof item.h === 'number' && typeof item.y === 'number' && typeof item.x === 'number' && typeof item.w === 'number'){
                        arr.push(item)
                    }
                })
                if (arr.length === 0){
                    if (res.length < 9){
                        arr = res.map((item,index) =>{
                            let obj = {}
                            obj.siteName = item.siteName
                            obj.sitePigpenName = item.sitePigpenName
                            obj.sitePigpenId = item.sitePigpenId
                            obj.siteId = item.siteId
                            obj.highRisk = item.highRisk
                            obj.mediumRisk = item.mediumRisk
                            obj.lowRisk = item.lowRisk
                            obj.siteType = item.siteType
                            if (index === 0){
                                obj.w = 1
                                obj.x = 0
                                obj.y = 0
                                obj.h = 2
                                obj.i = '0'
                            }
                            if (index === 1){
                                obj.w = 1
                                obj.x = 1
                                obj.y = 0
                                obj.h = 2
                                obj.i = '1'
                            }
                            if (index === 2){
                                obj.w = 1
                                obj.x = 2
                                obj.y = 0
                                obj.h = 2
                                obj.i = '2'
                            }
                            if (index === 3){
                                obj.w = 1
                                obj.x = 0
                                obj.y = 2
                                obj.h = 2
                                obj.i = '3'
                            }
                            if (index === 4){
                                obj.w = 1
                                obj.x = 1
                                obj.y = 2
                                obj.h = 2
                                obj.i = '4'
                            }
                            if (index === 5){
                                obj.w = 1
                                obj.x = 2
                                obj.y = 2
                                obj.h = 2
                                obj.i = '5'
                            }
                            if (index === 6){
                                obj.w = 1
                                obj.x = 0
                                obj.y = 4
                                obj.h = 2
                                obj.i = '6'
                            }
                            if (index === 7){
                                obj.w = 1
                                obj.x = 1
                                obj.y = 4
                                obj.h = 2
                                obj.i = '7'
                            }
                            if (index === 8){
                                obj.w = 1
                                obj.x = 2
                                obj.y = 4
                                obj.h = 2
                                obj.i = '8'
                            }
                            return obj
                        })
                    }else {
                        arr = res.slice(0,9).map((item,index) =>{
                            let obj = {}
                            obj.sitePigpenName = item.sitePigpenName
                            obj.sitePigpenId = item.sitePigpenId
                            obj.siteName = item.siteName
                            obj.siteId = item.siteId
                            obj.highRisk = item.highRisk
                            obj.mediumRisk = item.mediumRisk
                            obj.lowRisk = item.lowRisk
                            obj.siteType = item.siteType
                            if (index === 0){
                                obj.w = 1
                                obj.x = 0
                                obj.y = 0
                                obj.h = 2
                                obj.i = '0'
                            }
                            if (index === 1){
                                obj.w = 1
                                obj.x = 1
                                obj.y = 0
                                obj.h = 2
                                obj.i = '1'
                            }
                            if (index === 2){
                                obj.w = 1
                                obj.x = 2
                                obj.y = 0
                                obj.h = 2
                                obj.i = '2'
                            }
                            if (index === 3){
                                obj.w = 1
                                obj.x = 0
                                obj.y = 2
                                obj.h = 2
                                obj.i = '3'
                            }
                            if (index === 4){
                                obj.w = 1
                                obj.x = 1
                                obj.y = 2
                                obj.h = 2
                                obj.i = '4'
                            }
                            if (index === 5){
                                obj.w = 1
                                obj.x = 2
                                obj.y = 2
                                obj.h = 2
                                obj.i = '5'
                            }
                            if (index === 6){
                                obj.w = 1
                                obj.x = 0
                                obj.y = 4
                                obj.h = 2
                                obj.i = '6'
                            }
                            if (index === 7){
                                obj.w = 1
                                obj.x = 1
                                obj.y = 4
                                obj.h = 2
                                obj.i = '7'
                            }
                            if (index === 8){
                                obj.w = 1
                                obj.x = 2
                                obj.y = 4
                                obj.h = 2
                                obj.i = '8'
                            }
                            return obj
                        })
                    }
                }
                let arr2 = []
                arr.forEach(i =>{
                    let obj = {}
                    obj.w = i.w
                    obj.x = i.x
                    obj.y = i.y
                    obj.h = i.h
                    obj.i = i.i
                    arr2.push(obj)
                })
                console.log(arr);
                console.log(arr2);
                if (siteId && arr.length > 0){
                    setLayout(arr2)
                    setItemTableData(arr)
                    setModalType(false)
                }else {
                    setModalType(true)
                    setLayout(arr2)
                    setTableData(arr)
                }
            }
        })
    }
    const clickRow = (e,item,risk,isItem) => {
        e.stopPropagation();
        clickDetails(item,risk);
        if (isItem){
            getVideoMonitorList(item.siteId)
        }
    }

    const backArea = () => {
        getVideoMonitorList()
    }

    return(
        <Container id="cont">
            <div className='back'>
                {
                    !modalType?
                        <span className='tableA' onClick={backArea}>返回区域</span>:''
                }
            </div>
            {
                modalType?
                <GridLayout
                    className="layout"
                    cols={3}
                    rowHeight={44}
                    width={layoutWidth}
                    isDraggable={false}
                    isBounded={false}
                >
                    {tableData.length > 0 ? _.map(tableData, function (l, i) {
                        return (
                            <div className="layoutItem" onClick={(e) => clickRow(e, l,'',true)} key={l.i}
                                 data-grid={{x: l.x, y: l.y, w: l.w, h: l.h, static: true}}>
                                <div className='itemText'>{l.siteName}</div>
                                <div className='risk'>
                                    {l.highRisk > 0 ? <div
                                        style={{backgroundImage: `url(${high})`}}
                                        className='highRisk'
                                        onClick={(e) => clickRow(e, l, '高',true)}>
                                        {l.highRisk}
                                    </div> : ''}
                                    {l.mediumRisk > 0 ? <div
                                        style={{backgroundImage: `url(${medium})`}}
                                        className='mediumRisk'
                                        onClick={(e) => clickRow(e, l, '中',true)}>
                                        {l.mediumRisk}
                                    </div> : ''}
                                    {l.lowRisk > 0 ? <div
                                        style={{backgroundImage: `url(${low})`}}
                                        className='lowRisk'
                                        onClick={(e) => clickRow(e, l, '低',true)}>
                                        {l.lowRisk}
                                    </div> : ''}
                                </div>
                            </div>
                        );
                    }) : ''}
                </GridLayout>
            :''}
            {
                !modalType?
                <GridLayout
                    className="layout"
                    style={{marginTop: '-21px'}}
                    cols={3}
                    rowHeight={44}
                    width={layoutWidth}
                    isDraggable={false}
                    isBounded={false}
                >
                    {itemTableData.length > 0 ? _.map(itemTableData, function (l, i) {
                        return (
                            <div className="layoutItem" onClick={(e) => clickRow(e, l)} key={l.i}
                                 data-grid={{x: l.x, y: l.y, w: l.w, h: l.h, static: true}}>
                                <div className='itemText'>{l.sitePigpenName}</div>
                                <div className='risk'>
                                    {l.highRisk > 0 ? <div
                                        style={{backgroundImage: `url(${high})`}}
                                        className='highRisk'
                                        onClick={(e) => clickRow(e, l, '高')}>
                                        {l.highRisk}
                                    </div> : ''}
                                    {l.mediumRisk > 0 ? <div
                                        style={{backgroundImage: `url(${medium})`}}
                                        className='mediumRisk'
                                        onClick={(e) => clickRow(e, l, '中')}>
                                        {l.mediumRisk}
                                    </div> : ''}
                                    {l.lowRisk > 0 ? <div
                                        style={{backgroundImage: `url(${low})`}}
                                        className='lowRisk'
                                        onClick={(e) => clickRow(e, l, '低')}>
                                        {l.lowRisk}
                                    </div> : ''}
                                </div>
                            </div>
                        );
                    }) : ''}
                </GridLayout>:''
            }
        </Container>
    )
}

export default Form.create()(AICaution)
