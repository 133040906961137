import React from "react";
import {Container} from './style'
import { Form,Tabs } from "antd";
import WashSet from "../washSet";
import WashGroup from "../washGroup";
import WorkFlow from  "../workFlowPic"

const { TabPane } = Tabs;
function WashAdmin(props){

    return (
        <Container>
            <Tabs defaultActiveKey="1">
                <TabPane tab="洗消中心管理" key="1">
                    <WashGroup></WashGroup>
                </TabPane>
                <TabPane tab="洗消点设置" key="2" style={{ marginLeft: '20px' }}>
                    <WashSet></WashSet>
                </TabPane>
                <TabPane tab="洗消流程设置" key="3" style={{ marginLeft: '20px' }}>
                    <WorkFlow></WorkFlow>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(WashAdmin)