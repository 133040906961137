import request from '../https'

class VideoRequest {
    //获取列表
    getVideoList(param) {
        return request({
            url: 'safety/videoLiveConfig/page',
            method: 'GET',
            params: param
        })
    }
    //新增
    addVideoMonitor(param) {
        return request({
            url: 'safety/videoLiveConfig/add',
            method: 'POST',
            data: param
        })
    }
    //修改
    editVideoMonitor(param) {
        return request({
            url: 'safety/videoLiveConfig/update',
            method: 'POST',
            data: param
        })
    }
    //删除
    deleteVideoMonitor(param) {
        return request({
            url: 'safety/videoLiveConfig/'+param,
            method: 'DELETE',
            // data: param
        })
    }
}

export default VideoRequest