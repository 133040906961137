import React from "react";
import {Container} from './style'
import { Form,Tabs } from "antd";
import Staff from "../staffTreeAll";
import Visitor from "../visitorTreeAll";

const { TabPane } = Tabs;
function PersonGuard(props){

    return (
        <Container>
            <Tabs defaultActiveKey="1">
                <TabPane tab="员工管理" key="1">
                    <Staff></Staff>
                </TabPane>
                <TabPane tab="临时访客登记" key="2" style={{ marginLeft: '20px' }}>
                    <Visitor></Visitor>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(PersonGuard)