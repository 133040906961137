import React, {useEffect, useState} from "react";
import { Container } from './style'
import {Button, Col, Form, Tabs} from "antd";
import All from "../../../../request/other-request";

const {TabPane} = Tabs;
const _request = new All()

function Fast(props) {
    const { clickDetails } = props

    const [list, setList] = useState([])

    useEffect(()=>{
        getList()
    },[])

    const skipClick = (e) => {
        clickDetails(e)
    }

    const getList = () => {
        _request.menuCollectionList().then(res => {
            setList(res)
        })
    }

    return (
        <Container>
            <Tabs defaultActiveKey="1" type="card" style={{width: '100%'}}>
                <TabPane tab="快捷操作" key="1" style={{ marginLeft: 10,overflow: 'auto' }}>
                    <div className='title' style={{ marginTop: 6 }}>
                        人/车/物
                    </div>
                    <div className='content'>
                        <Col span={12}>
                            <Button onClick={skipClick.bind(this,'136&员工管理')}>新增员工</Button>
                        </Col>
                        <Col span={12}>
                            <Button style={{ paddingLeft: 5,paddingRight: 5 }} onClick={skipClick.bind(this,'137&临时访客登记')}>新增临时人员</Button>
                        </Col>
                        <Col span={12}>
                            <Button onClick={skipClick.bind(this,'105&车辆信息')}>新增车辆</Button>
                        </Col>
                        <Col span={12}>
                            <Button onClick={skipClick.bind(this,'106&临时车辆登记')} style={{ paddingLeft: 5,paddingRight: 5 }}>新增临时车辆</Button>
                        </Col>
                        <Col span={24}>
                            <Button onClick={skipClick.bind(this,'140&物资登记')}>新增物资</Button>
                        </Col>
                    </div>
                    {/*<Col span={24}>*/}
                    {/*    <div className='title' style={{ marginTop: '20px' }}>*/}
                    {/*        设备*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    {/*<div className='content'>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <Button onClick={skipClick.bind(this,'147&人车识别')}>新增监控</Button>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <Button onClick={skipClick.bind(this,'22&智能水表')}>新增水表</Button>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <Button onClick={skipClick.bind(this,'21&人车识别')}>人车识别设备</Button>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <Button onClick={skipClick.bind(this,'23&闸机控制')}>新增闸机</Button>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <Button onClick={skipClick.bind(this,'150&智能花洒')}>新增花洒</Button>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <Button onClick={skipClick.bind(this,'23&智能电表')}>新增电表</Button>*/}
                    {/*    </Col>*/}
                    {/*</div>*/}
                </TabPane>
                <TabPane tab="收藏" key="2" style={{ marginLeft: 10,overflow: 'auto' }}>
                    <div>
                        {
                            list.length === 0?<div style={{width: '100%',textAlign: 'center',lineHeight: '300px'}}>暂无数据</div>:
                            list.map((item,index)=>{
                                return (
                                    <Col span={12} style={{ marginTop: 10 }}>
                                        <Button onClick={skipClick.bind(this,item.router+'&'+item.name)}>{item.name}</Button>
                                    </Col>
                                )
                            })
                        }
                    </div>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(Fast)