import styled from 'styled-components'

export const Container = styled.div`
    margin: 0!important;
  .auditFlex{
    display: flex;
    justify-content: space-between;
    border-bottom: #EBEEF5 1px solid;
    margin-top: 10px;
    .noData{
      width: 100%;
      height: 100%;
      line-height: 230px!important;
      text-align: center!important;
    }
    .hearImg{
      height: 46px;
    }
    .left{
      margin-bottom: 10px;
      .credNo{
        //font-weight: bold;
        margin-bottom: 5px;
      }
    }
    .right{
      margin-bottom: 10px;
      font-size: 10px;
      .audit {
        color: #2F7ECF;
        width: 57px;  
        padding: 2px 0;
        text-align: center;
        float: right;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #2F7ECF;
        &:hover {
          background-color: #358AFF;
          color: white;
        }
      }
      .yetAudit {
        color: rgb(86, 157, 28);
        width: 57px;  
        padding: 5px 0 6px 0;
        text-align: center;
        float: right;
        border-radius: 4px;
      }
    }
  }
  .auditDiv{
    height: 285px;
    overflow: auto;
    padding: 0 10px;
  }
  .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab-active{
    border-bottom: none!important;
  }
  .ant-tabs-bar{
    border: none!important;
    border-radius: 0!important;
    background-color: #F5F5F5!important;
  }
  .ant-table .ant-table-thead > tr > th{
    border: none!important;
  }
  .ant-tabs-nav {
    font-size: 14px;
    .ant-tabs-tab-active {
      color: #2F7ECF!important;
      background-color: #FFFFFF;
    }
  }
`