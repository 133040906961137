import styled from 'styled-components'

export const Container = styled.div `
    height: 100%;
    .top{
        margin-bottom: 4px;
    }
    .bottom{
        height: calc(100% - 32px);
        overflow-y: scroll;
    }
  .ant-tabs-bar{
    background-color: #FFFFFF!important;
  }
  .ant-tabs-nav .ant-tabs-tab-active{
    font-weight: 700;
  }
  .ant-tabs-nav-scroll{
    font-weight: 600;
    margin-top: -14px!important;
  }
  .ant-pagination{
    margin-bottom: 12px!important;
  }
`
