import React, {useState, useEffect, useRef} from 'react'
import {Container} from './style'

import {Button, Form, message, Table, Modal} from 'antd'

import AICameraRequest from '../../../request/AICamera-request'

const _request = new AICameraRequest()
const { confirm } = Modal;
function RiskLevel(props) {

    const {getFieldValue, setFieldsValue, getFieldDecorator} = props.form
    const [riskLevelList, setRiskLevelList] = useState([])
    const [list, setList] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [tableColunm2, setTableColunm2] = useState([{
        title: '',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
    }])
    // const [riskLevel, setRiskLevel] = useState('高')
    const [riskLevel1, setRiskLevel] = useState('高')
    const riskLevel = useRef('高');

    //表格结构

    useEffect(() => {
        getVideoMonitorList()
    }, [])

    //获取列表
    const getVideoMonitorList = () => {
        _request.getRiskLevelDate().then(res => {
            let arr = [{
                title: '',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
            }]
            let arr2 = []
            let list2 = []
            res.aiRisklevelList.forEach(i =>{
                arr2.push({
                    name: i.alarmType,
                })
                i.tBdAiRisklevels.forEach(j =>{
                    list2.push(j)
                })
            })
            let arr3 = arr2.map(i =>{
                let obj = {}
                obj.name = i.name
                list2.forEach(j =>{
                    if (i.name === j.alarmType){
                        obj[j.siteName] = j.riskLevel
                        obj[j.siteName+'id'] = j.id
                    }
                })
                return obj
            })
            setRiskLevelList(arr3)

            setList(res.siteNameList)
            res.siteNameList.forEach(i =>{
                arr.push(
                    {
                        // title: i,
                        title: (val) => {
                            const style = {
                                maxWidth: '90px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }
                            return (
                                <div style={style} title={i}>{i}</div>
                            )
                        },
                        key: i,
                        align: 'center',
                        ellipsis: true,
                        render: (text, record, index) => (
                            <div onClick={setTableRiskLevel.bind(this,record,index,i,arr3)} style={{ cursor: "pointer" }}>
                                <div style={{
                                    width: 20,
                                    backgroundColor: record[i] === "高"?'red':(record[i] === "中"?'orange':'green'),
                                    height: 20,
                                    borderRadius: '50%',
                                    margin: '0 auto'
                                }}></div>
                            </div>
                        ),
                    })
            })

            setTableColunm2(arr)
        })
    }


    //设置表格风险等级
    const setTableRiskLevel = (record,index,i,arr3) => {
        const arr = [...arr3]
        arr[index][i] = riskLevel.current
        setRiskLevelList(arr)
    }

    const selectRiskLevel = (value) => {
        setRiskLevel(value)
        riskLevel.current = value
    }

    const save = () => {
        let arr = [];
        for (let i = 0;i < list.length;i++){
            arr = arr.concat(riskLevelList.map(j => {
                return {riskLevel: j[list[i]],id: j[list[i]+'id']}
            }))
        }
        setConfirmLoading(true)
        let overwrite = true
        confirm({
            title: '是否按规则覆盖该区域设备设置的风险等级?',
            onOk() {
                overwrite = true
                let param = {
                    tBdAiRisklevels: arr,
                    overwrite
                }
                _request.updateRiskLevel(param).then(res =>{
                    setConfirmLoading(false)
                    message.info('修改成功！')
                    getVideoMonitorList()
                })
            },
            onCancel() {
                overwrite = false
                let param = {
                    tBdAiRisklevels: arr,
                    overwrite
                }
                _request.updateRiskLevel(param).then(res =>{
                    setConfirmLoading(false)
                    message.info('修改成功！')
                    getVideoMonitorList()
                })
            },
        });
    }

    return (
        <Container>
            <div className="top" style={{ display: 'flex' }}>
                <div style={{
                    display: 'flex',
                    cursor: "pointer",
                    // fontWeight: riskLevel.current === "高"?'bold':'400',
                    marginTop: 6
                }} className={riskLevel.current === "高"?'checked':''} onClick={selectRiskLevel.bind(this,'高')}>
                    <div style={{
                        height: 20,
                        width: 20,
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        marginRight: 10
                    }}></div>
                    高风险
                </div>
                <div style={{
                    display: 'flex',
                    marginLeft: 20,
                    cursor: "pointer",
                    // fontWeight: riskLevel.current === "中"?'bold':'400',
                    marginTop: 6
                }} className={riskLevel.current === "中"?'checked':''} onClick={selectRiskLevel.bind(this,'中')}>
                    <div style={{
                        height: 20,
                        width: 20,
                        backgroundColor: 'orange',
                        borderRadius: '50%',
                        marginRight: 10
                    }}></div>
                    中风险
                </div>
                <div style={{
                    display: 'flex',
                    marginLeft: 20,
                    cursor: "pointer",
                    // fontWeight: riskLevel.current === "低"?'bold':'400',
                    marginTop: 6
                }} className={riskLevel.current === "低"?'checked':''} onClick={selectRiskLevel.bind(this,'低')}>
                    <div style={{ height: 20,
                        width: 20,
                        backgroundColor: 'green',
                        borderRadius: '50%',
                        marginRight: 10
                    }}></div>
                    低风险
                </div>
                <div style={{ flex: 1,display: 'flex',justifyContent: 'flex-end' }}>
                    <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={save}>保存</Button>
                </div>
            </div>
            <div className="bottom">
                <Table
                    dataSource={riskLevelList}
                    bordered
                    columns={tableColunm2}
                    pagination={false}
                />
            </div>

        </Container>

    )
}

export default Form.create()(RiskLevel)
