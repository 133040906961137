import styled from 'styled-components'

export const Container = styled.div`
  height: 103%;
  overflow: auto;
  background-color: #E6E9EC;
  margin-top: -10px!important;
  margin-left: -10px!important;
  margin-right: -10px!important;
  padding: 10px!important;
  .homeCol{
    box-shadow: 0 2px 8px 0 #DDDDDD;
    border-radius: 8px;
    background: #FFFFFF;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container{
    height: 40px!important;
  }
  .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab{
    background: none;
    color: black!important;;
    //border-width: 0;
    margin: 0!important;
    height: 40px!important;
    border-radius: 0!important;
    box-shadow: none!important;
    line-height: 40px!important;
    //font-size: 12px!important;
  }
  .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab-active{
    color: #0D4DE2!important;
    font-weight: bold!important;;
  }
  .ant-tabs.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active{
    background-color: white!important;
  }
`