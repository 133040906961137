import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 10px;
  .videoDiv{
    width: 49%;
    margin-left: 10px;
    height: 257px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    div{
      margin-top: 10px;
    }
    .playVideo{
      padding: 5px 15px;
      cursor: pointer;
      border-radius: 5px;
      border: #358AFF solid 1px;
      color: #358AFF;
      margin-bottom: 10px;
      margin-right: 10px;
      &:hover{
        color: #FFFFFF;
        background-color: #358AFF;
      }
    }
  }
`