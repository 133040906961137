import React, {useState, useEffect} from 'react'
import {Container} from './style'

import {Button, Input, Select, Modal, DatePicker, Popconfirm, Col, Form, message, InputNumber, Popover} from 'antd'

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import ShowerRequest from "../../../request/shower-request";
import CommonHttp from '../../../request/common-request'


const Option = Select.Option;
const _request = new ShowerRequest()
const _commonrequest = new CommonHttp()

function Shower(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [detailVisible, setDetailVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [isLongShower, setIsLongShower] = useState(false) //编辑模态框加载图标
    const [washSpotName, setWashSpotName] = useState('')
    const [equipmentNo, setEquipmentNo] = useState('')
    const [pushList, setPushList] = useState([])
    const [versionsList, setVersionsList] = useState(['V1.0','V2.0'])

    const dateFormat = "YYYY-MM-DD"

    const tableColunm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '洗消点',
            dataIndex: 'washSpotName',
            key: 'washSpotName',
        },
        {
            title: '花洒位置',
            key: 'equipmentPosition',
            dataIndex: 'equipmentPosition',
            render: (text, record, index) => (
                electricPositionData.map((item, index1) => {
                    if (record.equipmentPosition === item.dictValue) {
                        return <span>{item.dictDesc}</span>
                    }
                })

            )
        },
        {
            title: '设备名称',
            dataIndex: 'equipmentName',
            key: 'equipmentName',
        },
        {
            title: '设备号',
            dataIndex: 'equipmentNo',
            key: 'equipmentNo',
        },
        {
            title: '在线状态',
            key: 'isOnline',
            dataIndex: 'isOnline',
            render: (text, record, index) => (
                <div style={{ position: 'relative' }}>
                    {
                        text !== null?text?
                            <Popover content={'最后通讯时间：' + (record.offlineTime?moment(record.offlineTime).format("YYYY-MM-DD HH:mm:ss"):'-')} title="设备在线">
                                <span style={{color: 'green'}}>在线</span>
                            </Popover>
                            :<Popover content={'设备离线时间：' + (record.offlineTime?moment(record.offlineTime).format("YYYY-MM-DD HH:mm:ss"):'-')} title="设备已离线">
                                <span style={{color: 'red'}}>离线</span>
                            </Popover>:'-'
                    }
                </div>
            )
        },
        {
            title: '设备版本',
            key: 'version',
            dataIndex: 'version',
        },
        {
            title: '操作人',
            key: 'createUser',
            dataIndex: 'createUser',
        },
        {
            title: '操作日期',
            dataIndex: 'createDate',
            key: 'createDate',
            render: (text, record, index) => {
                return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
                    <span className='tableA' style={{marginRight: 8}}
                          onClick={openModal.bind(this, 'edit', record)}>编辑</span>
                    <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消"
                                onConfirm={deleteRow.bind(this, record.id)}>
                        <span className='tableADelete'>删除</span>
                    </Popconfirm>
                </span>
            ),
        },
    ]


    const tableDetailColunm = [
        {
            title: '洗消点',
            dataIndex: 'washSpotName',
            key: 'washSpotName',
        },
        {
            title: '姓名',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '人员类型',
            key: 'type',
            dataIndex: 'type',
            render: (text, record, index) => {
                return <span>{text === 0?'员工':'临时员工'}</span>;
            }
        },
        {
            title: '手机号',
            key: 'phone',
            dataIndex: 'phone',
        },
        {
            title: '账号',
            key: 'code',
            dataIndex: 'code',
        },
        {
            title: '密码',
            key: 'password',
            dataIndex: 'password',
        },
        {
            title: '推送时间',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => {
                return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
            }
        },
        {
            title: '状态',
            key: 'isSuccess',
            dataIndex: 'isSuccess',
            render: (text, record, index) => {
                return <span>{text?'推送成功':'推送失败'}</span>;
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return(
                    record.isSuccess?'':<span onClick={pushAgain.bind(this,record)} style={{ cursor: 'pointer',color: '#2F7ECF' }}>推送</span>
                );
            }
        },
    ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [pushTotal, setPushTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [electricPositionData, setElectricPositionData] = useState([]) //智能电表位置列表
    const [washSpotData, setWashSpotData] = useState([]) //洗消点列表
    const [paramData, setParamData] = useState({})
    const [pushVisible, setPushVisible] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [pageNo2, setPageNo2] = useState(1)
    const [pageSize2, setPageSize2] = useState(10)


    const staffList = [{
        id: 0,
        name: '员工'
    },{
        id: 1,
        name: '临时人员'
    }]

    const isSuccess = [{
        id: 1,
        name: '推送成功'
    },{
        id: 0,
        name: '推送失败'
    }]

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        },
    }
    const {getFieldDecorator,setFieldsValue,getFieldValue,validateFields} = props.form
    useEffect(() => {
        getElectricMeterList()
        getDictList()
        getWashSpotList()
        // getIsLongoorSmartShower()
    }, [])
    //获取列表
    const getElectricMeterList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            washName: washSpotName,
            equipmentNo: equipmentNo,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getSmartShowerList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //获取是否使用小龙潜行花洒
    const getIsLongoorSmartShower = () =>{
        _request.isLongoorSmartShower().then(res =>{
            setIsLongShower(res)
        })
    }
    //获取智能花洒位置列表
    const getDictList = () => {
        let params = {
            dictType: 'SMARTSHOWER_TYPE'
        }
        _commonrequest.getDictList(params).then(res => {
            setElectricPositionData(res.list)
        })
    }

    //推送
    const pushNxinShower = (record) => {
        let param = {
            id: record.id
        }
        _request.pushNxinShower(param).then(res =>{
            message.info('推送成功！')
            getElectricMeterList()
        })
    }

    //获取当前登录用户下的洗消点列表
    const getWashSpotList = () => {
        let params = {}
        _commonrequest.getWashSpotList(params).then(res => {
            setWashSpotData(res)
        })
    }

    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteSmartShowerMeter(params).then(res => {
            message.info('删除成功！')
            getElectricMeterList()
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        setModalType(type)
        setEditVisible(true)
        if (type !== 'add') {
            setTimeout(() => {
                setFieldsValue({
                    equipmentPosition: record.equipmentPosition,
                    equipmentNo: record.equipmentNo,
                    washSpotId: record.washSpotId,
                    equipmentName: record.equipmentName,
                    equipmentImei: record.equipmentImei,
                    version: record.version,
                    equipmentProductionDate: moment(record.equipmentProductionDate),
                    id: record.id,
                })
            }, 0)
        }

    }

    //打开参数设置页
    const openDetailModal = () => {
        setDetailVisible(true)
        setModalType('')
        setTimeout(() => {
            setFieldsValue({
                washSpotId: washSpotData[0].id
            })
        },0)
        getSmartShowerFarm2(washSpotData[0].id)
    }

    const getSmartShowerFarm2 = (e) => {
        setFieldsValue({
            duration: '',
            temperature: ''
        })
        let param = {
            washSpotId: e
        }
        _request.getSmartShowerFarm(param).then(res => {
            setTimeout(() => {
                setParamData({
                    id: res.id,
                    accountNumber: res.accountNumber
                })
                setFieldsValue({
                    duration: parseInt(res.duration),
                    temperature: parseInt(res.temperature)
                })
            }, 0)
        })
    }

    //编辑页提交
    const editConfire = () => {
        validateFields((err, value) => {
            if (!err) {
                // setConfirmLoading(true)
                let washName = washSpotData.filter(item => item.id === getFieldValue('washSpotId'))[0].name;
                if (modalType === 'add') {
                    let params = {
                        equipmentPosition: getFieldValue('equipmentPosition'),
                        equipmentNo: getFieldValue('equipmentNo'),
                        washSpotId: getFieldValue('washSpotId'),
                        washSpotName: washName,
                        equipmentName: getFieldValue('equipmentName'),
                        equipmentImei: getFieldValue('equipmentImei'),
                        version: getFieldValue('version'),
                        equipmentProductionDate: moment(getFieldValue('equipmentProductionDate')).format('YYYY-MM-DD')
                    }
                    _request.addSmartShowerList(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getElectricMeterList()
                    })
                } else {
                    let params = {
                        equipmentPosition: getFieldValue('equipmentPosition'),
                        equipmentNo: getFieldValue('equipmentNo'),
                        washSpotId: getFieldValue('washSpotId'),
                        washSpotName: washName,
                        id: getFieldValue('id')
                    }
                    if (getFieldValue('version') === 'V2.0'){
                        params.equipmentName = getFieldValue('equipmentName')
                    }
                    _request.updateSmartShowerMeter(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getElectricMeterList()
                    })
                }
            }
        })
    }
    //选择时间回调
    const changeDate = (date) => {
        console.log(date);
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
        setPushVisible(false)
    }
    //参数设置提交
    const paramSubmit = () => {
        validateFields((err, value) => {
            if (!err) {
                let param = {
                    // id: paramData.id,
                    // accountNumber: paramData.accountNumber,
                    duration: getFieldValue('duration'),
                    temperature: getFieldValue('temperature'),
                    washSpotId: getFieldValue('washSpotId')
                }
                _request.updateSmartShowerFarm(param).then(res => {
                    setConfirmLoading(false)
                    message.info('设置成功！')
                    setDetailVisible(false)
                })
            }
        })
    }
    //参数设置页取消
    const cancelDetail = () => {
        setDetailVisible(false)
        setConfirmLoading(false)
        setPushVisible(false)
    }

    const pushModal = () => {
        setPushVisible(true)
        getLogList()
    }

    const pushAgain = (e) => {
        let param = {
            id: e.id
        }
        _request.pushAgain(param).then(res => {
            message.info('推送成功！')
            getLogList()
        })
    }

    const getLogList = (ButtonType,pageNo = 1,pageSize = 10) => {
        let param = {
            pageNo,
            pageSize,
            isSuccess: getFieldValue('isSuccess'),
            type: getFieldValue('type'),
            name: getFieldValue('name'),
            phone: getFieldValue('phone'),
            washSpotId: getFieldValue('washSpotId2')
        }
        if (ButtonType === 'reset') {
            param = {}
        }
        _request.getLogList(param).then(res => {
            setPushTotal(res.total || 0)
            setPushList(res.list)
        })
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getElectricMeterList()
    }
    //重置
    const clickReset = () => {
        setWashSpotName('')
        setEquipmentNo('')
        setPageNo(1)
        setPageSize(10)
        getElectricMeterList('reset')
    }

    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getElectricMeterList('',pageNo,pageSize);
    }
    const onPaginationChange2 = (pageNo, pageSize) =>{
        setPageSize2(pageSize);
        setPageNo2(pageNo);
        //查询
        getLogList('',pageNo,pageSize);
    }

    const clickInquire = () => {
        getLogList()
    }
    const clickReset2 = () => {
        setPageSize2(10);
        setPageNo2(1);
        getLogList('reset')
        props.form.resetFields()
    }

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38'}} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入洗消点" style={{width: 200, marginRight: 10}} value={washSpotName}
                       onPressEnter={clickSearch} onChange={(e) => setWashSpotName(e.target.value)}></Input>
                <Input placeholder="请输入设备号" style={{width: 200, marginRight: 10}} value={equipmentNo}
                       onPressEnter={clickSearch} onChange={(e) => setEquipmentNo(e.target.value)}></Input>
                <Button type="primary" style={{marginRight: 10}} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{marginRight: 10}} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
                <Button type="primary" style={{marginRight: 10}} onClick={openDetailModal}>参数设置</Button>
                <Button type="primary" style={{marginRight: 10}} onClick={pushModal}>推送日志</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData}/>
            </div>
            <Modal
                title={modalType === 'add' ? '新增智能花洒' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button type="primary" style={{width: 200}} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{height: getFieldValue('version') === 'V1.0'? 250 : 200}}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备版本：">
                                    {getFieldDecorator('version', {
                                        rules: [{required: true, message: '请选择设备版本!'}],
                                    })(
                                        <Select>
                                            {
                                                versionsList.map((item, index) => {
                                                    return (
                                                        <Option value={item} key={item}>{item}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备号：">
                                    {getFieldDecorator('equipmentNo', {
                                        rules: [{required: true, message: '请输入设备号！'}],
                                    })(
                                        <Input
                                            placeholder="请输入设备号"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备名称：">
                                    {getFieldDecorator('equipmentName', {
                                        rules: [{required: true, message: '请输入设备名称！'}],
                                    })(
                                        <Input placeholder="请输入设备名称"/>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="智能花洒位置：">
                                    {getFieldDecorator('equipmentPosition', {
                                        rules: [{required: true, message: '请选择花洒位置!'}],
                                    })(
                                        <Select>
                                            {
                                                electricPositionData.map((item, index) => {
                                                    return (
                                                        <Option value={item.dictValue}
                                                                key={item.dictValue}>{item.dictDesc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{required: true, message: '请选择洗消点!'}],
                                    })(
                                        <Select>
                                            {
                                                washSpotData.map((item, index) => {
                                                    return (
                                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}

                                </Form.Item>
                            </Col>

                            {
                                getFieldValue('version') === 'V1.0' ?
                                    <div>
                                        <Col span={12}>
                                            <Form.Item {...formItemLayout} label="设备imei码：">
                                                {getFieldDecorator('equipmentImei', {
                                                    rules: [{required: true, message: '请输入设备imei码!'}],
                                                })(
                                                    <Input placeholder="请输入设备imei码"/>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item {...formItemLayout} label="设备生产日期：">
                                                {getFieldDecorator('equipmentProductionDate', {
                                                    rules: [{required: true, message: '请选择设备生产日期!'}],
                                                })(
                                                    <DatePicker onChange={changeDate} style={{ width: '100%' }} format={dateFormat}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </div>:''
                            }
                        </Form>
                        : modalType === 'edit'?
                            <Form style={{height: getFieldValue('version') === 'V1.0'? 250 : 200}}>
                                <Form.Item>
                                    {getFieldDecorator('id')(
                                        <span></span>
                                    )}
                                </Form.Item>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="设备版本：">
                                        {getFieldDecorator('version', {
                                            rules: [{required: true, message: '请选择设备版本!'}],
                                        })(
                                            <Select disabled>
                                                {
                                                    versionsList.map((item, index) => {
                                                        return (
                                                            <Option value={item} key={item}>{item}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="设备号：">
                                        {getFieldDecorator('equipmentNo', {
                                            rules: [{required: true, message: '请输入设备号！'}],
                                        })(
                                            <Input
                                                disabled
                                                placeholder="请输入设备号"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="设备名称：">
                                        {getFieldDecorator('equipmentName')(
                                            <Input disabled={getFieldValue('version') !== 'V2.0'} placeholder="请输入设备名称" />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="智能花洒位置：">
                                        {getFieldDecorator('equipmentPosition', {
                                            rules: [{required: true, message: '请选择花洒位置!'}],
                                        })(
                                            <Select>
                                                {
                                                    electricPositionData.map((item, index) => {
                                                        return (
                                                            <Option value={item.dictValue}
                                                                    key={item.dictValue}>{item.dictDesc}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="洗消点：">
                                        {getFieldDecorator('washSpotId', {
                                            rules: [{required: true, message: '请选择洗消点!'}],
                                        })(
                                            <Select>
                                                {
                                                    washSpotData.map((item, index) => {
                                                        return (
                                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}

                                    </Form.Item>
                                </Col>
                                {
                                    getFieldValue('version') === 'V2.0' ?'':
                                        <div>
                                            <Col span={12}>
                                                <Form.Item {...formItemLayout} label="设备imei码：">
                                                    {getFieldDecorator('equipmentImei')(
                                                        <Input disabled placeholder="请输入设备imei码" />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item {...formItemLayout} label="设备生产日期：">
                                                    {getFieldDecorator('equipmentProductionDate')(
                                                        <DatePicker disabled onChange={changeDate} style={{ width: '100%' }} format={dateFormat}/>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </div>
                                }
                            </Form>:''
                }
            </Modal>

            <Modal
                title={'参数设置'}
                visible={detailVisible}
                onCancel={cancelDetail}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='detailClass'
                footer={
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button type="primary" style={{width: 200}} onClick={paramSubmit}>提交</Button>
                    </div>
                }
            >
                <div className="bottom">
                    {
                        detailVisible ?
                            <Form style={{height: 200}}>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="洗消点：">
                                        {getFieldDecorator('washSpotId')(
                                            <Select onSelect={getSmartShowerFarm2}>
                                                {
                                                    washSpotData.map((item, index) => {
                                                        return (
                                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="时长（分钟）：">
                                        {getFieldDecorator('duration', {
                                            rules: [{required: true, message: '请输入时长!'}],
                                        })(
                                            <InputNumber min={1} max={999}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="温度（℃）：">
                                        {getFieldDecorator('temperature', {
                                            rules: [{required: true, message: '请输入温度!'}],
                                        })(
                                            <InputNumber min={1} max={60}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Form> : ''
                    }
                </div>
            </Modal>
            <Modal
                title={'推送日志'}
                visible={pushVisible}
                onCancel={cancelDetail}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'70%'}
                className='editClass'
                footer={''}
            >
                <div>
                    <div style={{ display: 'flex' }}>
                        <Form layout='inline'>
                            <Form.Item>
                                {getFieldDecorator('washSpotId2')(
                                    <Select placeholder='请选择洗消点' style={{ width: 150 }}>
                                        {
                                            washSpotData.map((item, index) => {
                                                return (
                                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('type')(
                                    <Select placeholder='请选择人员类型' style={{ width: 150 }}>
                                        {
                                            staffList.map((item, index) => {
                                                return (
                                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('name')(
                                    <Input placeholder='请输入姓名' style={{ width: 150 }}/>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('phone')(
                                    <Input placeholder='请输入手机号' style={{ width: 150 }}/>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('isSuccess')(
                                    <Select placeholder='请选择状态' style={{ width: 150 }}>
                                        {
                                            isSuccess.map((item, index) => {
                                                return (
                                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Form>
                        <div style={{marginTop: 2.5}}>
                            <Button type='primary' onClick={clickInquire}><span className='iconfont icon-sousuo buttonIcon'></span>查询</Button>
                            <Button style={{marginLeft: 10}} className='buttonReset' onClick={clickReset2}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
                        </div>
                    </div>
                    <div style={{ overflowY: 'auto',height: '500px' }}>
                        <DeTable columns={tableDetailColunm} total={pushTotal}
                                 setOn={setPageNo2}
                                 setSize={setPageSize2}
                                 current={pageNo2}
                                 pageSize={pageSize2}
                                 onPaginationChange={(pageNo, pageSize) => onPaginationChange2(pageNo, pageSize)}
                            // onPaginationChange={(pageNo, pageSize) => getLogList('', pageNo, pageSize)}
                                 dataSource={pushList}/>
                    </div>
                </div>
            </Modal>
        </Container>

    )
}

export default Form.create()(Shower)

