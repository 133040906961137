import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Select, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import DecontaminateHttp from '../../../request/group-request'
import CommonHttp from '../../../request/common-request'


const Option = Select.Option;
const _request = new DecontaminateHttp()
const _commonrequest = new CommonHttp()

function WashGroup(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [name, setName] = useState('')
    const [regionName, setRegionName] = useState('')

    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消中心名称',
                dataIndex: 'groupName',
                key: 'groupName',
            },
            {
                title: '所属机构',
                dataIndex: 'regionName',
                key: 'regionName',
            },
            {
                title: '创建时间',
                dataIndex: 'createDate',
                key: 'createDate',
				render: (text, record, index) => {
                    return <span>{text?moment(text).format('YYYY-MM-DD HH:mm:ss'):''}</span>;
				}
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <span className='tableA' onClick={openModal.bind(this, 'edit', record)}>编辑</span>
                    </span>
                ),
            },
        ]
     //表格结构
    const [tableData, setTableData] = useState([]) //表格数据
    const [childRegionData, setChildRegionData] = useState([]) //猪场列表
    const [pigFramData, setPigFramData] = useState([]) //获取洗消点关联的猪场列表
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getWashGroupList()
        getChildRegionList()
    }, [])
    //获取列表
    const getWashGroupList = (ButtonType) => {
        let params = {
//          groupName:groupName,
//          regionId:regionId
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getWashGroupList(params).then(res => {
            setTableData(res.list)
        })
    }

    //获取下属所有机构
    const getChildRegionList = () => {
        let params = {
            regionId: sessionStorage.getItem('regionId')
        }
        _commonrequest.getChildRegionListAll(params).then(res => {
            setChildRegionData(res)
        })
    }

    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteWashGroup(params).then(res => {
            message.info('删除成功！')
            getWashGroupList()
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){
        	 //获取洗消点关联的猪场列表

//	        _request.getPigFramList(params).then(res => {
//	                setFieldsValue({
//	                    pigFram:res.map(item => Number(item)),//.map(Number)
//	                })
//	        })


            setTimeout(() => {
                setFieldsValue({
                    // regionId: Number(record.regionId),
                    regionId: record.regionId,
                    groupName: record.groupName,
                    id: record.id
                })
            }, 0)


        }

    }

    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (modalType === 'add') {
                    let params = {
                        groupName: getFieldValue('groupName'),
                        regionId: getFieldValue('regionId')
                    }
                    _request.addWashGroup(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getWashGroupList()
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })

                } else {
                    let params = {
                        groupName: getFieldValue('groupName'),
                        regionId: getFieldValue('regionId'),
                        id: getFieldValue('id')
                    }
                    _request.updateWashGroup(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getWashGroupList()
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //搜索
    const clickSearch = () => {
        getWashGroupList()
    }
    //重置
    const clickReset = () => {
		setName('')
		setRegionName('')
        getWashGroupList('reset')
    }
    return (
        <Container>
            <div className="top">
                   <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>


            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} onPaginationChange={(pageNo, pageSize) => getWashGroupList('', pageNo, pageSize)} dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增洗消中心' : '编辑洗消中心'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ height: 100 }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="洗消中心名称：">
                                {getFieldDecorator('groupName', {
                                    rules: [{ required: true, message: '请输洗消中心名称！' },{ pattern: /^[^\s]*$/, message: '禁止输入空格！' }],
                                })(
                                    <Input
                                        placeholder="请输洗消中心名称"
                                    />,
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属机构：">
								{getFieldDecorator('regionId', {
									rules: [{ required: true, message: '请选择所属机构!' }],
								})(
									<Select placeholder="请选择所属机构">
										{
							  				childRegionData.map((item,index) => {
								  				return (
								  					<Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                    </Form>
                    :
                    <Form style={{ height: 100 }}>
                        <Form.Item>
                            {getFieldDecorator('id')(
                                <span></span>
                            )}
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="洗消中心名称：">
                                {getFieldDecorator('groupName', {
                                    rules: [{ required: true, message: '请输入洗消中心名称！' }],
                                })(
                                    <Input
                                        placeholder="请输入洗消中心名称"
                                    />,
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属机构：">
								{getFieldDecorator('regionId', {
									//initialValue:pigFramData.map(Number),//.map(Number)

								})(
									<Select  placeholder="请选择所属机构">
										{
							  				childRegionData.map((item,index) => {
								  				return (
								  					<Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                    </Form>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(WashGroup)
