import React, { useEffect, useState, useRef } from 'react'
import Request from '../../request/other-request'
import HomeTitle from "../../component/HomeTitle";
import HomeAudit from "./subpage/audit";
import { Container } from './style'
import {Col, Form} from 'antd'
import Wash from "./subpage/wash";
import Alert from "./subpage/alert";
import Video from "./subpage/video";
import AIAlert from "./subpage/AIAlert";
import Share from "./subpage/share";
import Fast from "./subpage/fast";
import AICaution from "./subpage/AIcaution";

const _request = new Request()
function ErrorPage(props) {
    const { nextClick } = props;
    const alertRef = useRef(null)
    const [isThereAIRecord, setIsThereAIRecord] = useState(true)
    useEffect(()=>{
        getIsThereAIRecord()
    },[isThereAIRecord])

    const getIsThereAIRecord = () =>{
        _request.isThereAIRecord().then(res => {
            setIsThereAIRecord(res)
        })
    }
    const clickDetails = (e,id,time) => {
        let param = {
            key: e
        }
        if (id || time){
            nextClick(param,id,time)
        }else {
            nextClick(param)
        }
    }
    const selectArea = (id,risk) =>{
        alertRef.current.getVideoMonitorList(id,risk)
    }
    return (
        <Container>
            <div style={{ display: 'flex' }}>
                <div className='homeCol' style={{ marginRight: 10,width: 768,height: 390 }}>
                    <HomeTitle title={'洗消记录'} iconFont={'wash'} clickDetails={clickDetails.bind(this,'148&洗消记录')}/>
                    <Wash clickDetails={clickDetails}/>
                </div>
                <div className='homeCol' style={{ marginRight: 10,width: 546,height: 390 }}>
                    {
                        isThereAIRecord?
                            <HomeTitle title={'AI报警警示'} iconFont={'audit'} clickDetails={clickDetails.bind(this,"158&AI报警记录")}/>:
                            <HomeTitle title={'审批记录'} iconFont={'audit'} clickDetails={clickDetails.bind(this,"146&审批管理")}/>
                    }

                    {
                        !isThereAIRecord?<HomeAudit/>:
                        <AICaution clickDetails={selectArea}/>
                    }
                </div>
                <div style={{width: 360,height: 390}} className='homeCol'>
                    {
                        !isThereAIRecord?
                            <HomeTitle title={'分享沟通'} iconFont={'share'} clickDetails={clickDetails.bind(this,"152&分享沟通")} isBorder={true}/>:
                            <HomeTitle title={'审批记录'} iconFont={'audit'} clickDetails={clickDetails.bind(this,"146&审批管理")}/>
                    }
                    {/*<HomeTitle title={'沟通分享'} iconFont={'share'} clickDetails={clickDetails.bind(this,"152&分享沟通")} isBorder={true}/>*/}
                    {/*<HomeTitle title={'审批记录'} iconFont={'audit'} clickDetails={clickDetails.bind(this,"146&审批管理")}/>*/}
                    {
                        !isThereAIRecord?<Share/>:
                        <HomeAudit/>
                    }
                </div>
            </div>
            <div style={{ display: 'flex',marginTop: 10 }}>
                <div className='homeCol' style={{ marginRight: 10,width: 768,height: 348 }}>
                    <HomeTitle title={'警报事件'} iconFont={'alert'}
                               confirm1={clickDetails.bind(this,"151&警报中心")}
                               cancel1={clickDetails.bind(this,"159&AI报警统计")} isBorder={true} isAlert={true}/>
                    <Alert clickDetails={clickDetails}/>
                </div>
                <div className='homeCol' style={{ marginRight: 10,width: 546,height: 348 }}>
                    {
                        !isThereAIRecord?
                            <HomeTitle title={'视频监控'} iconFont={'video'} clickDetails={clickDetails.bind(this,"147&视频监控")} isBorder={true}/>
                            :
                            <HomeTitle title={'AI报警记录'} iconFont={'video'} clickDetails={clickDetails.bind(this,"158&AI报警记录")} isBorder={true}/>
                    }
                    {
                        isThereAIRecord?
                            <AIAlert ref={alertRef}/>
                            :
                            <Video/>
                    }
                </div>
                <div style={{width: 360,height: 348}} className='homeCol'>
                    <Fast clickDetails={clickDetails}/>
                </div>
            </div>
        </Container>
    )
}

export default Form.create()(ErrorPage)
