import React, {useEffect,useRef,useState} from 'react';
import flvJs from 'flv.js';
import {Form} from "antd";

function FlvVideo(props){

    const { url,type } = props

    const videoRef = useRef(null);

    useEffect(()=>{
        if (url){
            if (flvJs.isSupported()) {
                const flvPlayer = flvJs.createPlayer({
                    type,
                    url,
                    isLive: false,
                    // duration: 10000
                });
                flvPlayer.attachMediaElement(videoRef.current);
                flvPlayer.load();
                flvPlayer.play();
            }
        }
    },[])

    return (
        <video
            controls={true}
            id="videoElement"
            style={{
                width: '100%',
            }}
            ref={videoRef}
        />
    )
}

export default Form.create()(FlvVideo)
