import React from "react";
import {Container} from './style'
import { Form,Tabs } from "antd";
import TempCarInfo from "../tempCarInfo";
import ResidentCarInfo from "../residentCarInfo"

const { TabPane } = Tabs;
function CarAdmin(props){

    return (
        <Container>
            <Tabs defaultActiveKey="1">
                <TabPane tab="车俩管理" key="1">
                    <ResidentCarInfo></ResidentCarInfo>
                </TabPane>
                <TabPane tab="临时车俩登记" key="2" style={{ marginLeft: '20px' }}>
                    <TempCarInfo></TempCarInfo>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(CarAdmin)