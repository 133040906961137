import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'
import DeTable from '../../../component/DeTable'

import moment from 'moment'

import StoreSettingHttp from '../../../request/demo'
import { InputNumber } from 'antd';
import {Select} from "antd/lib/index";
import CommonHttp from "../../../request/common-request";

const _request = new StoreSettingHttp()
const Option = Select.Option;
const _commonrequest = new CommonHttp()

function Demo(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [goodsName, setGoodsName] = useState('')
    const [washSpotName, setWashSpotName] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const tableColunm =
        [
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '物品类型',
                dataIndex: 'goodsType',
                key: 'goodsType',
                render: (text, record, index) => (
                    goodTypeData.map((item,index1) => {
                        if(record.goodsType === item.dictValue) {
                            return  <span>{item.dictDesc}</span>
                        }
                    })

                )
            },
            {
                title: '物品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
            },
            {
                title: '所属猪场',
                key: 'pigFramName',
                dataIndex: 'pigFramName',
            },
            {
                title: '操作类型',
                key: 'type',
                dataIndex: 'type',
            },
            {
                title: '数量',
                key: 'num',
                dataIndex: 'num',
            },
            {
                title: '操作时间',
                key: 'createDate',
                dataIndex: 'createDate',
				render: (text, record, index) => {
					return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },
        ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [washSpotData, setWashSpotData] = useState([]) //洗消点列表
    const [pigFarmData, setPigFarmData] = useState([]) //猪场列表
    const [goodTypeData, setGoodTypeData] = useState([]) //物资类型
    const type = [
        {value:"入库",text:"入库"},
        {value:"出库",text:"出库"}
    ] //操作类型

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getAllOutAndWarehousing()
        getWashSpotList()
        getPigFarmList()
        getGoodTypeList()
    }, [])
    //获取物资列表
    const getAllOutAndWarehousing = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            goodsName,
            washSpotName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.findMaterialOutAndWarehousing(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteType(params).then(res => {
            message.info('删除成功！')
            getAllOutAndWarehousing()
        })
    }
    //获取当前登录用户下的洗消点列表
    const getWashSpotList = () => {
        let params = {
        }
        _commonrequest.getWashSpotList(params).then(res => {
            let list = []
            list = res.map(item => {
                return {
                    ...item,
                    key: item.key
                }
            })
            setWashSpotData(list)
        })
    }
    //获取当前登录用户下的猪场列表
    const getPigFarmList = () => {
        let params = {
        }
        _commonrequest.getChildRegionList(params).then(res => {
            let list = []
            list = res.map(item => {
                return {
                    ...item,
                    key: item.key
                }
            })
            setPigFarmData(list)
        })
    }
    //获取物品类型列表
    const getGoodTypeList = () => {
        let params = {
            dictType:'GOOD_TYPE'
        }
        _commonrequest.getDictList(params).then(res => {
            setGoodTypeData(res.list)
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){
            setTimeout(() => {
                setFieldsValue({
                    typePropE: record.nature,
                    typenameE: record.name,
                    id: record.id
                })
            }, 0)
        }
    }
    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getAllOutAndWarehousing()
    }
    //重置
    const clickReset = () => {
		setGoodsName('')
		setWashSpotName('')
        setPageNo(1)
        setPageSize(10)
        getAllOutAndWarehousing('reset')
    }
    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getAllOutAndWarehousing('',pageNo,pageSize);
    }
    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (modalType === 'add') {
                    let params = {
                        washSpotId: getFieldValue('washSpotId'),
                        goodsType: getFieldValue('goodsType'),
                        goodsName: getFieldValue('goodsName'),
                        pigFramId: getFieldValue('pigFramId'),
                        type: getFieldValue('type'),
                        num: getFieldValue('num'),
                        remark: getFieldValue('remark')
                    }
                    _request.addMaterialOutAndWarehousing(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getAllOutAndWarehousing()
                    }).catch(err => {
                        setConfirmLoading(false)
                    })
                } else {
                    let params = {
                        nature: getFieldValue('typePropE'),
                        name: getFieldValue('typenameE'),
                        id: getFieldValue('id')
                    }
                    _request.editType(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getAllOutAndWarehousing()
                    })
                }
            }
        })
    }


    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入洗消点" style={{ width: 200, marginRight: 10 }} value={washSpotName} onPressEnter={clickSearch} onChange={(e) => setWashSpotName(e.target.value)}></Input>
                <Input placeholder="请输入物品名称" style={{ width: 200, marginRight: 10 }} value={goodsName} onPressEnter={clickSearch} onChange={(e) => setGoodsName(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>查找</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增物资' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 400 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{ height: 260 }}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择洗消点!' }],
                                    })(
                                        <Select >
                                            {
                                                washSpotData.map((item,index) => {
                                                    return (
                                                        <Option value={item.id} key={item.id} >{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="物品类型：">
                                    {getFieldDecorator('goodsType', {
                                        rules: [{ required: true, message: '请选择物品类型!' }],
                                    })(
                                        <Select >
                                            {
                                                goodTypeData.map((item,index) => {
                                                    return (
                                                        <Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="物品名称：">
                                    {getFieldDecorator('goodsName', {
                                        rules: [{ required: true, message: '请输入物品名称！' }],
                                    })(
                                        <Input
                                            placeholder="请输入物品名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属猪场：">
                                    {getFieldDecorator('pigFramId', {
                                        rules: [{ required: true, message: '请选择猪场!' }],
                                    })(
                                        <Select >
                                            {
                                                pigFarmData.map((item,index) => {
                                                    return (
                                                        <Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="操作类型：">
                                    {getFieldDecorator('type', {
                                        rules: [{ required: true, message: '请输入操作类型!' }],
                                    })(
                                        <Select >
                                            {
                                                type.map((item,index) => {
                                                    return (
                                                        <Option value={item.value} key={item.value} >{item.text}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="数量：">
                                    {getFieldDecorator('num', {
                                        rules: [{ required: true, message: '请输入数量！' }],
                                    })(
                                        <InputNumber style={{ width: '100%' }}  min={1} max={100000} defaultValue={3}  />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="描述：">
                                    {getFieldDecorator('remark', {
                                        rules: [{ required: true, message: '请输入描述！' }],
                                    })(
                                        <Input
                                            placeholder="请输入描述"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>

                        </Form>
                        :
                        <Form style={{ height: 260 }}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择洗消点!' }],
                                    })(
                                        <Select >
                                            {
                                                washSpotData.map((item,index) => {
                                                    return (
                                                        <Option value={item.id} key={item.id} >{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="物品类型：">
                                    {getFieldDecorator('goodsType', {
                                        rules: [{ required: true, message: '请输入物品名称！' }],
                                    })(
                                        <Input
                                            placeholder="请输入物品名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="物品名称：">
                                    {getFieldDecorator('goodsName', {
                                        rules: [{ required: true, message: '请输入物品名称！' }],
                                    })(
                                        <Input
                                            placeholder="请输入物品名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属猪场：">
                                    {getFieldDecorator('pigFramId', {
                                        rules: [{ required: true, message: '请选择猪场!' }],
                                    })(
                                        <Select >
                                            {
                                                pigFarmData.map((item,index) => {
                                                    return (
                                                        <Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="操作类型：">
                                    {getFieldDecorator('type', {
                                        rules: [{ required: true, message: '请输入操作类型！' }],
                                    })(
                                        <Input
                                            placeholder="请输入操作类型"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="数量：">
                                    {getFieldDecorator('num', {
                                        rules: [{ required: true, message: '请输入数量！' }],
                                    })(
                                        <InputNumber style={{ width: '100%' }}  min={1} max={100000} defaultValue={3}  />
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(Demo)
