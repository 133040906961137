import moment from "moment";
import React from "react";
import {Popover} from 'antd'

const VxTimeFormat = (time,home) => {
    // moment(time).format("DD") === moment(new Date()).format("DD") ?
    //     (moment(time).format("HH") <= '12' ?
    //             <span>上午 {moment(time).format("HH:mm")}</span> :
    //             <span>下午 {moment(time).format("HH:mm")}</span>
    //     )
    //     :
    //     (moment(new Date()).format("DD")) - 1 == moment(time).format("DD") ?
    //         <span>昨天 {moment(time).format("HH:mm")}</span> :
    //         moment(new Date()).format("YY") == moment(time).format("YY") ?
    //             <span>{moment(time).format("MM/DD HH:mm")}</span>
    //             :
    //             <span>{moment(time).format("YY/MM/DD HH:mm")}</span>
    let data = ''
    if (moment(time).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")){
        if (home === 'home'){
            data = '今日'
        }else {
            if (moment(time).format("HH") <= '12'){
                data = '上午 ' + moment(time).format("HH:mm")
            }else{
                data = '下午 ' + moment(time).format("HH:mm")
            }
        }
    }else if (moment(new Date()).format("YY") == moment(time).format("YY") && (moment(new Date()).format("DD")) - 1 == moment(time).format("DD")){
        if (home === 'home'){
            data = '昨日'
        }else {
            data = '昨天 ' + moment(time).format("HH:mm")
        }
    }else if (moment(new Date()).format("YY") == moment(time).format("YY")){
        data = moment(time).format("MM/DD")
    }else{
        data = moment(time).format("YY/MM/DD")
    }
    return data
}

const stringFormat = (text,num = 8) => {
    if (text){
        let screenWidth = window.screen.width;
        if (num === 8){
            if (screenWidth <= 1280){
                num = 4
            } else  if (screenWidth <= 1440){
                num = 5
            } else if (screenWidth <= 1680){
                num = 6
            }
        }
        if (num === 20){
            if (screenWidth <= 1280){
                num = 10
            } else  if (screenWidth <= 1440){
                num = 12
            } else if (screenWidth <= 1680){
                num = 16
            } else if (screenWidth <= 1920){
                num = 18
            }
        }
        if (num === 10){
            if (screenWidth <= 1280){
                num = 16
            } else  if (screenWidth <= 1440){
                num = 18
            } else if (screenWidth <= 1680){
                num = 22
            } else if (screenWidth <= 1920){
                num = 24
            }
        }
        if (text.length > num){
            return (
                <Popover content={text}>
                    <span style={{ color: 'rgba(0,0,0)',fontWeight: 500 }}>{text.substring(0,num)}...</span>
                </Popover>
            )
        }else{
            return (
                <span>{text}</span>
            )
        }
    }
}


const getPeriodTime = (start, end) =>{
    if (typeof start.createDate === 'string'){
        start.createDate = moment(start.createDate).unix() * 1000
    }
    if (typeof end.createDate === 'string'){
        end.createDate = moment(end.createDate).unix() * 1000
    }
    let time = Math.floor(end.createDate / (60 * 1000)) - Math.floor(start.createDate / (60 * 1000));
    if(start.workflowName === end.workflowName&&!time){
        return false
    }
    let hour = Math.floor(time / 60);
    let minute = time % 60;
    return (hour > 0 ? hour+'小时' : '') + minute+'分钟';
}

export {VxTimeFormat,stringFormat,getPeriodTime}
