import React, {useEffect, useState} from "react";
import DeTable from "../../../../component/DeTable";
import moment from "moment";
import StoreSettingHttp from '../../../../request/peopleWash'
import DecontaminationHttp from '../../../../request/car-request'
import StoreSetting from '../../../../request/demo'
import {Modal, Tabs, Popover, Form} from 'antd'
import {Container} from './style'
import {VxTimeFormat, stringFormat} from '../../../../utils/homeFormat'
import CommonHttp from "../../../../request/common-request";

const _StoreSettingHttp = new StoreSettingHttp();
const _DecontaminationHttp = new DecontaminationHttp();
const _request = new StoreSetting();
const _commonrequest = new CommonHttp()

const {TabPane} = Tabs

const marginTop10 = {marginTop: 10}
const colorBlack = {color: 'rgba(0, 0, 0, 0.9)'}

function Wash(props) {
    const { clickDetails } = props
    //表格结构
    const [vehicleData, setVehicleData] = useState([]) //表格数据
    const [personData, setPersonData] = useState([]) //表格数据
    const [goodsData, setGoodsData] = useState([]) //表格数据
    const [carTypeData, setCarTypeData] = useState([]) //车辆类型

    //车辆
    const vehicleColUnm = [
        {
            title: '日期',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => (
                <span>{VxTimeFormat(text,'home')}</span>
            )
        },
        // {
        //     title: '车牌号',
        //     key: 'carNo',
        //     dataIndex: 'carNo',
        // },
        {
            title: '洗消点名称',
            key: 'washSpotName',
            dataIndex: 'washSpotName',
            render: (text, record, index) => (
                stringFormat(text,10)
            )
        },
        {
            title: '车辆数',
            key: 'notNullCount',
            dataIndex: 'notNullCount',
        },
        {
            title: '记录数',
            key: 'allCount',
            dataIndex: 'allCount',
        },
        // {
        //     title: '设备名称',
        //     key: 'videoName',
        //     dataIndex: 'videoName',
        //     render: (text, record, index) => (
        //         stringFormat(text, 4)
        //     )
        // },
        // {
        //     title: '流程',
        //     key: 'workflowName',
        //     dataIndex: 'workflowName',
        // },
    ]
    //人员
    const personColUnm = [
        {
            title: '日期',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => (
                <span>{VxTimeFormat(text,'home')}</span>
            )
        },
        // {
        //     title: '姓名',
        //     key: 'name',
        //     dataIndex: 'name',
        // },
        {
            title: '洗消点名称',
            key: 'washSpotName',
            dataIndex: 'washSpotName',
            render: (text, record, index) => (
                stringFormat(text,10)
            )
        },
        {
            title: '人数',
            key: 'notNullCount',
            dataIndex: 'notNullCount',
        },
        {
            title: '记录数',
            key: 'allCount',
            dataIndex: 'allCount',
        },
        // {
        //     title: '设备名称',
        //     key: 'videoName',
        //     dataIndex: 'videoName',
        //     render: (text,record,index) => (
        //         stringFormat(text,4)
        //     )
        // },
        // {
        //     title: '流程',
        //     key: 'workflowName',
        //     dataIndex: 'workflowName',
        // },
    ]
    //物资
    const goodsColUnm = [
        {
            title: '日期',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => (
                <span>{VxTimeFormat(text,'home')}</span>
            )
        },
        // {
        //     title: '物品名称',
        //     key: 'goodsName',
        //     dataIndex: 'goodsName',
        // },
        {
            title: '洗消点名称',
            key: 'washSpotName',
            dataIndex: 'washSpotName',
            render: (text, record, index) => (
                stringFormat(text,20)
            )
        },
        {
            title: '批次数',
            key: 'notNullCount',
            dataIndex: 'notNullCount',
        },
        {
            title: '合格',
            key: 'qualifiedCount',
            dataIndex: 'qualifiedCount',
            render: (imgPath, record) => (
                <div>
                    <span>{record.qualifiedCount?record.qualifiedCount:0}</span>
                </div>
            )
        },
        {
            title: '不合格',
            key: 'unqualifiedCount',
            dataIndex: 'unqualifiedCount',
            render: (imgPath, record) => (
                <div>
                    <span style={record.unqualifiedCount?{color: 'red'}:{}}>{record.unqualifiedCount?record.unqualifiedCount:0}</span>
                </div>
            )
        },
        {
            title: '消毒中',
            key: 'ongoingCount',
            dataIndex: 'ongoingCount',
            render: (imgPath, record) => (
                <div>
                    <span>{record.ongoingCount?record.ongoingCount:0}</span>
                </div>
            )
        },
    ]

    useEffect(() => {
        getPersonList()
        getVehicleList()
        getGoodsList()
        getDictList()
    }, [])

    //获取人员列表数据
    const getPersonList = () => {
        let params = {
            // pageNo: 1,
            // pageSize: 7
        }
        _StoreSettingHttp.getHomeWashList(params).then(res => {
            setPersonData(res)
        })
    }
    //获取车辆列表
    const getVehicleList = () => {
        let params = {
            // pageNo: 1,
            // pageSize: 7,
            // type: 'web'
        }
        _DecontaminationHttp.getWashCarList(params).then(res => {
            setVehicleData(res)
        })
    }
    //获取物资列表
    const getGoodsList = () => {
        let params = {
            // pageNo: 1,
            // pageSize: 7
        }
        _request.getWashGoodsList(params).then(res => {
            setGoodsData(res)
        })
    }

    const personClick = (e) => {
        clickDetails('148&洗消记录','1&'+e.washSpotId,e.createDate)

    }
    const vehicleClick = (e) => {
        clickDetails('148&洗消记录','2&'+e.washSpotId,e.createDate)
    }
    const goodsClick = (e) => {
        clickDetails('148&洗消记录','3&'+e.washSpotId,e.createDate)
    }
    //获取车辆类型列表
    const getDictList = () => {
        let params = {
            dictType: 'CAR_TYPE'
        }
        _commonrequest.getDictList(params).then(res => {
            setCarTypeData(res.list)
        })
    }
    return (
        <Container>
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab="人员" key="1">
                    <DeTable columns={personColUnm} clickRow={personClick} dataSource={personData} isPagination={true}/>
                </TabPane>
                <TabPane tab="车辆" key="2">
                    <DeTable columns={vehicleColUnm} clickRow={vehicleClick} dataSource={vehicleData}
                             isPagination={true}/>
                </TabPane>
                <TabPane tab="物资" key="3">
                    <DeTable columns={goodsColUnm} clickRow={goodsClick} dataSource={goodsData} isPagination={true}/>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(Wash)
