import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Select, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import TempCarInfoHttp from '../../../request/car-request'
import CommonHttp from '../../../request/common-request'
import {InfoCircleOutlined} from "@ant-design/icons";


const Option = Select.Option;
const _request = new TempCarInfoHttp()
const _commonrequest = new CommonHttp()
const { TextArea } = Input;
const pigFarmDiv = {
    padding: '9px 15px 10px 15px',
    marginRight: 10,
    background: '#F2F7FC',
    borderRadius: 5,
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: 13
}
const CutOff = {borderTop: '1px solid #E5E6EA', width: 500, marginLeft: -24, marginTop: 24}
const marginTop10 = {marginTop: 10}
const marginTop20 = {marginTop: 22}
const titleP = {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.85)'
}

function TempCarInfo(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [carNo, setCarNo] = useState('')
    const [driver, setDriver] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [washSpotList, setWashSpotList] = useState([])
    const [washSpot, setWashSpot] = useState([])
    const [vehicleAuditData, setVehicleAuditData] = useState({})
    const [pigFarmId, setPigFarmId] = useState([])

    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '车牌号',
                dataIndex: 'carNo',
                key: 'carNo',
            },
            {
                title: '车辆类型',
                key: 'carType',
                dataIndex: 'carType',
                render: (text, record, index) => (
                	carTypeData.map((item,index1) => {
						if(record.carType === item.dictValue) {
							return  <span>{item.dictDesc}</span>
						}
					})

                )
            },
            {
                title: '驾驶人',
                dataIndex: 'driver',
                key: 'driver',
            },
//          {
//              title: '所属猪场',
//              key: 'pigFramName',
//              dataIndex: 'pigFramName',
//          },
            {
                title: '手机号',
                key: 'phone',
                dataIndex: 'phone',
            },
            {
                title: '登记人',
                key: 'registrant',
                dataIndex: 'registrant',
            },
            {
                title: '操作时间',
                dataIndex: 'createDate',
                key: 'createDate',
				render: (text, record, index) => {
					return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <span style={{ marginRight: 8 }} className='tableA' onClick={openModal.bind(this, 'edit', record)}>详情</span>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                            <span className='tableADelete'>删除</span>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [carTypeData, setCarTypeData] = useState([]) //车辆类型列表
    const [childRegionData, setChildRegionData] = useState([]) //猪场列表
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getTempCarInfoList()
        getDictList()
        getChildRegionList()
    }, [])
    //获取列表
    const getTempCarInfoList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            carNo:carNo,
            driver:driver,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getTempCarInfoList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

     //获取车辆类型列表
    const getDictList = () => {
        let params = {
           dictType:'CAR_TYPE'
        }
        _commonrequest.getDictList(params).then(res => {
            setCarTypeData(res.list)
        })
    }
    //获取猪场列表
    const getChildRegionList = () => {
        let params = {}
        _commonrequest.getChildRegionList(params).then(res => {
            setChildRegionData(res)
        })
    }
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteTempCarInfo(params).then(res => {
            message.info('删除成功！')
            getTempCarInfoList()
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){
        	//获取关联的猪场列表
            let params = {
            	tempCarId:record.id
            }
	        _request.getTempCarFramList(params).then(res => {
	                // setFieldsValue({
	                //     pigFramId:res.map(item => Number(item)),//.map(Number)
	                // })
                let arr = res.map(item => item);
                setPigFarmId(arr)
                setFieldsValue({
                    // pigFramId:res.map(item => Number(item)),//.map(Number)
                    pigFramId:arr,//.map(Number)
                })
                selectPigFram(arr);
	        })
            _request.getTempCarWashSpotList(params).then(res => {
                setFieldsValue({
                    washSpotIds:res.map(item => item),//.map(Number)
                })
                setWashSpot(res)
            })

            setTimeout(() => {
                setVehicleAuditData({
                    carNo: record.carNo,
                    carType: record.carType,
                    driver: record.driver,
                    phone: record.phone,
                    remark:record.remark,
                    id: record.id
                })
            }, 0)
        }
    }
    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
               //let regionName = childRegionData.filter(item => item.regionId === getFieldValue('pigFramId'))[0].regionName
               //let newData = getFieldValue('pigFramId').map(item => item.toString()).join('-')
               if (modalType === 'add') {
                    let params = {
                        carNo: getFieldValue('carNo'),
                        carType: getFieldValue('carType'),
                        driver: getFieldValue('driver'),
                        phone: getFieldValue('phone'),
                        pigFramIds: JSON.stringify(getFieldValue('pigFramId')),
                        washSpotIds: JSON.stringify(getFieldValue('washSpotIds')),
                        //pigFramId: newData,
                        //pigFramName: regionName,
                        remark: getFieldValue('remark'),
                        source: 'web'
                    }
                    _request.addTempCarInfo(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getTempCarInfoList()
                    })
                } else {
                    let params = {
                        carNo: getFieldValue('carNo'),
                        carType: getFieldValue('carType'),
                        driver: getFieldValue('driver'),
                        phone: getFieldValue('phone'),
                        pigFramIds: JSON.stringify(getFieldValue('pigFramId')),
                        washSpotIds: JSON.stringify(getFieldValue('washSpotIds')),
                        //pigFramId: newData,
                        //pigFramName: regionName,
                        remark: getFieldValue('remark'),
                        id: getFieldValue('id')
                    }
                    _request.updateTempCarInfo(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getTempCarInfoList()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getTempCarInfoList()
    }
    //重置
    const clickReset = () => {
		setDriver('')
		setCarNo('')
        setPageNo(1)
        setPageSize(10)
        getTempCarInfoList('reset')
    }

    const selectPigFram = (e) =>{
        const { setFieldsValue } = props.form
        if (e.length > 0){
            const regionIds = e.join(',');
            _request.getWashSetListByFarmId({ regionIds }).then(res =>{
                setWashSpotList(res)
            });
        }else {
            setWashSpotList([])
            setFieldsValue({
                washSpotIds: []
            })
        }
    }

    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getTempCarInfoList('',pageNo,pageSize);
    }
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入车牌号" style={{ width: 200, marginRight: 10 }} value={carNo} onPressEnter={clickSearch} onChange={(e) => setCarNo(e.target.value)}></Input>
                <Input placeholder="请输入驾驶员" style={{ width: 200, marginRight: 10 }} value={driver} onPressEnter={clickSearch} onChange={(e) => setDriver(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增车辆' : '详情'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={modalType === 'add' ? '50%':'500px'}
                className='editClass'
                footer={
                    modalType === 'add' ?<div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>:''
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ display: 'inline-block' }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="车牌号：">
                                {getFieldDecorator('carNo', {
                                    rules: [{ required: true, message: '请输入车牌号！',pattern:new RegExp(/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/, "g") }],
                                })(
                                    <Input
                                        placeholder="请输入车牌号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                         <Col span={12}>
							<Form.Item {...formItemLayout} label="车辆类型：">
								{getFieldDecorator('carType', {
									rules: [{ required: true, message: '请选择车辆类型!' }],
								})(
									<Select >
										{
							  				carTypeData.map((item,index) => {
								  				return (
								  					<Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>

                         <Col span={12}>
                            <Form.Item {...formItemLayout} label="驾驶人：">
                                {getFieldDecorator('driver', {
                                    rules: [{ required: true, message: '请输入驾驶人！' }],
                                })(
                                    <Input
                                        placeholder="请输入驾驶人"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="手机号：">
                                {getFieldDecorator('phone', {
                                    rules: [{ required: true, message: '请输入手机号！',pattern:new RegExp(/^1[3456789]\d{9}$/, "g") }],
                                })(
                                    <Input
                                        placeholder="请输入手机号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属猪场：">
								{getFieldDecorator('pigFramId', {
									rules: [{ required: true, message: '请选择所属猪场!' }],
								})(
									<Select  mode="multiple" onChange={selectPigFram}>
										{
							  				childRegionData.map((item,index) => {
								  				return (
                                                    <Option value={item.pigFramId} key={item.pigFramId}>{item.pigFramName}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="所属洗消点：">
                                {getFieldDecorator('washSpotIds', {
                                    rules: [{ required: true, message: '请选择所属洗消点!' }],
                                })(
                                    <Select mode="multiple">
                                        {
                                            washSpotList.map((item,index) => {
                                                return (
                                                    <Option value={item.id} key={item.id} >{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )}

                            </Form.Item>
                        </Col>
                         <Col span={12}>
                            <Form.Item {...formItemLayout} label="事项说明：">
                                {getFieldDecorator('remark')(
                                    <Input
                                        placeholder="请输入事项说明"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Form>
                    :
                    <div>
                        <div>
                            <div>车 &nbsp;牌 &nbsp;号：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{vehicleAuditData.carNo}</span></div>
                            <div style={marginTop10}>车辆类型：<span style={{color: 'rgba(0, 0, 0, 0.85)'}}>{
                                carTypeData.map((item, index1) => {
                                    if (vehicleAuditData.carType === item.dictValue) {
                                        return item.dictDesc
                                    }
                                })}</span></div>
                            <div style={marginTop10}>驾 &nbsp;驶 &nbsp;人：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{vehicleAuditData.driver}</span></div>
                            <div style={marginTop10}>手 &nbsp;机 &nbsp;号：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{vehicleAuditData.phone}</span></div>
                        </div>
                        <div style={CutOff}></div>
                        <div style={marginTop20}>
                            <div style={titleP}>访问猪场</div>
                            <div style={{display: 'flex', marginTop: 10}}>
                                {
                                    childRegionData.map((item,index) => {
                                        if (pigFarmId[index] === item.pigFramId) {
                                            return (
                                                <div style={pigFarmDiv}>{item.pigFramName}</div>
                                            )
                                        }

                                    })
                                }
                            </div>
                        </div>
                        <div style={CutOff}></div>
                        <div style={marginTop20}>
                            <div style={titleP}>访问洗消点</div>
                            <div style={{display: 'flex', marginTop: 10}}>
                                {
                                    washSpot.map(id => {
                                        const matchingObj = washSpotList.find(obj => obj.id === id);
                                        return matchingObj ? <div style={pigFarmDiv}>{matchingObj.name}</div> : '';
                                    })
                                }

                            </div>
                        </div>
                        <div style={CutOff}></div>
                        <div style={marginTop20}>
                            <div style={titleP}>事项说明</div>
                            <TextArea value={vehicleAuditData.remark} disabled style={{marginTop: 10}}/>
                        </div>
                    </div>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(TempCarInfo)
