import React, {useState, useEffect} from 'react'
import {Container} from './style'
import { connect } from 'react-redux'

import {
    Button,
    Input,
    Modal,
    Table,
    TreeSelect,
    Popconfirm,
    Col,
    Form,
    message,
    Icon,
    Upload,
    Select,
    DatePicker, InputNumber
} from 'antd'

import StoreSettingHttp from '../../../request/visitor';
import CommonHttp from '../../../request/common-request'
import StoreSettingHttp2 from '../../../request/staff'

import DeTable from '../../../component/DeTable';

import moment from 'moment';

const _request = new StoreSettingHttp();
const _request2 = new StoreSettingHttp2();
const _commonrequest = new CommonHttp()

const headers = {
    'x-auth-token': sessionStorage.getItem('token')
}

function beforeUpload(file) {
    if (file.type.indexOf("image") == -1) return message.error('上传文件类型为图片!');
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
        message.error('图片太大了，要小于3000kb!');
    }
    return isLt2M;
}

function Visitor(props) {
    const {baseURL} = props
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [checkImg, setCheckImg] = useState(false) //编辑模态框加载图标
    const [imageUrl, setImageUrl] = useState('')
    const [imageId, setImageId] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [state, setState] = useState('')
    const [pigFarmList, setPigFarmList] = useState([])
    const [expandTreeList, setExpandTreeList] = useState([])
    const [date, setDate] = useState('')
    const [videoName, setVideoName] = useState('')
    const tableColunm =
        [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
//          {
//              title: '所属猪场',
//              key: 'pigFramName',
//              dataIndex: 'pigFramName',
//          },
        {
            title: '授权访问时间',
            key: 'authVisit',
            dataIndex: 'authVisit',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>
            )
        },
        {
            title: '人脸图片',
            key: 'imgPath',
            dataIndex: 'imgPath',
            render: (text, record, index) => (
                record.imgPath?
                    <img
                        onClick={openModal.bind(this, 'check', record)}
                        style={{ width: '40px',height: '40px',cursor: 'pointer' }}
                        src={record.imgPath}/>:''
            )
        },
        {
            title: '操作时间',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            )
        },
        {
            title: '可访问设备',
            key: 'videoNames',
            dataIndex: 'videoNames',
            render: (text, record, index) => (
                <div style={{
                    maxWidth: '220px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',}}
                >{record.videoNames}</div>
            )
        },
        {
            title: '岗位',
            key: 'post',
            dataIndex: 'post',
            render: (text, record, index) => (
                userPostData.map((item, index1) => {
                    if (record.post === item.dictValue) {
                        return <span>{item.dictDesc}</span>
                    }
                })

            )
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
                    {/*<span className='tableA' style={{marginRight: 8}} onClick={openModal.bind(this, 'edit', record)}>编辑</span>*/}
                    <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                        <span className='tableADelete'>删除</span>
                    </Popconfirm>
                </span>
            ),
        },
    ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [userPostData, setUserPostData] = useState([]) //用户岗位列表
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        },
    }
    const {getFieldDecorator} = props.form
    useEffect(() => {
        headers['x-auth-token'] =  sessionStorage.getItem('token')
        getVisitorList()
        getDictList()
        getPigfarm()
    }, [])
    //获取员工列表
    const getVisitorList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            name: name,
            phone: phone,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getVisitorList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

    //获取用户岗位列表
    const getDictList = () => {
        let params = {
            dictType: 'USER_POST'
        }
        _commonrequest.getDictList(params).then(res => {
            setUserPostData(res.list)
        })
    }
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteVisitor(params).then(res => {
            message.info('删除成功！')
            getVisitorList()
        })
    }
    const getPigfarm = () => {
        let params = {
            regionType: 5,
            enterpriseType: 2
        }
        _commonrequest.getFramBuilddingList(params).then(res => {
            let list = []
            res.forEach(item => {
                let childList = []
                item.buildList.forEach(child => {
                    childList.push({
                        title: child.buildingName,
                        value: child.buildingId,
                        isLeaf: true
                    })
                })
                list.push({
                    title: item.pigFramName,
                    value: item.pigFramId,
                    children: childList,
                    // selectable: false,
                    // disableCheckbox: true
                })
            })
            setPigFarmList(list)
        })
    }

    //打开编辑页
    const openModal = (type, record) => {
        const {setFieldsValue} = props.form
        setModalType(type)
        setEditVisible(true)
        setVideoName('')
        if (type === 'edit') {
            setTimeout(() => {
                setFieldsValue({
                    name: record.name,
                    post: record.post,
                    // credNo: record.credNo,
                    phone: record.phone,
                    height: record.height,
                    authVisit: moment(moment(record.authVisit).format("YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm"),
                    id: record.id
                })
                setImageUrl(record.imgPath)
                setImageId(record.imgPathId)
                setVideoName(record.videoNames)
            }, 0)
            setImageUrl(record.imgPath)
            _commonrequest.getTempPreBuildding({
                tempPeopleId: record.id
            }).then(res => {
                let list = []
                let id
                res.forEach(item => {
                    if (!item.split) return
                    id = item.split('-')[0]
                    if (!list.includes(id)) {
                        list.push(id)
                    }
                })
                let listValue = []
                res.forEach(item => {
                    listValue.push({
                        value: item.value,
                        label: item.label
                    })
                })
                setExpandTreeList(list)
                setFieldsValue({
                    pigFramId: listValue
                })
            })
        }else if (type === 'check') {
            setCheckImg(true)
            setImageUrl(record.imgPath)
        }else {
            setImageUrl('')
        }
    }

    const {getFieldValue, validateFields, setFieldValue} = props.form

    //编辑页提交
    const editConfire = () => {
        //return console.log(props.form.getFieldsValue())
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                let idList = getFieldValue('pigFramId').map(item => {
                    return item.value + "&" + item.label
                })
                if (modalType === 'add') {
                    let params = {
                        name: getFieldValue('name'),
                        pigFramIds: JSON.stringify(idList),
                        post: getFieldValue('post'),
                        imgPath: imageUrl,
                        imgPathId: imageId,
                        // credNo: getFieldValue('credNo'),
                        phone: getFieldValue('phone'),
                        height: getFieldValue('height'),
                        authVisit: moment(getFieldValue('authVisit')).format("YYYY-MM-DD HH:mm") + ':00',
                        source: 'web'
                    }
                    _request.addVisitor(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setImageUrl('')
                        setEditVisible(false)
                        getVisitorList()
                    })
                } else {
                    let params = {
                        name: getFieldValue('name'),
                        pigFramIds: JSON.stringify(idList),
                        post: getFieldValue('post'),
                        imgPath: imageUrl,
                        imgPathId: imageId,
                        // credNo: getFieldValue('credNo'),
                        phone: getFieldValue('phone'),
                        height: getFieldValue('height'),
                        id: getFieldValue('id'),
                        authVisit: moment(getFieldValue('authVisit')).format("YYYY-MM-DD HH:mm") + ':00',
                    }
                    _request.editVisitor(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        setImageUrl('')
                        getVisitorList()
                    })
                }
            }
            setConfirmLoading(false)
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setVideoName('')
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
        setCheckImg(false)
        setImageUrl('')
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getVisitorList()
    }
    //获取可访问设备
    const selectVideoSn = (e) =>{
        if (e.length === 0) return setVideoName('')
        let idList = e.map(item => {
            return item.value+"&"+item.label
        })
        let param = {
            pigFramIds: JSON.stringify(idList)
        }
        _request2.selectVideoSn(param).then(res =>{
            setVideoName(res)
        })
    }
    //重置
    const clickReset = () => {
        setName('')
        setPhone('')
        setPageNo(1)
        setPageSize(10)
        getVisitorList('reset')
    }
    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getVisitorList('',pageNo,pageSize);
    }

    const handleChange = (info, e, data) => {
        if (info.file.response){
            if (info.file.status === 'done' && info.file.response && info.file.response.code == 200) {
                setImageUrl(info.file.response.result.url);
                setImageId(info.file.response.result.id);
            }else {
                message.error(info.file.response.message)
            }
        }
        // else {
        //     message.error('上传失败')
        // }
        // 将图片URL保存至from
        // let formData = new FormData()
        // formData.append("imgPath","");
    }


    const {Option} = Select;

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38'}} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增访客</Button>
                <Input placeholder="请输入姓名" style={{width: 200, marginRight: 10}} value={name} onPressEnter={clickSearch}
                       onChange={(e) => setName(e.target.value)}></Input>
                <Input placeholder="请输入手机号" style={{width: 200, marginRight: 10}} value={phone}
                       onPressEnter={clickSearch} onChange={(e) => setPhone(e.target.value)}></Input>
                <Button type="primary" style={{marginRight: 10}} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{marginRight: 10}} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData}/>
            </div>
            <Modal
                title={modalType === 'add' ? '新增访客' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button type="primary" style={{width: 200}} loading={confirmLoading}
                                onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{display: 'inline-block'}}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="访客姓名：">
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: '请输入访客姓名！'}],
                                    })(
                                        <Input
                                            placeholder="请输入访客姓名"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            {/*<Col span={12}>*/}
                            {/*    <Form.Item {...formItemLayout} label="证件号：">*/}
                            {/*        {getFieldDecorator('credNo', {*/}
                            {/*            rules: [{*/}
                            {/*                required: true,*/}
                            {/*                message: '请输入证件号！',*/}
                            {/*                pattern: new RegExp(/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, "g")*/}
                            {/*            }],*/}
                            {/*        })(*/}
                            {/*            <Input*/}
                            {/*                placeholder="请输入证件号"*/}
                            {/*            />,*/}
                            {/*        )}*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="手机号：">
                                    {getFieldDecorator('phone', {
                                        rules: [{
                                            required: true,
                                            message: '请输入手机号！',
                                            pattern: new RegExp(/^1[3456789]\d{9}$/, "g")
                                        }],
                                    })(
                                        <Input
                                            placeholder="请输入手机号"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="岗位：">
                                    {getFieldDecorator('post', {
                                        rules: [{required: true, message: '请输入岗位！'}],
                                    })(
                                        <Select>
                                            {
                                                userPostData.map((item, index) => {
                                                    return (
                                                        <Option value={item.dictValue}
                                                                key={item.dictValue}>{item.dictDesc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属猪场：">
                                    {getFieldDecorator('pigFramId', {
                                        rules: [{required: true, message: '请选择！'}],
                                    })(
                                        <TreeSelect
                                            style={{width: '100%'}}
                                            treeData={pigFarmList}
                                            dropdownStyle={{height: 300, overflow: 'auto'}}
                                            placeholder="请选择"
                                            treeCheckStrictly
                                            allowClear
                                            treeCheckable
                                            multiple
                                            onChange={selectVideoSn}
                                        >
                                        </TreeSelect>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="授权访问时间：">
                                    {getFieldDecorator('authVisit', {
                                        rules: [{required: true, message: '请选择授权访问时间！'}],
                                    })(
                                        <DatePicker
                                            showTime={{
                                                format: 'HH:mm',
                                            }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{width: '100%'}}></DatePicker>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="可访问设备：">
                                    <div style={videoName?{color: 'black',fontWeight: '600'}:{}}>
                                        { videoName?videoName:'暂无' }
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="身高：">
                                    {getFieldDecorator('height')(
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            min={1}
                                            placeholder="请输入身高(CM)"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="人脸图片：" required={true}>
                                    <Upload
                                        className="avatar-uploader"
                                        name="file"
                                        showUploadList={false}
                                        action={baseURL+"system/File/upload"}//http://localhost:9081/client/file/upload
                                        method="post"
                                        headers={headers}
                                        beforeUpload={beforeUpload}
                                        onChange={handleChange.bind(this)}
                                    >
                                        {
                                            imageUrl ?
                                                <div><img src={imageUrl} alt="" className="avatar"/></div> :
                                                <Icon type="plus" className="avatar-uploader-trigger"/>
                                        }
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Form>
                        :
                        <Form style={{display: 'inline-block'}}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="访客姓名：">
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: '请输入访客姓名！'}],
                                    })(
                                        <Input
                                            placeholder="请输入访客姓名"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            {/*<Col span={12}>*/}
                            {/*    <Form.Item {...formItemLayout} label="证件号：">*/}
                            {/*        {getFieldDecorator('credNo', {*/}
                            {/*            rules: [{*/}
                            {/*                required: true,*/}
                            {/*                message: '请输入证件号！',*/}
                            {/*                pattern: new RegExp(/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, "g")*/}
                            {/*            }],*/}
                            {/*        })(*/}
                            {/*            <Input*/}
                            {/*                placeholder="请输入证件号"*/}
                            {/*            />,*/}
                            {/*        )}*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="手机号：">
                                    {getFieldDecorator('phone', {
                                        rules: [{
                                            required: true,
                                            message: '请输入手机号！',
                                            pattern: new RegExp(/^1[3456789]\d{9}$/, "g")
                                        }],
                                    })(
                                        <Input
                                            placeholder="请输入手机号"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="岗位：">
                                    {getFieldDecorator('post', {
                                        rules: [{required: true, message: '请输入岗位！'}],
                                    })(
                                        <Select>
                                            {
                                                userPostData.map((item, index) => {
                                                    return (
                                                        <Option value={item.dictValue}
                                                                key={item.dictValue}>{item.dictDesc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属猪场：">
                                    {getFieldDecorator('pigFramId', {
                                        rules: [{required: true, message: '请选择猪场！'}],
                                    })(
                                        <TreeSelect
                                            style={{width: '100%'}}
                                            treeData={pigFarmList}
                                            treeDefaultExpandedKeys={expandTreeList}
                                            dropdownStyle={{height: 300, overflow: 'auto'}}
                                            placeholder="请选择"
                                            treeCheckStrictly
                                            allowClear
                                            treeCheckable
                                            multiple
                                            onChange={selectVideoSn}
                                        >
                                        </TreeSelect>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="授权访问时间：">
                                    {getFieldDecorator('authVisit', {
                                        rules: [{required: true, message: '请选择授权访问时间！'}],
                                    })(
                                        <DatePicker
                                            showTime={{
                                                format: 'HH:mm',
                                            }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{width: '100%'}}></DatePicker>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="可访问设备：">
                                    <div style={videoName?{color: 'black',fontWeight: '600'}:{}}>
                                        { videoName?videoName:'暂无' }
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="身高：">
                                    {getFieldDecorator('height')(
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            min={1}
                                            placeholder="请输入身高(CM)"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="人脸图片：" required={true}>
                                    <Upload
                                        className="avatar-uploader"
                                        name="file"
                                        showUploadList={false}
                                        action={baseURL+"system/File/upload"}//http://localhost:9081/client/file/upload
                                        method="post"
                                        headers={headers}
                                        beforeUpload={beforeUpload}
                                        onChange={handleChange.bind(this)}
                                    >
                                        {
                                            imageUrl ?
                                                <div><img src={imageUrl} alt="" className="avatar"/><Icon
                                                    style={{position: ' absolute', top: '120px'}} type="plus"
                                                    className="avatar-uploader-trigger"/></div> :
                                                <Icon type="plus" className="avatar-uploader-trigger"/>
                                        }
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Form>
                }
            </Modal>

            <Modal
                title='查看图片'
                visible={checkImg}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={null}
            >
                <div style={{ width: '100%',textAlign: 'center' }}>
                    <img style={{width: '60%'}} src={imageUrl}/>
                </div>
            </Modal>
        </Container>

    )
}

// export default Form.create()(Visitor)
const mapState = (state) => ({
    baseURL: state.baseURL,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Form.create()(Visitor))
