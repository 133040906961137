import styled from 'styled-components'

export const Container = styled.div `
    height: 100%;
    .top{
        margin-bottom: 4px;
    }
    .bottom1{
        height: calc(100% - 6rem);
        overflow-y: scroll;
      .dataDiv{
        cursor: pointer;
        width: 32%;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;
        float: left;
        margin-right: 1%;
        height: calc(50% - 10px);
        img {
          width: 100%;
          height: 100%;
        }
        .dataTitle{
          position: absolute;
          bottom: 5px;
          background-color: rgba(0,0,0,0.3);
          color: #FFFFFF;
          width: 100%;
          padding-left: 10px;
        }
        .unread{
          position: absolute;
          top: 10px;
          right: 10px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: red;
        }
      }
    }
  .ant-tabs-bar{
    background-color: #FFFFFF!important;
  }
  .ant-tabs-nav .ant-tabs-tab-active{
    font-weight: 700;
  }
  .ant-tabs-nav-scroll{
    font-weight: 600;
    margin-top: -14px!important;
  }
  .ant-pagination{
    margin-bottom: 12px!important;
  }
`
