import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  .messageDiv{
    height: 235px;
    padding: 0 10px 0 10px;
    background-color: #FFFFFF;
    overflow: auto;
    &-div{
      border-bottom: 1px solid rgb(211, 215, 223, 0.4);
      margin-top: 14px;
      .titleDate{
      display: flex;
      justify-content: space-between;
      &-title{
        font-size: 14px;
        //font-weight: bold;
        color: rgba(0, 0, 0, 0.8);
      }
      &-date{
        font-size: 10px;
        color: rgba(60, 66, 73, 0.5);
      }
    }
      .content{
        font-size: 14px;
        color: rgba(60, 66, 73, 0.6);
        margin-bottom: 14px;
        margin-top: 3px;
      }
    }
    
  }
  .inputDiv{
    height: 90px;
    padding: 10px 10px;
    border-top: rgba(0,0,0,0.4) solid 1px;
    //box-shadow: 0px -2px 3px rgba(0,0,0, 0.2);
    .TextAreaDiv{
      .TextArea{
        resize: none;
        //background-color: #EFEFEF;
      }
    }
    .sendButton{
      margin-top: -20px;
      display: flex;
      justify-content: flex-end;
    }
  }
`