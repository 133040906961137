import React, {useEffect, useState} from "react";
import {Container} from './style'
import {Button, Col, DatePicker, Form, Icon, Input, message, Modal, Select, Tabs, TreeSelect, Upload} from "antd";
import moment from "moment";
import AuditRequest from "../../../../request/audit-request";
import TempCarInfoHttp from '../../../../request/car-request'
import CommonHttp from '../../../../request/common-request'
import {VxTimeFormat} from '../../../../utils/homeFormat'
import {InfoCircleOutlined} from '@ant-design/icons';
import passImg from '../../../../img/pass.png'
import noPassImg from '../../../../img/noPass.png'

const {TabPane} = Tabs;
const {TextArea} = Input;
const {Option} = Select;
const _AuditRequest = new AuditRequest()
const _TempCarInfoHttp = new TempCarInfoHttp()
const _CommonHttp = new CommonHttp()
const pigFarmDiv = {
    padding: '9px 15px 10px 15px',
    marginRight: 10,
    background: '#F2F7FC',
    borderRadius: 5,
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: 13
}
const CutOff = {borderTop: '1px solid #E5E6EA', width: 500, marginLeft: -24, marginTop: 24}
const marginTop10 = {marginTop: 10}
const marginTop20 = {marginTop: 22}
const titleP = {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.85)'
}

function HomeAudit(props) {
    const {getFieldDecorator, getFieldValue, setFieldsValue, validateFields} = props.form

    const [personList, setPersonList] = useState([])
    const [vehicleList, setVehicleList] = useState([])
    const [carTypeData, setCarTypeData] = useState([]) //车辆类型列表
    const [auditVisible, setAuditVisible] = useState(false)
    const [auditVehicle, setAuditVehicle] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [personAuditData, setPersonAuditData] = useState({})
    const [vehicleAuditData, setVehicleAuditData] = useState({})
    const [userPostData, setUserPostData] = useState([])
    const [pigFarmList, setPigFarmList] = useState([])
    const [childRegionData, setChildRegionData] = useState([])
    const [personComments, setPersonComments] = useState('')
    const [vehicleComments, setVehicleComments] = useState('')
    const [pigFarmId, setPigFarmId] = useState('')


    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        },
    }

    const formItemLayout100 = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 4},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 19},
        },
    }

    useEffect(() => {
        getVisitorList()
        getTempCarInfoList()
        getDictList()
        getUserDictList()
        getChildRegionList()
    }, [])
    //获取员工列表
    const getVisitorList = () => {
        let params = {
            // state: '3',
            pageNo: 1,
            pageSize: 4
        }
        _AuditRequest.getVisitorListHome(params).then(res => {
            console.log(res);
            setPersonList(res.list)
        })
    }
    //获取列表
    const getTempCarInfoList = () => {
        let params = {
            // state: '3',
            pageNo: 1,
            pageSize: 4
        }
        _TempCarInfoHttp.getTempCarInfoListHome(params).then(res => {
            setVehicleList(res.list)
        })
    }

    //获取车辆类型列表
    const getDictList = () => {
        let params = {
            dictType: 'CAR_TYPE'
        }
        _CommonHttp.getDictList(params).then(res => {
            setCarTypeData(res.list)
        })
    }

    const cancelEdit = () => {
        setPersonComments('')
        setPersonAuditData({})
        setVehicleAuditData({})
        setPigFarmList([])
        setAuditVisible(false)
        setAuditVehicle(false)
    }

    //审核
    const confirm = (e) => {
        let param = {
            state: e,
            id: personAuditData.id,
            comments: personComments,
        }
        if (e === '2') {
            if (personComments === '') return message.error('请输入驳回原因！')
        }
        _AuditRequest.VisitorConfirm(param).then(res => {
            setImageUrl('')
            setAuditVisible(false)
            getVisitorList()
            message.info('审批成功！')
        })
    }

    //获取用户岗位列表
    const getUserDictList = () => {
        let params = {
            dictType: 'USER_POST'
        }
        _CommonHttp.getDictList(params).then(res => {
            setUserPostData(res.list)
        })
    }

    //获取猪场列表
    const getChildRegionList = () => {
        let params = {}
        _CommonHttp.getChildRegionList(params).then(res => {
            setChildRegionData(res)
        })
    }

    const openModal = (e) => {
        setAuditVisible(true);
        setTimeout(() => {
            setPersonAuditData({
                name: e.name,
                credNo: e.credNo,
                phone: e.phone,
                registrant: e.registrant,
                id: e.id,
                post: e.post,
                time: moment(moment(e.authVisit).format("YYYY-MM-DD"), "YYYY-MM-DD")
            })
            // setFieldsValue({
            //     post: e.post,
            //     credNo: e.credNo,
            //     phone: e.phone,
            //     authVisit: moment(moment(e.authVisit).format("YYYY-MM-DD"), "YYYY-MM-DD"),
            // })
            setImageUrl(e.imgPath)
        }, 0)
        _CommonHttp.getTempPreBuildding({
            tempPeopleId: e.id
        }).then(res => {
            let list = []
            let id
            res.forEach(item => {
                if (!item.split) return
                id = item.split('-')[0]
                if (!list.includes(id)) {
                    list.push(id)
                }
            })
            let listValue = []
            res.forEach(item => {
                listValue.push({
                    value: item.value,
                    label: item.label
                })
            })
            setPigFarmList(listValue)
        })
    }


    const openVehicleModal = (e) => {
        setAuditVehicle(true)
        //获取关联的猪场列表
        let params = {
            tempCarId: e.id
        }
        _TempCarInfoHttp.getTempCarFramList(params).then(res => {
            setPigFarmId(res.map(item => Number(item)))
        })
        setTimeout(() => {
            setVehicleAuditData({
                carNo: e.carNo,
                carType: e.carType,
                driver: e.driver,
                phone: e.phone,
                remark: e.remark,
                id: e.id
            })
        }, 0)
    }

    const vehicleConfirm = (e) => {
        let param = {
            state: e,
            id: vehicleAuditData.id,
            comments: vehicleComments,
        }
        if (e === '2') {
            if (vehicleComments === '') return message.error('请输入驳回原因！')
        }
        _AuditRequest.confirmCar(param).then(res => {
            getTempCarInfoList()
            message.info('审批成功！')
            setAuditVehicle(false)
        })
    }

    const tabsClick = (e) => {
        if (e === '1') {
            getVisitorList()
        } else if (e === '2') {
            getTempCarInfoList()
        }
    }

    return (
        <Container>
            <Tabs defaultActiveKey="1" type="card" style={{width: '100%'}} onChange={tabsClick}>
                <TabPane tab="临时人员登记" key="1">
                    <div className='auditDiv'>
                        {
                            personList.length === 0 ?
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    lineHeight: '230px',
                                    textAlign: 'center'
                                }}>暂无数据</div>
                                :
                                personList.map(item => {
                                    return (
                                        <div className='auditFlex' key={item.id}>
                                            <div className='left' style={{display: 'flex'}}>
                                                <div className='headImg'>
                                                    <img width='46' height='46' src={item.imgPath}
                                                         style={{borderRadius: '50%'}}/>
                                                </div>
                                                <div style={{marginLeft: 10}}>
                                                    <div className='credNo'>
                                                        <span style={{
                                                            fontSize: 14,
                                                            color: 'rgba(0, 0, 0, 0.8)',
                                                            fontWeight: 600
                                                        }}>{item.name}</span>
                                                        <span style={{
                                                            fontSize: 12,
                                                            marginLeft: 12,
                                                            marginRight: 12
                                                        }}>{VxTimeFormat(item.createDate)}</span>
                                                        {/*{*/}
                                                        {/*    item.state === '1'?*/}
                                                        {/*        <img src={passImg} width='30' height='14'/>*/}
                                                        {/*        :*/}
                                                        {/*    item.state === '2'?*/}
                                                        {/*        <img src={noPassImg} width='30' height='14'/>*/}
                                                        {/*        :''*/}
                                                        {/*}*/}
                                                    </div>
                                                    <div style={{fontSize: '10px'}}>
                                                        <span>{item.phone}</span>
                                                    </div>
                                                </div>
                                                {/*<div>{VxTimeFormat(item.createDate)}</div>*/}
                                            </div>
                                            <div className='right'>
                                                {
                                                    item.state === '3' ?
                                                        <div className='audit' onClick={openModal.bind(this, item)}
                                                             style={{marginTop: 10}}>
                                                            <span style={{fontSize: 12}}
                                                                  className='iconfont icon-shenpi1'> </span>
                                                            审核
                                                        </div> :item.state === '1'?
                                                        <div className='yetAudit' style={{marginTop: 10,backgroundColor: 'rgb(86, 157, 28, 0.1)'}}>
                                                            已通过
                                                        </div>:
                                                        item.state === '2'?
                                                        <div className='yetAudit' style={{marginTop: 10,backgroundColor:  'rgb(166, 65, 14,0.1)',color:'rgb(166, 65, 14)'}}>
                                                            未通过
                                                        </div>:''
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </TabPane>
                <TabPane tab="临时车辆登记" key="2">
                    <div className='auditDiv'>
                        {
                            vehicleList.length === 0 ?
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    lineHeight: '230px',
                                    textAlign: 'center'
                                }}>暂无数据</div>
                                :
                                vehicleList.map((item, index) => {
                                    return (
                                        <div className='auditFlex' key={index}>
                                            <div className='left'>
                                                <div className='credNo'>
                                                    <span style={{
                                                        fontSize: 14,
                                                        color: 'rgba(0, 0, 0, 0.8)',
                                                        fontWeight: 600
                                                    }}>{item.carNo} </span>
                                                    <span style={{
                                                        fontSize: 12,
                                                        marginLeft: 12,
                                                        marginRight: 12
                                                    }}> {VxTimeFormat(item.createDate)}</span>
                                                </div>
                                                <div style={{fontSize: '10px'}}>
                                                    <span>{item.driver}</span>
                                                    <span> | </span>
                                                    <span>{item.phone}</span>
                                                    <span> | </span>
                                                    <span>{
                                                        carTypeData.map((item2, index1) => {
                                                            if (item.carType === item2.dictValue) {
                                                                return item2.dictDesc
                                                            }
                                                        })
                                                    }</span>
                                                </div>
                                            </div>
                                            <div className='right'>
                                                {
                                                    item.state === '3' ?
                                                        <div className='audit'
                                                             onClick={openVehicleModal.bind(this, item)}
                                                             style={{marginTop: 10}}>
                                                            <span style={{fontSize: 12}} className='iconfont icon-shenpi1'> </span>
                                                            审核
                                                        </div> :item.state === '1'?
                                                    <div className='yetAudit' style={{marginTop: 10,backgroundColor: 'rgb(86, 157, 28, 0.1)'}}>
                                                    已通过
                                                    </div>:
                                                    item.state === '2'?
                                                    <div className='yetAudit' style={{marginTop: 10,backgroundColor: 'rgb(166, 65, 14,0.1)',color:'rgb(166, 65, 14)'}}>
                                                    未通过
                                                    </div>:''
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </TabPane>
            </Tabs>
            <Modal
                title='人员审批'
                visible={auditVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'500px'}
                footer={
                    <div style={{width: '100%', textAlign: 'right', padding: '10px 0'}}>
                        <Button className='ratify' onClick={confirm.bind(this, '1')}>通过</Button>
                        <Button className='noRatify' onClick={confirm.bind(this, '2')}>不通过</Button>
                    </div>
                }
            >
                <div>
                    <div style={{display: 'flex'}}>
                        <img src={imageUrl} width='116' height='116' style={{borderRadius: 12}}/>
                        <div style={{marginLeft: 24}}>
                            <div>姓&emsp;&emsp;名：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{personAuditData.name}</span></div>
                            <div style={marginTop10}>手 &nbsp;机 &nbsp;号：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{personAuditData.phone}</span></div>
                            {/*<div style={marginTop10}>证 &nbsp;件 &nbsp;号：<span style={{color: 'rgba(0, 0, 0, 0.85)'}}>{personAuditData.credNo}</span></div>*/}
                            <div style={marginTop10}>登记人员：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{personAuditData.registrant}</span></div>
                        </div>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>访问猪场</div>
                        <div style={{display: 'flex', marginTop: 10}}>
                            {
                                pigFarmList.length === 0 ? <div></div> :
                                    pigFarmList.map(item => {
                                        return (
                                            <div style={pigFarmDiv}>{item.label}</div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>授权访问时间</div>
                        <DatePicker style={{width: '45%', marginTop: 10}} value={personAuditData.time}
                                    disabled></DatePicker>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>审批说明 <span style={{color: '#A6410E', fontSize: 10}}> <InfoCircleOutlined/> 驳回时审批说明是必填的</span>
                        </div>
                        <TextArea placeholder='请输入驳回原因' value={personComments}
                                  onChange={(e) => setPersonComments(e.target.value)}
                                  style={{marginTop: 10, height: 85}}/>
                    </div>
                </div>
            </Modal>

            <Modal
                title='审核车辆'
                visible={auditVehicle}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'500px'}
                className='editClass'
                footer={
                    <div style={{width: '100%', textAlign: 'right', padding: '10px 0'}}>
                        <Button className='ratify' onClick={vehicleConfirm.bind(this, '1')}>通过</Button>
                        <Button className='noRatify' onClick={vehicleConfirm.bind(this, '2')}>不通过</Button>
                    </div>
                }
            >
                <div>
                    <div>
                        <div>车 &nbsp;牌 &nbsp;号：<span
                            style={{color: 'rgba(0, 0, 0, 0.85)'}}>{vehicleAuditData.carNo}</span></div>
                        <div style={marginTop10}>车辆类型：<span style={{color: 'rgba(0, 0, 0, 0.85)'}}>{
                            carTypeData.map((item, index1) => {
                                if (vehicleAuditData.carType === item.dictValue) {
                                    return item.dictDesc
                                }
                            })}</span></div>
                        <div style={marginTop10}>驾 &nbsp;驶 &nbsp;人：<span
                            style={{color: 'rgba(0, 0, 0, 0.85)'}}>{vehicleAuditData.driver}</span></div>
                        <div style={marginTop10}>手 &nbsp;机 &nbsp;号：<span
                            style={{color: 'rgba(0, 0, 0, 0.85)'}}>{vehicleAuditData.phone}</span></div>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>访问猪场</div>
                        <div style={{display: 'flex', marginTop: 10}}>
                            {
                                childRegionData.map(item => {
                                    if (pigFarmId[0] === item.regionId) {
                                        return (
                                            <div style={pigFarmDiv}>{item.regionName}</div>
                                        )
                                    }

                                })
                            }
                        </div>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>事项说明</div>
                        <TextArea value={vehicleAuditData.remark} disabled style={{marginTop: 10}}/>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>审批说明 <span style={{color: '#A6410E', fontSize: 10}}> <InfoCircleOutlined/> 驳回时审批说明是必填的</span>
                        </div>
                        <TextArea placeholder='请输入驳回原因' value={vehicleComments}
                                  onChange={(e) => setVehicleComments(e.target.value)}
                                  style={{marginTop: 10, height: 85}}/>
                    </div>

                </div>
                {/*<Form style={{display: 'inline-block'}}>*/}
                {/*    <Form.Item>*/}
                {/*        {getFieldDecorator('vehicleId')(*/}
                {/*            <span></span>*/}
                {/*        )}*/}
                {/*    </Form.Item>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="车牌号：">*/}
                {/*            <span>{vehicleAuditData.carNo}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="车辆类型：">*/}
                {/*            <span>{*/}
                {/*                carTypeData.map((item, index1) => {*/}
                {/*                    if (vehicleAuditData.carType === item.dictValue) {*/}
                {/*                        return item.dictDesc*/}
                {/*                    }*/}
                {/*                })*/}
                {/*            }</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}

                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="驾驶人：">*/}
                {/*            <span>{vehicleAuditData.driver}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="手机号：">*/}
                {/*            <span>{vehicleAuditData.phone}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="所属猪场：">*/}
                {/*            {getFieldDecorator('pigFramId')(*/}
                {/*                <Select mode="multiple" disabled>*/}
                {/*                    {*/}
                {/*                        childRegionData.map((item, index) => {*/}
                {/*                            return (*/}
                {/*                                <Option value={item.regionId}*/}
                {/*                                        key={item.regionId}>{item.regionName}</Option>*/}
                {/*                            )*/}
                {/*                        })*/}
                {/*                    }*/}
                {/*                </Select>*/}
                {/*            )}*/}

                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="事项说明：">*/}
                {/*            <span>{vehicleAuditData.remark}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*        <Form.Item {...formItemLayout100} label="审批意见：" required={true}>*/}
                {/*            {getFieldDecorator('vehicleComments', {*/}
                {/*                rules: [{required: true, message: '请输入审批意见！'}, {*/}
                {/*                    pattern: /^[^\s]*$/,*/}
                {/*                    message: '禁止输入空格！'*/}
                {/*                }],*/}
                {/*            })(*/}
                {/*                <TextArea style={{width: '100%'}}/>,*/}
                {/*            )}*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Form>*/}
            </Modal>
        </Container>
    )
}

export default Form.create()(HomeAudit)
