import request from '../https'

class AlertRequest{
    //获取列表
    getWarnRecord(param) {
        return request({
            url: 'safety/WarnRecord/page',
            method: 'GET',
            params: param
        })
    }
    //获取首页列表
    getHomeWarnRecord(param) {
        return request({
            url: 'safety/WarnRecord/wash/record',
            method: 'POST',
            params: param
        })
    }
}

export default AlertRequest