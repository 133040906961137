import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  .ant-tabs-bar{
    height: 50px!important;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container{
    height: 50px!important;
    div{
      //line-height: 50px!important;
      height: 100%;
    }
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container{
    width: 100%;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container div{
    width: 100%;
  }
  .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab{
    height: 50px!important;
    margin: 0!important;
    line-height: 50px!important;
    width: 50%;
  }

  .ant-btn:hover{
    background-color: #358AFF!important;
    color: #FFFFFF;
  }
  
  .title {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }

  .content {
    div{
      margin-top: 10px;
    }
  }
`