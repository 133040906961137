import request from '../https'

class ElectricMeterRequest{
	//智能电表列表=========================
    getElectricMeterList(params){
        return request({
            url: 'safety/electricMeter/list',
            method: 'post',
            params
        })
    }
    //智能电表使用详情=========================
    getElectricMeterDetail(params){
        return request({
            url: 'safety/electricMeter/getDetail',
            method: 'post',
            params
        })
    }
    //新增保存智能电表
    addElectricMeter(params) {
        return request({
            url: 'safety/electricMeter/add',
            method: 'POST',
            params
        });
    }
    //修改保存智能电表
    updateElectricMeter(params) {
        return request({
            url: 'safety/electricMeter/update',
            method: 'PUT',
            params
        });
    }
    //删除智能电表
    deleteElectricMeter(params) {
        return request({
            url: 'safety/electricMeter/delete/' + params.id,
            method: 'DELETE'
        });
    }
    
    
    
   
    
}

export default ElectricMeterRequest