import React, {useState, useEffect} from 'react'
import {Container} from './style'
import {Button, Col, Form, Input, message, Modal, Popconfirm, Select} from "antd";
import StoreSettingHttp from '../../../request/demo'
import DeTable from "../../../component/DeTable";
import moment from "moment";

const _request = new StoreSettingHttp()
const {TextArea} = Input;

function GoodsType(props) {
    const {getFieldValue, validateFields, getFieldDecorator, setFieldsValue} = props.form
    const [typeName, setTypeName] = useState('')
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([])
    const [modalType, setModalType] = useState('add')
    const [editVisible, setEditVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    }
    const tableColunm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '类型名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '说明',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: '所属机构',
            dataIndex: 'regionName',
            key: 'regionName',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
                        <span style={{marginRight: 8}} className='tableA'
                              onClick={openModal.bind(this, 'edit', record)}>编辑</span>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消"
                                    onConfirm={deleteRow.bind(this, record.id)}>
                            <span className='tableADelete'>删除</span>
                        </Popconfirm>
                    </span>
            ),
        },
    ]

    useEffect(() => {
        getGoodsTypeList()
    }, [])

    const deleteRow = (id) => {
        _request.delGoodsType({id: id}).then(res =>{
            message.info('删除成功！')
            getGoodsTypeList()
        })
    }

    const getGoodsTypeList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let param = {
            name: typeName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            param = {
                pageNo: 1,
                pageSize: 10,
                name: ''
            }
        }
        _request.getGoodsType(param).then(res => {
            setTableTotal(res.total)
            setTableData(res.list)
        }).catch(err => {
            console.log(err);
        })
    }

    const openModal = (type, record) => {
        setModalType(type)
        setEditVisible(true)
        if (type !== 'add') {
            setTimeout(() => {
                setFieldsValue({
                    name: record.name,
                    remark: record.remark,
                    id: record.id
                })
            }, 0)
        }
    }
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getGoodsTypeList()
    }
    const clickReset = () => {
        setTypeName('')
        setPageNo(1)
        setPageSize(10)
        getGoodsTypeList('reset')
    }
    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getGoodsTypeList('',pageNo,pageSize);
    }
    const editConFire = () => {
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (modalType === 'add') {
                    let params = {
                        name: getFieldValue('name'),
                        remark: getFieldValue('remark'),
                    }
                    _request.addGoodsType(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getGoodsTypeList()
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })
                } else {
                    let params = {
                        name: getFieldValue('name'),
                        remark: getFieldValue('remark'),
                        id: getFieldValue('id'),
                    }
                    _request.updateGoodsType(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getGoodsTypeList()
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38'}} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入类型名称" style={{width: 200, marginRight: 10}} value={typeName}
                       onPressEnter={clickSearch} onChange={(e) => setTypeName(e.target.value)}></Input>
                <Button type="primary" style={{marginRight: 10}} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>查询</Button>
                <Button type="primary" style={{marginRight: 10}} className='buttonReset' onClick={clickReset}>重置</Button>
            </div>
            <div className="bottom" style={{marginTop: 10}}>
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData}/>
            </div>
            <Modal
                title={modalType === 'add' ? '新增类型' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'40%'}
                className='editClass'
                footer={
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button type="primary" style={{width: 200}} loading={confirmLoading}
                                onClick={editConFire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{height: 150}}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={24}>
                                <Form.Item {...formItemLayout} label="类型名称：">
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: '请输入类型名称！'}],
                                    })(
                                        <Input
                                            style={{width: '50%'}}
                                            maxLength={10}
                                            placeholder="请输入类型名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout} label="说明：">
                                    {getFieldDecorator('remark')(
                                        <TextArea
                                            maxLength={50}
                                            placeholder="请输入说明"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                        :
                        <Form style={{height: 150}}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={24}>
                                <Form.Item {...formItemLayout} label="类型名称：">
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: '请输入类型名称！'}],
                                    })(
                                        <Input
                                            style={{width: '50%'}}
                                            maxLength={10}
                                            placeholder="请输入类型名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout} label="说明：">
                                    {getFieldDecorator('remark')(
                                        <TextArea
                                            maxLength={50}
                                            placeholder="请输入说明"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                }
            </Modal>
        </Container>
    )
}

export default Form.create()(GoodsType)
