import React, {useEffect, useState, useImperativeHandle, forwardRef} from "react";
import {Form, Button, Col, Select, Modal, Icon, message, Input} from "antd";
import {Container} from './style'
import AICameraRequest from '../../../../request/AICamera-request'
import CommonHttp from '../../../../request/common-request'
import DeVideo from "../../../../component/DeVideo";
import centre from '../../../../img/risk/centre.png'
import low from '../../../../img/risk/low.png'
import tall from '../../../../img/risk/tall.png'
import {EnvironmentOutlined,CaretRightOutlined} from '@ant-design/icons';
import { stringFormat } from "../../../../utils/homeFormat";
import moment from "moment";
import FlvVideo from "../../../../component/flvVideo";

const _request = new AICameraRequest();
const _commonRequest = new CommonHttp()

const { TextArea } = Input;

const AIAlert = forwardRef((props,ref) => {

    const [videoData,setVideoData] = useState([])
    const [promptEntryList,setPromptEntryList] = useState([])
    const [detailsData, setDetailsData] = useState({})   //设备位置
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [remake, setRemake] = useState('')
    const [editVisible, setEditVisible] = useState(false)
    useEffect(()=>{
        getVideoMonitorList()
        getPromptEntry()
    },[])
    useImperativeHandle(ref, () => ({
        getVideoMonitorList,
    }));
    const getVideoMonitorList = (item,risk) => {
        let params = {
            pageNo:1,
            pageSize:3
        }
        if (item){
            params.siteType = item.siteType
            params.siteId = item.siteId
            params.sitePigpenId = item.sitePigpenId
        }
        if (risk){
            params.riskLevel = risk
        }
        _request.getAiAlarmRecordList(params).then(res => {
            setVideoData(res.list)
        })
    }

    //编辑页取消
    const cancelEdit = () => {
        setDetailsData({})
        setEditVisible(false)
    }

    //获取推荐词条
    const getPromptEntry = () =>{
        _request.promptEntry().then(res =>{
            setPromptEntryList(res)
        })
    }

    const editConFire = () => {
        setConfirmLoading(true)
        let param = {
            id: detailsData.id,
            remarks: remake,
            state: '1'
        }
        _request.updateAiAlarmRecord(param).then(res =>{
            if (res.code){
                setConfirmLoading(false)
                message.info(res.msg)
                getVideoMonitorList()
                cancelEdit()
            }
        })
    }

    const playVideo = (e) => {
        setRemake(e.remakes)
        setDetailsData(e)
        setEditVisible(true)
    }

    return (
        <Container>
            {
                videoData.length === 0?<div style={{width: '100%',textAlign: 'center',lineHeight: '260px',height: 270}}>暂无数据</div>:
                    videoData.map((i,index) =>{
                        return (
                            <div className='videoDiv'>
                                <div className='dataDiv' onClick={playVideo.bind(this,i)}>
                                    <img style={{ borderRadius: '8px' }} src={i.pictureUrl}/>
                                    <div className="dataTitle">
                                        <div>事件：{i.alarmContent}</div>
                                        <div>位置：{i.sitePositionName}</div>
                                        {/*<div>风险等级：{i.riskLevel}</div>*/}
                                        <div>事件时间：{moment(i.createdDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                                    </div>
                                </div>

                                {
                                    i.state === 0?
                                        <div className='unread'></div>
                                        :''
                                }
                                {/*<div className={i.riskLevel === '高'?"tall":(i.riskLevel === '中'?"centre":(i.riskLevel === '低'?"low":""))}>*/}
                                {/*    {*/}
                                {/*        i.riskLevel === '高'?"高":(i.riskLevel === '中'?"中":(i.riskLevel === '低'?"低":""))*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className='risk' style={{
                                    backgroundImage: i.riskLevel === '高'?`url(${tall})`:(i.riskLevel === '中'?`url(${centre})`:(i.riskLevel === '低'?`url(${low})`:""))
                                }}>

                                </div>
                            </div>
                        )
                    })
            }


            <Modal
                title='查看详情'
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    detailsData.state === 0?
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <Button type="primary" style={{ width: 100 }} loading={confirmLoading} onClick={editConFire}>处理</Button>
                            <Button style={{ width: 100,marginLeft: 10 }} onClick={cancelEdit}>暂不处理</Button>
                        </div>:''
                }
            >
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div><b>设备位置</b>：{detailsData.sitePositionName}</div>
                        <div><b>风险等级</b>：{detailsData.riskLevel}</div>
                        <div><b>设备类型</b>：{detailsData.alarmType}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div><b>事件内容</b>：{detailsData.alarmContent}</div>
                        <div><b>事件时间</b>：{moment(detailsData.createdDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                        <div><b>设备名称</b>：{detailsData.videoName}（{detailsData.videoSn}）</div>
                    </div>
                </div>
                <div style={{ width: 'calc(100% + 48px)',height: 0.5,backgroundColor: '#e7e7e7',marginLeft: -24,marginTop: 10 }}></div>
                <div style={{ display: 'flex',marginTop: 15 }}>
                    <div style={{ width: '49%' }}>
                        <img style={{ width: '100%' }} src={detailsData.pictureUrl}/>
                    </div>
                    <div style={{ width: '49%',marginLeft: '1%' }}>
                        {/*{editVisible?<video width="100%" controls>*/}
                        {/*    <source src={detailsData.playbackUrl} type="video/mp4"></source>*/}
                        {/*</video>:''}*/}
                        {editVisible?detailsData.playbackUrl?<FlvVideo url={detailsData.playbackUrl} type={detailsData.videoUrlType}/>:'暂无视频':''}
                    </div>
                </div>
                <div style={{ width: 'calc(100% + 48px)',height: 0.5,backgroundColor: '#e7e7e7',marginLeft: -24,marginTop: 15,marginBottom: 15 }}></div>
                {detailsData.state === 0?<div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: 46}}>备注：</div>
                        <TextArea rows={4} value={remake} onChange={(e) => {
                            setRemake(e.target.value)
                        }}/>
                    </div>
                    <div style={{display: 'flex',marginTop: 10,}}>
                        <div style={{width: 100}}>推荐词条：</div>
                        <div style={{display: 'flex',flexFlow: 'wrap',color: 'rgba(0,0,0, 0.4)'}}>
                            {
                                promptEntryList.length > 0?promptEntryList.map(i =>{
                                    return <div style={{cursor: 'pointer',width: '50%'}} onClick={() => {
                                        setRemake(i)
                                    }}>
                                        {i}
                                    </div>
                                }):''
                            }
                        </div>
                    </div>
                </div>:<div>f
                    <div style={{display: 'flex'}}>
                        <div style={{width: 46}}>备注：</div>
                        <div>{remake}</div>，
                    </div>
                </div>}
            </Modal>

        </Container>
    )
})

// export default Form.create()(AIAlert)
export default AIAlert
