import React, {useState, useEffect, useRef} from 'react'
import {Container} from './style'

import {Button, Input, Modal, Col, Form, message, Radio, Table, Select, Popconfirm, TreeSelect} from 'antd'

import moment from 'moment'

import AICameraRequest from '../../../request/AICamera-request'

import CommonRequest from '../../../request/common-request'

import DeTable from '../../../component/DeTable';

import AreaItem from "./subpage";

const _request = new AICameraRequest()
const _common = new CommonRequest()
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
}
const { confirm } = Modal;
function Area(props) {

    const {getFieldValue, setFieldsValue, getFieldDecorator} = props.form
    const {washSpotId,time} = props
    const [modalType, setModalType] = useState(false) //
    const [addModalType, setAddModalType] = useState('') //
    const [editVisible, setEditVisible] = useState(false) //
    const [layoutVisible, setLayoutVisible] = useState(false) //
    const [confirmLoading, setConfirmLoading] = useState(false) //
    const [imageUrl, setImageUrl] = useState('')
    const [warnType, setWarnType] = useState('')
    const [regionName, setRegionName] = useState('')
    const [washList, setWashList] = useState([])
    const [flowTypeData, setFlowTypeData] = useState([])
    const [childRegionData, setChildRegionData] = useState([])
    const [riskLevelList, setRiskLevelList] = useState([])
    const [buildList, setBuildList] = useState([])
    const [riskLevel, setRiskLevel] = useState('高')

    const subPage = useRef(null)

    const tableColunm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '区域名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '所属机构',
            key: 'regionName',
            dataIndex: 'regionName',
        },
        {
            title: '修改时间',
            key: 'updatedDate',
            dataIndex: 'updatedDate',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            )
        },
        {
            title: '操作人',
            key: 'updatedName',
            dataIndex: 'updatedName',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
                    <span className='tableA' style={{marginRight: 8}} onClick={getRiskLevel.bind(this, record)}>风险等级</span>
                    <span className='tableA' style={{marginRight: 8}} onClick={openModel.bind(this, record)}>编辑</span>
                    <Popconfirm
                        title="确认删除吗?"
                        onConfirm={delArea.bind(this, record)}
                        okText="确认"
                        cancelText="取消"
                    >
                        <span className='tableADelete'>删除</span>
                    </Popconfirm>
                </span>
            ),
        },
    ]
    const tableColunm2 = [
        {
            title: '警报类型',
            dataIndex: 'alarmType',
            key: 'alarmType',
            align: 'center',
        },
        {
            title: '风险等级',
            key: 'riskLevel',
            align: 'center',
            render: (text, record, index) => (
                // <div onClick={setTableRiskLevel.bind(this,record,index)} style={{ cursor: "pointer" }}>
                //     <div style={{
                //         width: 20,
                //         backgroundColor: record.riskLevel === "高"?'red':(record.riskLevel === "中"?'orange':'green'),
                //         height: 20,
                //         borderRadius: '50%',
                //         margin: '0 auto'
                //     }}></div>
                // </div>
                <Radio.Group onChange={setTableRiskLevel.bind(this,record,index)} value={record.riskLevel}>
                    <Radio value={'高'}>高</Radio>
                    <Radio value={'中'}>中</Radio>
                    <Radio value={'低'}>低</Radio>
                </Radio.Group>
            ),
        },
    ]

    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
        getVideoMonitorList()
        washSpotList()
        getChildRegionList()
        getDictList()
    }, [])

    //获取列表
    const getVideoMonitorList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            current:pageNo,
            size:pageSize,
            name: getFieldValue('keyWord'),
        }
        if (ButtonType === 'reset') {
            params = {
                current: 1,
                size: 10,
            }
        }
        _request.getZoneList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

    //获取人员摄像头关联的猪场栋舍列表
    const getPigFarm = (e) => {
        let params = {
            regionType : 5,
            enterpriseType : 2,
            regionId: e
        }
        _common.getFramBuilddingList(params).then(res => {
            let list = []
            res.forEach(item => {
                let childList = []
                item.buildList.forEach(child => {
                    childList.push({
                        title: child.buildingName,
                        value: child.buildingId,
                        isLeaf: true
                    })
                })
                list.push({
                    title: item.pigFramName,
                    value: item.pigFramId,
                    children: childList,
                })
            })
            setBuildList(list)
        })
    }


    //获取下属所有机构
    const getChildRegionList = () => {
        let params = {
            regionId: sessionStorage.getItem('regionId')
        }
        _common.getChildRegionListAll(params).then(res => {
            setChildRegionData(res)
        })
    }

    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }

    //设置表格风险等级
    const setTableRiskLevel = (record,i,e) => {
        const arr = [...riskLevelList]
        arr[i].riskLevel = e.target.value
        setRiskLevelList(arr)
    }

    //打开搜索
    const openModel = (record) => {
        setAddModalType('edit')
        setEditVisible(true)
        getPigFarm(record.regionId)
        getPigpenList(record.id)
        setTimeout(() => {
            setFieldsValue({
                // regionId: Number(record.regionId),
                regionId: record.regionId,
                name: record.name,
                type: record.type,
                id: record.id
            })
            setRegionName(record.regionName)
        }, 0)
    }

    const getPigpenList = (id) => {
        let param = {
            id
        }
        _request.getPigpenList(param).then(res =>{
            let arr = res.map(i => i.pigpenId)
            setTimeout(() => {
                setFieldsValue({
                    tBdAiPigpens: arr
                })
            }, 0)
        })
    }

    //删除
    const delArea = (record) => {
        _request.deleteZone(record.id).then(res =>{
            message.info('删除成功！')
            getVideoMonitorList()
        })
    }

    //获取风险等级列表
    const getRiskLevel = (record) => {
        const param = {
            zoneId: record.id
        }
        _request.getRiskLevel(param).then(res =>{
            setRiskLevelList(res)
            setModalType(true)
        })
    }

    const cancelEdit = () => {
        setModalType(false)
        setImageUrl('')
        setEditVisible(false)
        setLayoutVisible(false)
        setAddModalType('')
        setConfirmLoading(false)
    }

    // 获取区域类型列表
    const getDictList = () => {
        let params = {
            dictType: 'AI_ZONE_TYPE',
        }
        _common.getDictList(params).then(res => {
            setFlowTypeData(res.list)
        })
    }

    //保存
    const editConFire = () => {
        const { getFieldValue, validateFields } = props.form
        let tBdAiPigpensList = []
        let arr = []
        if (getFieldValue('tBdAiPigpens')){
            getFieldValue('tBdAiPigpens').forEach((item,index) =>{
                let i = item.split('-')
                if (i.length === 2){
                    arr.push({
                        pigpenId: item
                    })
                }
            })
        }
        buildList.forEach(i =>{
            if (i.children.length > 0){
                i.children.forEach(child => {
                    arr.forEach(j =>{
                        if (j.pigpenId == child.value){
                            tBdAiPigpensList.push({
                                name: child.title,
                                pigpenId: j.pigpenId
                            })
                        }
                    })
                })
            }
        })
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (addModalType === 'add') {
                    let params = {
                        name: getFieldValue('name'),
                        regionId: getFieldValue('regionId'),
                        type: getFieldValue('type'),
                        tBdAiPigpens: tBdAiPigpensList,
                        regionName,
                    }
                    _request.addZone(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getVideoMonitorList()
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })

                } else {
                    let params = {
                        name: getFieldValue('name'),
                        regionId: getFieldValue('regionId'),
                        type: getFieldValue('type'),
                        regionName,
                        tBdAiPigpens: tBdAiPigpensList,
                        id: getFieldValue('id')
                    }
                    _request.updateZone(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getVideoMonitorList()
                    }).catch(err =>{
                        setConfirmLoading(false)
                    })

                }
            }
        })
    }

    //区域布局保存
    const layoutSave = () =>{
        let arr = subPage.current.layout
        let flag = false
        arr.forEach(i =>{
            if (i.y > 5 || i.h > 6 || (i.y === 1 && i.h > 5) || (i.y === 2 && i.h > 4) || (i.y === 3 && i.h > 3) || (i.y === 4 && i.h > 2) || (i.y === 5 && i.h > 1)){
                flag = true
            }
        })
        if (flag) return message.error('区域布局不可超出固定范围！')
        _request.updateAiLayout(arr).then(res =>{
            message.info('保存成功！')
            setLayoutVisible(false)
        })
    }

    const updateRiskLevel = () => {
        let overwrite = true
        confirm({
            title: '是否按规则覆盖该区域设备设置的风险等级?',
            onOk() {
                overwrite = true
                setConfirmLoading(true)
                const arr = riskLevelList.map(i=>{
                    let obj = {}
                    obj.id = i.id
                    obj.riskLevel = i.riskLevel
                    return obj
                })
                let param = {
                    tBdAiRisklevels: arr,
                    overwrite
                }
                _request.updateRiskLevel(param).then(res => {
                    setModalType(false)
                    setConfirmLoading(false)
                    message.info('修改成功！')
                    getVideoMonitorList()
                })
            },
            onCancel() {
                overwrite = false
                setConfirmLoading(true)
                const arr = riskLevelList.map(i=>{
                    let obj = {}
                    obj.id = i.id
                    obj.riskLevel = i.riskLevel
                    return obj
                })
                let param = {
                    tBdAiRisklevels: arr,
                    overwrite
                }
                _request.updateRiskLevel(param).then(res => {
                    setModalType(false)
                    setConfirmLoading(false)
                    message.info('修改成功！')
                    getVideoMonitorList()
                })
            },
        });
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getVideoMonitorList()
    }
    //新增
    const addArea = () => {
        setAddModalType('add')
        setEditVisible(true)
        getPigFarm()
    }

    const selectRegion = (e,e2) => {
        setRegionName(e2.props.children)
        getPigFarm(e)
    }

    const selectRiskLevel = (value) => {
        setRiskLevel(value)
    }

    const layoutSet = () => {
        setLayoutVisible(true)
    }

    //重置
    const clickReset = () => {
        setWarnType('')
        props.form.resetFields()
        setPageNo(1)
        setPageSize(10)
        getVideoMonitorList('reset')
    }
    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getVideoMonitorList('',pageNo,pageSize);
    }
    const {Option} = Select;

    return (
        <Container>
            <div className="top" style={{ display: 'flex' }}>
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={addArea}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Form layout="inline">
                    <Form.Item>
                        {getFieldDecorator('keyWord')(
                            <Input placeholder="请输入区域名称" />
                        )}
                    </Form.Item>
                </Form>
                <Button type="primary" style={{marginRight: 10, marginTop: 3}} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{marginTop: 3}} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
                <Button type="primary" style={{marginLeft: 10, marginTop: 3}} onClick={layoutSet}>区域位置布局设置</Button>
            </div>
            <div style={{marginBottom: 6}}>合计&nbsp;{tableTotal}&nbsp;条</div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData}/>
            </div>

            <Modal
                title='风险等级设置'
                visible={modalType}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'520px'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={updateRiskLevel}>提交</Button>
                    </div>
                }
            >
                {/*<div style={{ display: 'flex',marginBottom: 10 }} className='riskLevel'>
                    <div style={{
                        display: 'flex',
                        cursor: "pointer",
                        fontWeight: riskLevel === "高"?'bold':'400',
                        fontSize: 16,
                        textDecoration:'underline'
                    }} onClick={selectRiskLevel.bind(this,'高')}>
                        <div style={{
                            height: 20,
                            width: 20,
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            marginRight: 10
                        }}></div>
                        高风险
                    </div>
                    <div style={{
                        display: 'flex',
                        marginLeft: 20,
                        cursor: "pointer",
                        fontWeight: riskLevel === "中"?'bold':'400',
                        fontSize: 16,
                        textDecoration:'underline'
                    }} onClick={selectRiskLevel.bind(this,'中')}>
                        <div style={{
                            height: 20,
                            width: 20,
                            backgroundColor: 'orange',
                            borderRadius: '50%',
                            marginRight: 10
                        }}></div>
                        中风险
                    </div>
                    <div style={{
                        display: 'flex',
                        marginLeft: 20,
                        cursor: "pointer",
                        fontWeight: riskLevel === "低"?'bold':'400',
                        fontSize: 16,
                        textDecoration:'underline'
                    }} onClick={selectRiskLevel.bind(this,'低')}>
                        <div style={{ height: 20,
                            width: 20,
                            backgroundColor: 'green',
                            borderRadius: '50%',
                            marginRight: 10
                        }}></div>
                        低风险
                    </div>
                </div>*/}
                <Table
                    dataSource={riskLevelList}
                    bordered
                    columns={tableColunm2}
                    pagination={false}
                />
            </Modal>

            <Modal
                title={addModalType === 'add' ? '新增区域' : '编辑区域'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConFire}>提交</Button>
                    </div>
                }
            >
                {
                    addModalType === 'add' ?
                        <Form style={{ height: 150 }}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="区域名称：">
                                    {getFieldDecorator('name', {
                                        rules: [{ required: true, message: '请输区域名称！' },{ pattern: /^[^\s]*$/, message: '禁止输入空格！' }],
                                    })(
                                        <Input
                                            maxLength={20}
                                            placeholder="请输区域名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属机构：">
                                    {getFieldDecorator('regionId', {
                                        rules: [{ required: true, message: '请选择所属机构!' }],
                                    })(
                                        <Select placeholder="请选择所属机构" onSelect={selectRegion}>
                                            {
                                                childRegionData.map((item,index) => {
                                                    return (
                                                        <Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="类型：">
                                    {getFieldDecorator('type', {
                                        rules: [{ required: true, message: '请选择类型!' }],
                                    })(
                                        <Select placeholder="请选择类型">
                                            {
                                                flowTypeData.map((item,index) => {
                                                    return (
                                                        <Option value={item.dictDesc} key={item.dictDesc} >{item.dictDesc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="涵盖栋舍：">
                                    {getFieldDecorator('tBdAiPigpens')(
                                        <TreeSelect
                                            style={{width: '100%'}}
                                            treeData={buildList}
                                            dropdownStyle={{height: 300, overflow: 'auto'}}
                                            placeholder="请选择"
                                            allowClear
                                            treeCheckable
                                            multiple
                                            maxTagCount={1}
                                        >
                                        </TreeSelect>
                                    )}

                                </Form.Item>
                            </Col>
                        </Form>
                        :
                        <Form style={{ height: 150 }}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="区域名称：">
                                    {getFieldDecorator('name', {
                                        rules: [{ required: true, message: '请输入区域名称！' }],
                                    })(
                                        <Input
                                            maxLength={20}
                                            placeholder="请输入区域名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属机构：">
                                    {getFieldDecorator('regionId', {
                                        //initialValue:pigFramData.map(Number),//.map(Number)

                                    })(
                                        <Select  placeholder="请选择所属机构" onSelect={selectRegion}>
                                            {
                                                childRegionData.map((item,index) => {
                                                    return (
                                                        <Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="类型：">
                                    {getFieldDecorator('type', {
                                        rules: [{ required: true, message: '请选择类型!' }],
                                    })(
                                        <Select placeholder="请选择类型">
                                            {
                                                flowTypeData.map((item,index) => {
                                                    return (
                                                        <Option value={item.dictDesc} key={item.dictDesc} >{item.dictDesc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="涵盖栋舍：">
                                    {getFieldDecorator('tBdAiPigpens')(
                                        <TreeSelect
                                            style={{width: '100%'}}
                                            treeData={buildList}
                                            dropdownStyle={{height: 300, overflow: 'auto'}}
                                            placeholder="请选择"
                                            allowClear
                                            treeCheckable
                                            multiple
                                            maxTagCount={1}
                                        >
                                        </TreeSelect>
                                    )}

                                </Form.Item>
                            </Col>
                        </Form>
                }
            </Modal>
            <Modal
                title='区域布局设置'
                visible={layoutVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'630px'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={layoutSave}>提交</Button>
                    </div>
                }
            >
                <AreaItem areaList={tableData} ref={subPage}/>
            </Modal>
        </Container>

    )
}

export default Form.create()(Area)
