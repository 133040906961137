import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------品类管理
    //获取品类列表
    getAllType(params) {
        return request({
            url: 'safety/category/categoryList',
            method: 'post',
            params
        })
    }
    //新增品类
    addType(params) {
        return request({
            url: 'safety/category/addGoodsCategory',
            method: 'post',
            params
        })
    }
    //编辑品类
    editType(params) {
        return request({
            url: 'safety/category/updateCategory',
            method: 'post',
            params
        })
    }
    //删除品类
    deleteType(params) {
        return request({
            url: 'safety/category/deleteOneCategory',
            method: 'post',
            params
        })
    }
    //导入
    addMultiType(data) {
        return request({
            url: 'safety/category/addMultiCategory',
            method: 'post',
            data
        })
    }

    //--------------------------------------------------------物资管理
    //所有物资
    findMaterialRegister(data) {
        return request({
            url: 'safety/register/list',
            method: 'post',
            data
        })
    }
    //新增物资
    addMaterialRegister(data) {
        return request({
            url: 'safety/register/add',
            method: 'post',
            data
        })
    }
    //修改物资
    updateMaterialRegister(data) {
        return request({
            url: 'safety/register/update',
            method: 'post',
            data
        })
    }
    //删除物资
    deleteMaterialRegister(data) {
        return request({
            url: 'safety/register/delete',
            method: 'post',
            data
        })
    }
    //物资 洗消记录
    findMaterialDecontamination(data) {
        return request({
            // url: 'safety/decontamination/list',
            url: 'safety/goods/washInfo/list',
            method: 'post',
            data
        })
    }
    //物资 洗消记录详情
    goodsDetail(data) {
        return request({
            url: 'safety/goods/washInfo/detail',
            method: 'post',
            data
        })
    }
    //物资 首页洗消记录
    getWashGoodsList(data) {
        return request({
            url: 'safety/wash/goods/record',
            method: 'post',
            data
        })
    }
    //物品库存
    findMaterialStock(data) {
        return request({
            url: 'safety/stock/list',
            method: 'post',
            data
        })
    }
    //查询物品出入库
    findMaterialOutAndWarehousing(data) {
        return request({
            url: 'safety/outAndWarehousing/list',
            method: 'post',
            data
        })
    }
    //新增物品出入库
    addMaterialOutAndWarehousing(data) {
        return request({
            url: 'safety/outAndWarehousing/add',
            method: 'post',
            data
        })
    }

    //--------------------------------------------------------物资类型
    //新增物资类型
    addGoodsType(data) {
        return request({
            url: 'safety/goodsType/add',
            method: 'post',
            data
        })
    }
    //修改物资类型
    updateGoodsType(data) {
        return request({
            url: 'safety/goodsType/update',
            method: 'post',
            data
        })
    }
    //删除物资类型
    delGoodsType(data) {
        return request({
            url: 'safety/goodsType/delete',
            method: 'post',
            data
        })
    }
    //物资类型列表
    getGoodsType(data) {
        return request({
            url: 'safety/goodsType/list',
            method: 'post',
            data
        })
    }

    //--------------------------------------------------------消毒间管理
    //新增消毒间
    addGoodsRoom(data) {
        return request({
            url: 'safety/disinfectionRoom/add',
            method: 'post',
            data
        })
    }
    //编辑消毒间
    updateGoodsRoom(data) {
        return request({
            url: 'safety/disinfectionRoom/update',
            method: 'post',
            data
        })
    }
    //删除消毒间
    delGoodsRoom(data) {
        return request({
            url: 'safety/disinfectionRoom/delete',
            method: 'post',
            data
        })
    }
    //分页查询消毒间列表
    getGoodsRoomList(data) {
        return request({
            url: 'safety/disinfectionRoom/page',
            method: 'post',
            data
        })
    }
}

export default StoreSettingHttp