import React, { useState, useEffect } from 'react'
import {Container} from './style'

import {Button, Input, Select, Modal, Table, Popconfirm, Col, Form, message, Icon, Steps} from 'antd'
import { DatePicker } from 'antd';

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import DecontaminationHttp from '../../../request/car-request'
import CommonHttp from '../../../request/common-request'
import {getPeriodTime, stringFormat} from "../../../utils/homeFormat";
import CommonRequest from "../../../request/common-request";
import WashTotalRequest from "../../../request/washTotal-request";

const _request = new DecontaminationHttp()
const _commonrequest = new CommonHttp()
const _common = new CommonRequest()
const _washRequest = new WashTotalRequest()

const Option = Select.Option;

const { MonthPicker, RangePicker } = DatePicker;

const { Step } = Steps;
const marginTop10 = {marginTop: 10}
const colorBlack = {color: 'rgba(0, 0, 0, 0.9)'}
const CutOff = {borderTop: '1px solid #E5E6EA', width: 520, marginLeft: -24, marginTop: 24}
function DecontaminationRecord(props) {
    const [carNo, setCarNo] = useState('')
    const [supervisor, setSupervisor] = useState('')
    const [startTime, setStartTime] = useState(moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD') + ' 00:00:00')
    const [endTime, setEndTime] = useState(moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59')
    const [workflowId, setWorkflowId] = useState()
    const [washDetailList, setWashDetailList] = useState([])
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '车牌号',
                dataIndex: 'carNo',
                key: 'carNo',
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
                render: (text,record,index) => (
                    stringFormat(text,9)
                )
            },
            {
                title: '监管人',
                dataIndex: 'supervisor',
                key: 'supervisor',
            },
//          {
//              title: '所属猪场',
//              key: 'pigFramName',
//              dataIndex: 'pigFramName',
//          },
            {
                title: '流程',
                key: 'workflowName',
                dataIndex: 'workflowName',
            },
           {
                title: '视频位置',
                key: 'videoName',
                dataIndex: 'videoName',
            },
            {
                title: '操作时间',
                dataIndex: 'operationDate',
                key: 'operationDate',
				render: (text, record, index) => {
					return <span>{moment(record.operationDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '类型',
                key: 'type',
                dataIndex: 'type',
                render: (text, record, index) => (
                    <span>{record.type === '1' ? '常驻车辆' :  '临时车辆'}</span>
                )
            },
            {
                title: '车辆类型',
                key: 'carType',
                dataIndex: 'carType',
                render: (text, record, index) => (
                	carTypeData.map((item,index1) => {
						if(record.carType === item.dictValue) {
							return  <span>{item.dictDesc}</span>
						}
					})

                )
            },
            {
                title: '操作',
                key: 'imgPath',
                dataIndex: 'imgPath',
                render: (imgPath,record) => (
                    <span className='tableA' onClick={imagesPath.bind(this, imgPath, record)}>详情</span>
                )
            }
        ]


    //查看图片
	const [imagePath, setImagePath] = useState(false) //图片查看
	const [imgVehicle, setImgVehicle] = useState({})
	const [imagPath, setImagPath] = useState('')
    const [extractWork, setExtractWork] = useState('')
    const [washList, setWashList] = useState([])
    const [stepCurrent, setStepCurrent] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
	const imageEdit = () =>{
        setImagePath(false)
    }
	const imagesPath = (imgPath,record) => {
        carWashDetail(record)
		setImgVehicle(record)
        setImagePath(true)
		setImagPath(imgPath)
		const extractWork = record.workflowName
		setExtractWork(extractWork.substring(0,4))
	}
     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [carTypeData, setCarTypeData] = useState([]) //车辆类型列表
    const {washSpotId,time} = props
    useEffect(() => {
        getDictList()
        washSpotList()
        if (washSpotId){
            setWorkflowId(washSpotId.split('&')[1])
            setWorkflowId(washSpotId.split('&')[1])
            let endDate = moment(time).format('YYYY-MM-DD ') + '23:59:59'
            setStartTime(moment(time).format('YYYY-MM-DD HH:mm:ss'))
            setEndTime(endDate)
            getDecontaminationRecordList('',1,10,washSpotId,time)
        }else {
            getDecontaminationRecordList()
        }
    }, [washSpotId,time])
    //获取列表
    const getDecontaminationRecordList = (ButtonType, pageNo = 1, pageSize = 10,id,date) => {
        let params = {
            carNo:carNo,
            supervisor:supervisor,
            startTime:startTime,
            endTime: endTime,
            type:true,//web端查询默认查询历史纪录
            washSpotId:workflowId,
            pageNo,
            pageSize
        }
        if (id){
            params.washSpotId = id.split('&')[1]
            let endDate = moment(date).format('YYYY-MM-DD ') + '23:59:59'
            params.startTime = moment(date).format('YYYY-MM-DD HH:mm:ss')
            params.endTime = endDate
        }
        if (ButtonType === 'reset') {
            params = {
            	type:'true',//web端查询默认查询历史纪录
                startTime: moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD') + ' 00:00:00',
                endTime: moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59',
                pageNo,
                pageSize
            }
        }
        _request.getDecontaminationRecordList(params).then(res => {
        	setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

    const carWashDetail = (val) =>{
        let param = {
            id: val.carId,
            beginDate: moment(val.operationDate).format("YYYY-MM-DD")
        }
        _washRequest.carWashDetail(param).then(res =>{
            let arr = []
            let list = deWeightThree(res.list)
            list.map((item,index,list) => {
                item.time = index > 0 ? getPeriodTime(item,list[index-1]) : '';
                arr.splice(0,0,item)
            })
            arr.forEach((item,index) =>{
                if (item.createDate === val.createDate){
                    setStepCurrent(index)
                }
            })
            setWashDetailList(arr)
        })
    }
    const deWeightThree = (arr) => {
        let map = new Map();
        for (let item of arr) {
            if (!map.has(moment(item.createDate).format("YYYY-MM-DD HH:mm"))) {
                map.set(moment(item.createDate).format("YYYY-MM-DD HH:mm"), item);
            }
        }
        return [...map.values()];
    }
     //获取车辆类型列表
    const getDictList = () => {
        let params = {
           dictType:'CAR_TYPE'
        }
        _commonrequest.getDictList(params).then(res => {
            setCarTypeData(res.list)
        })
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getDecontaminationRecordList()
    }
    //重置
    const clickReset = () => {
        setWorkflowId()
		setSupervisor('')
		setCarNo('')
        setPageNo(1)
        setPageSize(10)
        setStartTime(moment(moment().startOf('day').subtract(6, 'days')).format('YYYY-MM-DD') + ' 00:00:00')
        setEndTime(moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59')
        getDecontaminationRecordList('reset',1,10)
    }


    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getDecontaminationRecordList('',pageNo,pageSize);
    }

    //日期控件搜索
    function range(start, end) {
	  const result = [];
	  for (let i = start; i < end; i++) {
	    result.push(i);
	  }
	  return result;
	}

	function disabledDate(current) {
	  // Can not select days before today and today
	  return current && current < moment().endOf('day');
	}

    function disabledDateTime() {
	  return {
	    disabledHours: () => range(0, 24).splice(4, 20),
	    disabledMinutes: () => range(30, 60),
	    disabledSeconds: () => [55, 56],
	  };
	}

	function disabledRangeTime(_, type) {
	  if (type === 'start') {
	    return {
	      disabledHours: () => range(0, 60).splice(4, 20),
	      disabledMinutes: () => range(30, 60),
	      disabledSeconds: () => [55, 56],
	    };
	  }
	  return {
	    disabledHours: () => range(0, 60).splice(20, 4),
	    disabledMinutes: () => range(0, 31),
	    disabledSeconds: () => [55, 56],
	  };
	}

    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }

    function rangeTimeChange(date){
    	if(date){
    		if(date[0]!=undefined){
    			setStartTime(date[0].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setStartTime('');
    		}
    		if(date[1]!=undefined){
    			setEndTime(date[1].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setEndTime('');
    		}
    	}
    }


    return (
        <Container>
            <div className="top">
                <Select placeholder="请选择洗消点" style={{width: 200, marginRight: 10}} showSearch value={workflowId}
                        onChange={e => setWorkflowId(e)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                    {
                        washList.map((item, index) => {
                            return <Option key={item.key} value={item.id}>{item.name}</Option>
                        })
                    }
                </Select>
                <Input placeholder="请输入车牌号" style={{ width: 200, marginRight: 10 }} value={carNo} onPressEnter={clickSearch} onChange={(e) => setCarNo(e.target.value)}></Input>
                <Input placeholder="请输入监管人" style={{ width: 200, marginRight: 10 }} value={supervisor} onPressEnter={clickSearch} onChange={(e) => setSupervisor(e.target.value)}></Input>
                <RangePicker
                    ranges={{
                        '今天': [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                        '一个月': [moment().startOf('month'), moment().endOf('month')],
                    }}
	                value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD HH:mm:ss"), moment(endTime, "YYYY-MM-DD HH:mm:ss")]}
				   /* disabledTime={disabledRangeTime}*/
				    onChange={rangeTimeChange}
				    showTime={{
				    	hideDisabledOptions: true,
				        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				    }}
				    format="YYYY-MM-DD"
				/>

               	&nbsp;&nbsp;
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div style={{marginBottom: 5}}>合计&nbsp;{tableTotal}&nbsp;条</div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal}
                         setOn={setPageNo}
                         setSize={setPageSize}
                         current={pageNo}
                         pageSize={pageSize}
                         onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                         dataSource={tableData} />
            </div>

            <Modal
                title={'车辆图片'}
                visible={imagePath}
                centered={true}
                width={'520px'}
                onCancel={imageEdit}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
            >
                <div style={{ display: 'flex' }}>
                    <img src={imagPath} width='204' height='204' style={{borderRadius: 12}}/>
                    <div style={{ marginLeft: 24,width: 300 }}>
                        <div>车 &nbsp;牌 &nbsp;号：<span style={colorBlack}>{imgVehicle.carNo}</span></div>
                        <div style={marginTop10}>监 &nbsp;管 &nbsp;人：<span style={colorBlack}>{imgVehicle.supervisor}</span></div>
                        <p style={{marginTop: 10,float: 'left'}}>位&emsp;&emsp;置：</p><p style={{marginTop: 10,color: 'rgba(0, 0, 0, 0.85)'}}>{imgVehicle.videoName ? (imgVehicle.workflowId == -1 ? extractWork + ' ' + imgVehicle.videoName : imgVehicle.videoName) : imgVehicle.workflowName}</p>
                        <div style={marginTop10}>类&emsp;&emsp;型：<span style={colorBlack}>{imgVehicle.type === '1' ? '常驻车辆' :  '临时车辆'}</span></div>
                        <div style={marginTop10}>车辆类型：<span style={colorBlack}>{
                            carTypeData.map((item,index1) => {
                                if(imgVehicle.carType === item.dictValue) {
                                    return  <span>{item.dictDesc}</span>
                                }
                        })}</span></div>
                        <div style={marginTop10}>操作时间：<span style={colorBlack}>{moment(imgVehicle.operationDate).format("YYYY-MM-DD HH:mm:ss")}</span></div>
                        <p style={{marginTop: 10,float: 'left'}}>洗 &nbsp;消 &nbsp;点：</p><p style={{marginTop: 10,color: 'rgba(0, 0, 0, 0.85)'}}>{imgVehicle.washSpotName}</p>
                    </div>
                </div>
                {
                    washDetailList.length === 0?'':
                        <div>
                            <div style={CutOff}></div>
                            <div style={{fontSize: 16,fontWeight: '500',marginTop: 10,color: 'rgba(0,0,0,0.8)'}}>行为路径</div>
                            <div style={{height: 300,overflow: 'auto',marginTop: 20}}>
                                <Steps progressDot current={stepCurrent} direction="vertical">
                                    {
                                        washDetailList.map((item,index,list) =>{
                                            return <Step title={
                                                index === stepCurrent?
                                                    <div style={{color: '#5A8BFF'}}>
                                                        <span>{moment(item.createDate).format("MM-DD HH:mm")}</span>
                                                        <span style={{marginLeft: 10,fontWeight: 500}}>{item.videoName}</span>
                                                        <span style={{fontSize: '12px',padding: 5,backgroundColor: 'rgba(90,139,255,0.2)',marginLeft: 10}}>记录点</span>
                                                    </div>:
                                                    <div>
                                                        <span>{moment(item.createDate).format("MM-DD HH:mm")}</span>
                                                        <span style={{marginLeft: 10,fontWeight: 500}}>{item.videoName}</span>
                                                    </div>
                                            } description={item.time} />
                                        })
                                    }

                                </Steps>
                            </div>
                        </div>
                }
                {/*{*/}
                {/*    imagPath ?*/}
				{/*	<div style={qrStyle}>*/}
				{/*		<div style={centerStyle}><img width='100%' src={imagPath} alt="" /></div>*/}
				{/*		<div style={{ marginTop: 10 }}>*/}
				{/*			<p>车牌号：{ imgVehicle.carNo ? imgVehicle.carNo : '无' }</p>*/}
				{/*			<p>洗消点：{ imgVehicle.washSpotName ? imgVehicle.washSpotName : '无' }</p>*/}
				{/*			<p>监管人：{ imgVehicle.supervisor ? imgVehicle.supervisor : '无' }</p>*/}
				{/*			<p>位置：{ imgVehicle.videoName ? (imgVehicle.workflowId == -1 ? extractWork + ' ' + imgVehicle.videoName : imgVehicle.videoName) : imgVehicle.workflowName }</p>*/}
				{/*			<p>操作时间：{ imgVehicle.operationDate ? moment(imgVehicle.operationDate).format("YYYY-MM-DD HH:mm:ss") : '无' }</p>*/}
				{/*			<p>类型：{ imgVehicle.type ? (imgVehicle.type === '1' ? '常驻车辆' :  '临时车辆') : '无' }</p>*/}
				{/*			<p>车辆类型：{*/}
				{/*			    imgVehicle.carType ?*/}
                {/*                    carTypeData.map((item,index1) => {*/}
                {/*                    if(imgVehicle.carType === item.dictValue) {*/}
                {/*                        return  <span>{item.dictDesc}</span>*/}
                {/*                    }*/}
                {/*                }) : '无'*/}
				{/*			}</p>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	:*/}
                {/*    <div style={ qrStyle }>暂无图片</div>*/}
				{/*}*/}
            </Modal>
        </Container>

    )
}

export default Form.create()(DecontaminationRecord)
