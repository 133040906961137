import styled from 'styled-components'

export const Container1 = styled.div`
  //height: 390px;
  //width: 546px;
  //position: relative;
  display: flex;
  .editClass{
    display: flex;
    .areaName{
      text-align: center;
      height: 340px;
      overflow: auto;
      margin-right: 10px;
      width: 72px;
      div{
        height: 40px;
        line-height: 40px;
        background-color: #f4f4f4;
        width: 70px;
        user-select: none;
        cursor: pointer;
        border: 1px solid black;
        border-bottom: none;
        content:attr(data-title);
      }
      div:nth-last-child(1){
        border-bottom: 1px solid black;
      }
    }
    .layout{
      border: 1px solid black;
      height: 340px!important;
      width: 500px!important;
    }
  }
  .areaName{
    text-align: center;
    height: 340px;
    overflow: auto;
    margin-right: 10px;
    width: 72px;
    div{
      height: 40px;
      line-height: 40px;
      background-color: #f4f4f4;
      width: 70px;
      user-select: none;
      cursor: pointer;
      border: 1px solid black;
      border-bottom: none;
      content:attr(data-title);
    }
    div:nth-last-child(1){
      border-bottom: 1px solid black;
    }
  }
  .layout{
    border: 1px solid black;
    height: 340px!important;
    width: 500px!important;
  }
  .drawing-item{
    position: absolute;
    border: 1px solid red;
    //cursor: move;
    background-color: #ccc;
    box-sizing: border-box;
    .name{
      width: 42px;
      margin: auto;
    }
  }
  // 容器盒子
  .container_box {
    position: relative;
    border: 1px solid #a5b1b8;
    //background-color: #f3f3f3;
    height: 390px;
    width: 546px;
    margin-left: 70px;
    .drawing-item{
      position: absolute;
      border: 1px solid red;
      cursor: move;
      background-color: #ccc;
      box-sizing: border-box;
      .name{
        width: 42px;
        margin: auto;
      }
    }
    .control-point{
      position: absolute;
      box-sizing: border-box;
      display: inline-block;
      background: #fff;
      border: 1px solid #c0c5cf;
      box-shadow: 0 0 2px 0 rgba(86, 90, 98, .2);
      border-radius: 2px;
      padding: 4px;
      margin-top: -4px !important;
      margin-left: -4px !important;
      user-select: none;   // 注意禁止鼠标选中控制点元素，不然拖拽事件可能会因此被中断

    }
    .control-point.point-e{
      cursor: ew-resize;
      left: 100%;
      top: 50%;
      margin-left: 1px
    }
    .control-point.point-n{
      cursor: ns-resize;
      left: 50%;
      margin-top: -1px
    }
    .control-point.point-s{
      cursor: ns-resize;
      left: 50%;
      top: 100%;
      margin-top: 1px
    }
    .control-point.point-w{
      cursor: ew-resize;
      top: 50%;
      left: 0;
      margin-left: -1px
    }
    .control-point.point-ne {
      cursor: nesw-resize;
      left: 100%;
      margin-top: -1px;
      margin-left: 1px
    }
    .control-point.point-nw {
      cursor: nwse-resize;
      margin-left: -1px;
      margin-top: -1px
    }
    .control-point.point-se {
      cursor: nwse-resize;
      left: 100%;
      top: 100%;
      margin-left: 1px;
      margin-top: 1px
    }
    .control-point.point-sw {
      cursor: nesw-resize;
      top: 100%;
      margin-left: -1px;
      margin-top: 1px
    }
    
    //.leftTop{
    //  width: 5px;
    //  height: 5px;
    //  position: absolute;
    //  background-color: blue;
    //  top: -1px;
    //  left: -1px;
    //  cursor: nwse-resize;
    //}
    //.rightTop{
    //  width: 5px;
    //  height: 5px;
    //  position: absolute;
    //  background-color: blue;
    //  top: -1px;
    //  right: -1px;
    //  cursor: nwse-resize;
    //}
    //.leftBottom{
    //  width: 5px;
    //  height: 5px;
    //  position: absolute;
    //  background-color: blue;
    //  bottom: -1px;
    //  left: -1px;
    //  cursor: nwse-resize;
    //}
    //.rightBottom{
    //  width: 5px;
    //  height: 5px;
    //  position: absolute;
    //  background-color: blue;
    //  bottom: -1px;
    //  right: -1px;
    //  cursor: nwse-resize;
    //}
  }
`
