import request from '../https'

//公共请求
class CommonRequest{

    //字典表：获取类型列表=========================
    //调用的地方需要传dictType参数
    getDictList(params){
        return request({
            url: 'safety/dict/list',
            method: 'post',
            params
        })
    }

    //获取当前用户下的猪场列表=========================
    getChildRegionList(params){
        return request({
            url: 'safety/pigFram/getFramBuilddingList',
            // url: 'safety/area/getChildrenRegionInfo',
            // url: 'upms/PcRegion/getCurrentPigFarm',
            method: 'post',
            data:{
	            regionType:5,
	            enterpriseType:2
            }
        })
    }
    //组织机构
    getChildRegionListAll(params){
        return request({
            // url: 'safety/area/getChildrenRegionInfo',
            url: 'upms/PcRegion/getChildRegionList',
            method: 'get',
            params
            // data:{
	        //     regionType:5
            // }
        })
    }

     //获取当前登录用户下的洗消点列表=========================
    getWashSpotList(params){
        return request({
            url: 'safety/common/washSpotListByFramId',
            method: 'post',
            params
        })
    }

     //获取猪场关联的栋舍列表=========================
    getBuilddingList(params){
        return request({
            url: 'safety/pigFram/getBuilddingList',
            method: 'post',
            params
        })
    }

    //获取回显的栋舍列表
    getPreBuildding(params){
        return request({
            url: 'safety/people/getPeopleFramList',
            method: 'post',
            params
        })
    }

     //获取回显的栋舍列表
    getTempPreBuildding(params){
        return request({
            url: 'safety/visitor/getPeopleTempFramList',
            method: 'post',
            params
        })
    }
     //获取猪场栋舍列表
    getFramBuilddingList(params){
        return request({
            url: 'safety/pigFram/getFramBuilddingList',
            method: 'post',
            params
        })
    }
     //根据洗消点id判断是否有智能花洒设备
    isSmartShower(params){
        return request({
            url: 'safety/workFlow/isSmartShower',
            method: 'post',
            params
        })
    }
     //根据洗消点id判断是否有物资消毒检测设备
    isDisinfectDevice(params){
        return request({
            url: 'safety/workFlow/isGoodDisinfectDevice',
            method: 'post',
            params
        })
    }
     //查询消毒间列表
    getDisinfectionRoom(params){
        return request({
            url: 'safety/disinfectionRoom/list',
            method: 'post',
            params
        })
    }


}

export default CommonRequest
