import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #E7EAED;
  margin-left: -10px;
  .shareLeft{
    flex: 3;
    background-color: white;
    height: 100%;
    padding: 0 10px;
    .content{
      overflow: auto;
      height: 82%;
      &-div{
        height: 120px;
        width: 100%;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #EBEEF5;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .content-name{
          font-size: 16px;
          font-weight: 500;
        }
        .content-text{
          color: rgba(60, 66, 73, 0.6);
          font-size: 10px;
          margin-top: 10px;
        }
      }
    }
  }
  .shareRight{
    flex: 1;
    height: 100%;
    background-color: #F2F2F2;
    .messageDiv{
      height: 80%;
      padding: 10px 10px 0 10px;
      background-color: #F2F2F2;
      overflow: auto;
      &-div{
        .titleDate{
          display: flex;
          justify-content: space-between;
          &-title{
            font-size: 14px;
            font-weight: bold;
          }
          &-date{
            font-size: 10px;
            color: rgba(60, 66, 73, 0.5);
          }
        }
        .content{
          font-size: 10px;
          color: rgba(60, 66, 73, 0.6);
          margin-bottom: 10px;
          margin-top: 3px;
        }
      }

    }
    .inputDiv{
      height: 120px;
      padding: 10px 10px;
      background-color: white;
      box-shadow: 0px -2px 12px 0px rgba(185, 185, 185, 0.5), 0px 2px 8px 0px #DDDDDD;
      .TextAreaDiv{
        .TextArea{
          resize: none;
          height: 70px;
          background-color: #EFEFEF;
        }
      }
      .sendButton{
        margin-top: -20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`