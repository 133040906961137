import React, { useState, useEffect } from 'react'
import {Container} from './style'
import Person from "./register/person";
import Vehicle from "./register/vehicle";
import { Button, Input, Modal, TreeSelect, Popconfirm, Col, Tabs, Form, message, Icon, Upload, Select, DatePicker } from 'antd'

const {TabPane} = Tabs;
function Audit(props){

    return (
        <Container>
            <Tabs>
                <TabPane tab="临时人员登记" key="1">
                    <Person></Person>
                </TabPane>
                <TabPane tab="临时车俩登记" key="2" style={{ marginLeft: '20px' }}>
                    <Vehicle></Vehicle>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(Audit)