import styled from 'styled-components'

export const Container = styled.div`
  .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: none !important;
  }

  .ant-tabs-bar {
    border: none !important;
    border-radius: 0 !important;
    background-color: #F5F5F5 !important;
  }

  .ant-table .ant-table-tbody > tr > td {
    font-size: 13px;
    cursor: pointer;
    //padding: 8px 0 !important;
  }

  .ant-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    //background-color: #FFFFFF !important;
  }

  .ant-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
    //box-shadow: 0px 2px 8px 0px rgba(221, 221, 221, 0.6);0px 0px 6px 0px #CFD8E1;
    //box-shadow: 0 10px 6px -10px #CFD8E1,0 -10px 6px -10px #CFD8E1;
    color: black;
  }

  .ant-tabs .ant-tabs-top-content, .ant-tabs .ant-tabs-bottom-content {
    width: 102% !important;
    margin-top: -10px;
    //height: 400px;
    overflow: auto;
  }

  .ant-table .ant-table-thead > tr > th {
    border-top: none !important;
    //padding: 8px 0 !important;
  }

  .ant-tabs-nav {
    font-size: 14px;
    .ant-tabs-tab{
      width: 100px!important;
      border: 1px solid #EBEEF5!important;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      color: #2F7ECF !important;
      background-color: #FFFFFF;
    }
  }
`