import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 10px;
  .ant-table .ant-table-tbody > tr > td{
    font-size: 13px;
    cursor: pointer;
    //padding: 8px 0!important;
  }
  .ant-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
    //background-color: #FFFFFF!important;
  }
  .ant-table .ant-table-thead > tr > th{
    border-top: none!important;
    //padding: 8px 0!important;
  }
  .ant-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected){
    //box-shadow: 0px 2px 8px 0px rgba(221, 221, 221, 0.6);
  }
`