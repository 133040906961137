import React from "react";
import {Container} from './style'
import { Form,Tabs } from "antd";
import Water from "../water";
import Gate from "../gate";
import ElectricMeter from "../electricMeter";
import Shower from "../shower";

const { TabPane } = Tabs;
function DeviceSet(props){
    return (
        <Container>
            <Tabs defaultActiveKey="1">
                <TabPane tab="智能水表" key="1">
                    <Water></Water>
                </TabPane>
                <TabPane tab="智能电表" key="2" style={{ marginLeft: '20px' }}>
                    <ElectricMeter></ElectricMeter>
                </TabPane>
                <TabPane tab="闸机控制" key="3" style={{ marginLeft: '20px' }}>
                    <Gate></Gate>
                </TabPane>
                <TabPane tab="智能花洒" key="4" style={{ marginLeft: '20px' }}>
                    <Shower></Shower>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default Form.create()(DeviceSet)