import React, {useEffect} from "react";
import Request from '../../request/other-request'

const _request = new Request();

function Index(props) {

    useEffect(() => {
        getToken()
    }, [])

    const getToken = () =>{
        const localToken = sessionStorage.getItem('token')
        if (localToken){
            getUser()
            getCurrentPigFarm()
        }else {
            const loc = props.location.search
            let n1 = loc.length;//地址的总长度
            // let n2 = loc.indexOf("&token=");//取得=号的位置
            let n2 = loc.split("token=");//取得=号的位置
            // let token = loc.substr(n2 + 1, n1 - n2);//从=号后面的内容
            let token = n2[1];//从=号后面的内容
            if(token){
                sessionStorage.setItem('token',token)
                getUser()
                getCurrentPigFarm()
            }else{
                _request.getLoginUrl().then(res => {
                    // props.history.push(res.sysLoginPath)
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('regionId')
                    sessionStorage.removeItem('user')
                    window.location.href=res.sysLoginPath
                })
                // window.location.href="http://192.168.200.13:9090/#/login";
            }
        }
    }

    const getUser = () =>{
        _request.getUser().then(res => {
            sessionStorage.setItem('user',JSON.stringify(res))
            // sessionStorage.setItem('regionId',res.regionId)
        })
    }

    const getCurrentPigFarm = () =>{
        _request.getCurrentPigFarm().then(res =>{
            sessionStorage.setItem('regionId',res[0].regionId);
            window.location.href="#/home";
        })
    }

    return (
        <div></div>
    )
}

export default Index
