import request from '../https'

const upmsData = {sysCode: 'safety'}
class All {
    //获取菜单列表
    getMenu() {
        return request({
            // url: 'client/sys/getLoginMenu',
            url: 'upms/RoleMenu/getCurrentUserMenus',
            contentType: 'application/x-www-form-urlencoded;charset=utf-8',
            method: 'get',
            params: upmsData
        })
    }
    //获取猪场
    getCurrentPigFarm(param) {
        return request({
            url: 'upms/PcRegion/getCurrentPigFarm',
            method: 'GET',
        })
    }
    //退出登录
    loginOut() {
        return request({
            // url: 'client/sys/loginOut',
            url: 'upms/auth/logout',
            method: 'get',
        })
    }
    //登录重定向
    getLoginUrl(params){
        return request({
            // url: 'safety/sys/getsysInfo/getLoginUrl',
            url: 'upms/UnifySmSysInfo/sys/getsysInfo',
            method: 'post',
            contentType: 'application/json; charset=utf-8',
            data: upmsData
        })
    }
    //获取当前登录用户
    getUser() {
        return request({
            // url: 'client/sys/getLoginUser',
            url: 'upms/User/getUserInfo',
            method: 'get',
            params: upmsData
        })
    }
    //首页查询是否有警报记录
    isThereAIRecord() {
        return request({
            url: 'safety/homePage/isThereAIRecord',
            method: 'post',
            params: upmsData
        })
    }
    //取消收藏
    delMenuCollection(param) {
        return request({
            // url: 'client/menuCollection/delete',
            url: 'upms/MenuCollection/remove',
            contentType: 'application/json; charset=utf-8',
            method: 'post',
            data: param
        })
    }
    //收藏
    menuCollection(param) {
        return request({
            // url: 'client/menuCollection/add',
            url: 'upms/MenuCollection/add',
            contentType: 'application/json; charset=utf-8',
            method: 'post',
            data: param
        })
    }
    //查询收藏
    menuCollectionList() {
        return request({
            // url: 'client/menuCollection/list',
            url: 'upms/MenuCollection/list',
            method: 'get',
            params: upmsData
        })
    }
    //获取天气
    getRegionWeather() {
        return request({
            // url: 'client/regionWeather/info',
            url: 'upms/PcRegion/getWeather',
            method: 'get',
            params: upmsData
        })
    }
    //获取系统
    getSysList() {
        return request({
            // url: 'client/bio/sysListAll',
            url: 'upms/UnifySmSysInfo/sys/listAll',
            contentType: 'application/json; charset=utf-8',
            method: 'post',
            params: upmsData
        })
    }

}

export default All
