import React, { useState, useEffect, useRef } from 'react'
import {Container} from './style'
import { connect } from 'react-redux'

import {
    Button,
    Input,
    Modal,
    Radio,
    TreeSelect,
    Popconfirm,
    Col,
    Form,
    message,
    Icon,
    Upload,
    Select,
    DatePicker
} from 'antd'
import { UploadOutlined, StarOutlined, ToTopOutlined } from '@ant-design/icons';
import moment from 'moment'

import StoreSettingHttp from '../../request/staff'
import CommonHttp from '../../request/common-request'


import DeTable from '../../component/DeTable';


const _request = new StoreSettingHttp()
const _commonrequest = new CommonHttp()

const { MonthPicker, RangePicker } = DatePicker;
const headers = {
    'x-auth-token': sessionStorage.getItem('token')
}
function beforeUpload(file) {
    if(file.type.indexOf("image") == -1) return message.error('上传文件类型为图片!');
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
        message.error('图片太大了，要小于3000kb!');
    }
    return isLt2M;
}


function Detection(props) {
    const {baseURL} = props
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [imageUrl, setImageUrl] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [washSpotData, setWashSpotData] = useState([]) //洗消点列表
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '名称',
                dataIndex: 'keyName',
                key: 'keyName',
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                render: (text, record, index) => (
                    <span>{
                        record.type === 0? '常驻人员' :
                            record.type === 1? '临时人员' :
                                record.type === 2? '常驻车辆' :
                                    record.type === 3? '临时车辆' : '未知'
                    }</span>
                )
            },
        {
            title: '检测结果',
            key: 'result',
            dataIndex: 'result',
            render: (text, record, index) => (
                <span>{record.result === 1? '合格' :record.result === 2? '不合格' : '检测中'}</span>
            )
        },
            {
                title: '所属洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '流程位置',
                dataIndex: 'washPositionName',
                key: 'washPositionName',
            },
            {
                title: '操作人',
                dataIndex: 'updatedName',
                key: 'updatedName',
            },
        {
            title: '操作时间',
            key: 'updatedDate',
            dataIndex: 'updatedDate',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            )
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
                    {
                        !record.result?
                        <span style={{marginRight: 8}} className='tableA'
                           onClick={openModal.bind(this, 'edit', record)}>编辑</span>:''}
                    <span style={{marginRight: 8}} className='tableA' onClick={openModal.bind(this, 'check', record)}>查看详情</span>
                </span>
            ),
        },
    ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [userPostData, setUserPostData] = useState([]) //用户岗位列表
    const [flowLocationData, setFlowLocationData] = useState([]) //位置
    const [keyNameData, setKeyNameData] = useState([]) //名称
    const [fileList, setFileList] = useState([]) //文件
    const [imgList, setImgList] = useState([]) //图片
    const [dataType, setDataType] = useState(0)
    const [resultData, setResultData] = useState(0)
    const [objData, setObjData] = useState({})
    const [washSpotId, setWashSpotId] = useState('')
    const [searchWashSpotId, setSearchWashSpotId] = useState()
    const [searchType, setSearchType] = useState()
    const [isType, setIsType] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        headers['x-auth-token'] =  sessionStorage.getItem('token')
        getPeopleList()
        getDictList()
        getWashSpotList()
    }, [])


    //获取员工列表
    const getPeopleList = (ButtonType, pageNo = 1, pageSize = 10) => {
    // const getPeopleList = (ButtonType) => {
        let params = {
            keyName:name,
            type: searchType,
            washSpotId: searchWashSpotId,
            startTime,
            endTime,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {
                pageNo,
                pageSize
            }
        }
        _request.getTestingList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //获取用户岗位列表
    const getDictList = () => {
        let params = {
            dictType:'USER_POST'
        }
        _commonrequest.getDictList(params).then(res => {
            //setTableTotal(res.total || 0)
            setUserPostData(res.list)
        })
    }

    //选择类型
    const typeChange = (e) => {
        const { setFieldsValue } = props.form
        setDataType(e)
        setTimeout(()=>{
            setFieldsValue({
                keyId: null,
            })
        },0)
        setIsType(true)
        getKeyName(e)
        if (washSpotId){
            setTimeout(()=>{
                setFieldsValue({
                    washPosition: null,
                })
            },0)
            getFlowLocation()
        }
    }

    //选择洗消点
    const washChange = (e) => {
        const { setFieldsValue } = props.form
        setWashSpotId(e)
        if (isType){
            setTimeout(()=>{
                setFieldsValue({
                    washPosition: null,
                })
            },0)
            getFlowLocation(e)
        }
    }

    const resChange = (e) =>{
        setResultData(e.target.value)
    }

    //获取位置
    const getFlowLocation = (e,type) =>{
        let param = {
            type: type?type:dataType,
            washSpotId: e?e:washSpotId
        }
        _request.getWashPosition(param).then(res =>{
            setFlowLocationData(res)
        })
    }

    //获取对象名称列表
    const getKeyName = (type) => {
        let param = {
            type
        }
        _request.getKeyName(param).then(res =>{
            setKeyNameData(res)
        })
    }

    //获取当前登录用户下的洗消点列表
    const getWashSpotList = () => {
        let params = {}
        _commonrequest.getWashSpotList(params).then(res => {
            setWashSpotData(res)
        })
    }

    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setImageUrl('')
        setEditVisible(true)
        if(type !== 'add'){
            setTimeout(() => {
                setFieldsValue({
                    type: record.type,
                    id: record.id,
                    result : record.result,
                })
                setObjData(record)
                setResultData(record.result)
                getTestingDetail(record.id)
            }, 0)
        }
    }

    const getTestingDetail = (id) => {
        let param = { id }
        _request.getTestingDetail(param).then(res =>{
            setImgList(res.imgList)
            setFileList(res.reportList)
        })
    }

    const { getFieldValue, validateFields, setFieldValue } = props.form

    //编辑页提交
    const editConfire = () => {
        let flag = false
        validateFields((err, value) => {
            if (!err) {
                if(fileList.length !== 0){
                    fileList.forEach(i =>{
                        if (!i.url){
                            flag = true
                        }
                    })
                }
                if (flag) return message.error('文件尚未上传成功！')
                setConfirmLoading(true)
                if (modalType === 'add') {
                    let params = {
                        type: getFieldValue('type'),
                        washSpotId : getFieldValue('washSpotId'),
                        washPosition : getFieldValue('washPosition')?getFieldValue('washPosition').split('&')[0]:'',
                        washPositionName : getFieldValue('washPosition')?getFieldValue('washPosition').split('&')[1]:'',
                        result : getFieldValue('result'),
                        imgList : imgList,
                        reportList: fileList,
                        keyId: getFieldValue('keyId').split('&')[0]?getFieldValue('keyId').split('&')[0]:null,
                        keyName: getFieldValue('keyId').split('&')[1]?getFieldValue('keyId').split('&')[1]:getFieldValue('keyId'),
                    }
                    _request.addTestingList(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setImgList([])
                        setFileList([])
                        setEditVisible(false)
                        setImageUrl('')
                        cancelEdit()
                        getPeopleList()
                    }).catch(err => {
                        setConfirmLoading(false)
                    })
                } else {
                    let params = {
                        id: getFieldValue('id'),
                        result : getFieldValue('result'),
                        imgList : imgList,
                        reportList: fileList,
                    }
                    _request.updateTestingList(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        setImgList([])
                        setFileList([])
                        setImageUrl('')
                        cancelEdit()
                        getPeopleList()
                    }).catch(err => {
                        setConfirmLoading(false)
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setImgList([])
        setFileList([])
        setEditVisible(false)
        setModalType('')
        setResultData(0)
        setConfirmLoading(false)
        setIsType(false)
        setImageUrl('')
    }

    //搜索
    const clickSearch = () => {
        setPageNo(1)
        setPageSize(10)
        getPeopleList();
    }
    //重置
    const clickReset = () => {
        setName('')
        setPhone('')
        setStartTime('')
        setEndTime('')
        setSearchType()
        setSearchWashSpotId()
        setPageNo(1)
        setPageSize(10)
        getPeopleList('reset')
    }

    const handleChange = (info,e,data) => {
        let newFileList = [...info.fileList];
        let flag = false;
        let msg = '';
        let uid = '';
        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
            if (file.response) {
                if (file.response.code === 200){
                    // Component will show file.url as link
                    file.url = file.response.result.url;
                }else {
                    flag = true
                    uid = file.uid
                    msg = file.response.message
                }
            }
            return file;
        });
        if (flag){
            let list = []
            newFileList.map(i =>{
                if (i.uid !== uid){
                    list.push(i)
                }
            })
            setImgList(list)
            return message.error(msg)
        }
        setImgList(newFileList)
        // if (info.file.status === 'done' && info.file.response && info.file.response.code == 200) {
        //     setImageUrl(info.file.response.result.url);
        //     setImageId(info.file.response.result.id);
        // }
        // 将图片URL保存至from
        // let formData = new FormData()
        // formData.append("imgPath","");
    }
    const fileHandleChange = (info,e,data) => {
        let newFileList = [...info.fileList];
        let flag = false;
        let msg = '';
        let uid = '';

        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
            if (file.response) {
                if (file.response.code === 200){
                    // Component will show file.url as link
                    file.url = file.response.result.url;
                }else {
                    flag = true
                    uid = file.uid
                    msg = file.response.message
                }
            }
            return file;
        });
        if (flag){
            let list = []
            newFileList.map(i =>{
                if (i.uid !== uid){
                    list.push(i)
                }
            })
            setFileList(list)
            return message.error(msg)
        }
        setFileList(newFileList)
        // console.log(info);
        // setFileList([...info.fileList])
        // if (info.file.status === 'done' && info.file.response && info.file.response.code == 200) {
        //     console.log(1111);
        // }
        // 将图片URL保存至from
        // let formData = new FormData()
        // formData.append("imgPath","");
    }

    const onPaginationChange = (pageNo, pageSize) =>{
        setPageSize(pageSize);
        setPageNo(pageNo);
        //查询
        getPeopleList('',pageNo,pageSize);
    }

    function rangeTimeChange(date){
        if(date){
            if(date[0]!=undefined){
                setStartTime(date[0].format('YYYY-MM-DD'));
            }else{
                setStartTime('');
            }
            if(date[1]!=undefined){
                setEndTime(date[1].format('YYYY-MM-DD'));
            }else{
                setEndTime('');
            }
        }
    }

    const { Option } = Select;

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入名称" style={{ width: 120, marginRight: 10 }} value={name} onPressEnter={clickSearch} onChange={(e) => setName(e.target.value)}></Input>
                <Select placeholder="请选择检测对象类型" style={{ width: 180, marginRight: 10 }} value={searchType} onChange={(e) => setSearchType(e)}>
                    <Option value={0} key='0' >常驻人员</Option>
                    <Option value={1} key='1' >临时人员</Option>
                    <Option value={2} key='2' >常驻车辆</Option>
                    <Option value={3} key='3' >临时车辆</Option>
                    <Option value={5} key='5' >未知</Option>
                </Select>
                <Select placeholder="请选择所属洗消点" style={{ width: 180, marginRight: 10 }} value={searchWashSpotId} onChange={(e) => setSearchWashSpotId(e)}>
                    {
                        washSpotData.map((item, index) => {
                            return (
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                            )
                        })
                    }
                </Select>
                <RangePicker
                    ranges={{
                        '今天': [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                        '一个月': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD"), moment(endTime, "YYYY-MM-DD")]}
                    /*disabledTime={disabledRangeTime}*/
                    onChange={rangeTimeChange}
                    showTime
                    format="YYYY-MM-DD"
                />
                <Button type="primary" style={{ marginRight: 10, marginLeft: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable
                    columns={tableColunm}
                    total={tableTotal}
                    setOn={setPageNo}
                    setSize={setPageSize}
                    current={pageNo}
                    pageSize={pageSize}
                    onPaginationChange={(pageNo, pageSize) => onPaginationChange(pageNo, pageSize)}
                    dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增' :modalType === 'check' ? '查看详情' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    modalType === 'check' ?'':
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{ display: 'inline-block' }}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="检测对象类型：">
                                    {getFieldDecorator('type', {
                                        rules: [{ required: true, message: '请选择检测对象类型！' }],
                                    })(
                                        <Select placeholder="请选择检测对象类型" onChange={typeChange}>
                                            <Option value={0} key='0' >常驻人员</Option>
                                            <Option value={1} key='1' >临时人员</Option>
                                            <Option value={2} key='2' >常驻车辆</Option>
                                            <Option value={3} key='3' >临时车辆</Option>
                                            <Option value={5} key='5' >未知</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="对象名称：">
                                    {getFieldDecorator('keyId', {
                                        rules: [{ required: true, message: '请选择对象名称！' }],
                                    })(
                                        dataType === 5?
                                            <Input placeholder="请输入对象名称" />
                                            :
                                        <Select placeholder="请选择对象名称" showSearch
                                            filterOption={(input, option) =>
                                                // (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {
                                                keyNameData.map((item,index) => {
                                                    return (
                                                        <Option value={item.keyId+'&'+item.keyName} key={item.keyId} >{item.keyName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择所属洗消点！' }],
                                    })(

                                        <Select placeholder="请选择所属洗消点" onChange={washChange}>
                                            {
                                                washSpotData.map((item, index) => {
                                                    return (
                                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="流程位置：">
                                    {getFieldDecorator('washPosition')(
                                        <Select placeholder="请选择流程位置">
                                            {
                                                flowLocationData.map((item,index) => {
                                                    return (
                                                        <Option value={item.washPosition+'&'+item.washPositionName} key={item.washPosition} >{item.washPositionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...{
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 4 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 14 },
                                    },
                                }} label="检测结果：">
                                    {getFieldDecorator('result', {
                                        rules: [{ required: true, message: '请选择检测结果！' }],
                                    })(
                                        <Radio.Group onChange={resChange}>
                                            <Radio value={0}>检测中</Radio>
                                            <Radio value={1}>合格</Radio>
                                            <Radio value={2}>不合格</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                resultData?<div>
                                    {/*<Col span={12}>*/}
                                    {/*    <Form.Item {...formItemLayout} label="上传图片：" required={true}>*/}
                                    {/*        <Upload*/}
                                    {/*            name="file"*/}
                                    {/*            showUploadList={{*/}
                                    {/*                showDownloadIcon: true,*/}
                                    {/*                downloadIcon: 'Download',*/}
                                    {/*                showRemoveIcon: true,*/}
                                    {/*                removeIcon: <StarOutlined*/}
                                    {/*                    onClick={e => console.log(e, 'custom removeIcon event')}/>,*/}
                                    {/*            }}*/}
                                    {/*            fileList={imgList}*/}
                                    {/*            action={baseURL+"system/File/upload"}//http://localhost:9081/client/file/upload*/}
                                    {/*            beforeUpload={beforeUpload}*/}
                                    {/*            headers={headers}*/}
                                    {/*            onChange={handleChange.bind(this)}*/}
                                    {/*            multiple*/}
                                    {/*        >*/}
                                    {/*            <Button><UploadOutlined />上传图片</Button>*/}
                                    {/*        </Upload>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                    <Col span={12}>
                                        <Form.Item {...formItemLayout} label="上传文件：" required={true}>
                                            <Upload
                                                name="file"
                                                showUploadList={{
                                                    showDownloadIcon: true,
                                                    downloadIcon: 'Download',
                                                    showRemoveIcon: true,
                                                    removeIcon: <StarOutlined
                                                        onClick={e => console.log(e, 'custom removeIcon event')}/>,
                                                }}
                                                fileList={fileList}
                                                action={baseURL+"system/File/upload"}//http://localhost:9081/client/file/upload
                                                headers={headers}
                                                onChange={fileHandleChange.bind(this)}
                                                multiple
                                            >
                                                <Button><UploadOutlined />上传文件</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </div>:''
                            }
                        </Form>
                        :
                        modalType === 'edit'?
                        <Form style={{ display: 'inline-block' }}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="检测对象类型：">
                                    <span>{objData.type===0?'常驻人员':objData.type===1?'临时人员':objData.type===2?'常驻车辆':objData.type===3?'临时车辆':'未知'}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="对象名称：">
                                    <span>{objData.keyName}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属洗消点：">
                                    <span>{objData.washSpotName}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="流程位置：">
                                    <span>{objData.washPositionName}</span>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...{
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 4 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 14 },
                                    },
                                }} label="检测结果：">
                                    {getFieldDecorator('result', {
                                        rules: [{ required: true, message: '请选择检测结果！' }],
                                    })(
                                        <Radio.Group onChange={resChange}>
                                            <Radio value={0}>检测中</Radio>
                                            <Radio value={1}>合格</Radio>
                                            <Radio value={2}>不合格</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                resultData?<div>
                                    {/*<Col span={12}>*/}
                                    {/*    <Form.Item {...formItemLayout} label="上传图片：" required={true}>*/}
                                    {/*        <Upload*/}
                                    {/*            name="file"*/}
                                    {/*            showUploadList={{*/}
                                    {/*                showDownloadIcon: true,*/}
                                    {/*                downloadIcon: 'Download',*/}
                                    {/*                showRemoveIcon: true,*/}
                                    {/*                removeIcon: <StarOutlined*/}
                                    {/*                    onClick={e => console.log(e, 'custom removeIcon event')}/>,*/}
                                    {/*            }}*/}
                                    {/*            fileList={imgList}*/}
                                    {/*            action={baseURL+"system/File/upload"}//http://localhost:9081/client/file/upload*/}
                                    {/*            beforeUpload={beforeUpload}*/}
                                    {/*            headers={headers}*/}
                                    {/*            onChange={handleChange.bind(this)}*/}
                                    {/*            multiple*/}
                                    {/*        >*/}
                                    {/*            <Button><UploadOutlined />上传图片</Button>*/}
                                    {/*        </Upload>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                    <Col span={12}>
                                        <Form.Item {...formItemLayout} label="上传文件：" required={true}>
                                            <Upload
                                                name="file"
                                                showUploadList={{
                                                    showDownloadIcon: true,
                                                    downloadIcon: 'Download',
                                                    showRemoveIcon: true,
                                                    removeIcon: <StarOutlined
                                                        onClick={e => console.log(e, 'custom removeIcon event')}/>,
                                                }}
                                                fileList={fileList}
                                                action={baseURL+"system/File/upload"}//http://localhost:9081/client/file/upload
                                                headers={headers}
                                                onChange={fileHandleChange.bind(this)}
                                                multiple
                                            >
                                                <Button><UploadOutlined />上传文件</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </div>:''
                            }
                        </Form>:
                            <Form style={{ display: 'inline-block' }}>
                                <Form.Item>
                                    <span></span>
                                </Form.Item>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="检测对象类型：">
                                        <span>{objData.type===0?'常驻人员':objData.type===1?'临时人员':objData.type===2?'常驻车辆':objData.type===3?'临时车辆':'未知'}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="对象名称：">
                                        <span>{objData.keyName}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="所属洗消点：">
                                        <span>{objData.washSpotName}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label="流程位置：">
                                        <span>{objData.washPositionName}</span>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 4 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 14 },
                                        },
                                    }} label="检测结果：">
                                        {getFieldDecorator('result')(
                                            <span>{objData.result === 0?'检测中':objData.result === 1?'合格':'不合格'}</span>
                                        )}
                                    </Form.Item>
                                </Col>
                                {/*<Col span={12}>*/}
                                {/*    <Form.Item {...formItemLayout} label="图片：">*/}
                                {/*        <Upload*/}
                                {/*            name="file"*/}
                                {/*            showUploadList={true}*/}
                                {/*            fileList={imgList}*/}
                                {/*            action={baseURL+"system/File/upload"}//http://localhost:9081/client/file/upload*/}
                                {/*            headers={headers}*/}
                                {/*        >*/}
                                {/*        </Upload>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                {objData.result === 0?'':<Col span={12}>
                                    <Form.Item {...formItemLayout} label="文件：">
                                        <Upload
                                            name="file"
                                            showUploadList={true}
                                            fileList={fileList}
                                            action={baseURL + "system/File/upload"}//http://localhost:9081/client/file/upload
                                            headers={headers}
                                        >
                                        </Upload>
                                    </Form.Item>
                                </Col>}
                            </Form>
                }
            </Modal>
        </Container>

    )
}

// export default Form.create()(Staff)
const mapState = (state) => ({
    baseURL: state.baseURL,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Form.create()(Detection))
