import request from '../https'

class AICameraRequest{
    /**
     * 区域
     */
    //新增区域
    addZone(param) {
        return request({
            url: 'safety/zone/add',
            method: 'POST',
            contentType: 'application/json',
            data: param
        })
    }
    //获取风险等级列表
    getRiskLevel(param) {
        return request({
            url: 'safety/zone/riskLevel/list',
            method: 'POST',
            params: param
        })
    }
    //编辑风险等级列表
    updateRiskLevel(param) {
        return request({
            url: 'safety/zone/riskLevel/update',
            method: 'POST',
            contentType: 'application/json',
            data: param
        })
    }
    //获取区域列表
    getZoneList(param) {
        return request({
            url: 'safety/zone/list',
            method: 'POST',
            params: param
        })
    }
    //获取栋舍列表
    getPigpenList(param) {
        return request({
            url: 'safety/zone/pigpenList',
            method: 'POST',
            params: param
        })
    }
    //编辑区域
    updateZone(param) {
        return request({
            url: 'safety/zone/update',
            method: 'PUT',
            contentType: 'application/json',
            data: param
        })
    }
    //返回区域布局
    aiLayoutList(param) {
        return request({
            url: 'safety/zone/aiLayout/list',
            method: 'POST',
            params: param
        })
    }
    //首页AI报警警示
    aiAlarmStatistics(param) {
        return request({
            url: 'safety/homePage/aiAlarmStatistics',
            method: 'POST',
            params: param
        })
    }
    //编辑区域布局
    updateAiLayout(param) {
        return request({
            url: 'safety/zone/aiLayout/update',
            contentType: 'application/json',
            method: 'POST',
            data: param
        })
    }
    //删除区域
    deleteZone(param) {
        return request({
            url: 'safety/zone/delete/'+param,
            method: 'delete',
        })
    }

    /**
     * AI摄像头
     */
    //AI摄像头列表
    getAIVideoList(param) {
        return request({
            url: 'safety/aiVideo/list',
            method: 'POST',
            params: param
        })
    }
    //新增/编辑区域下拉列表
    getAIVideoSiteList(param) {
        return request({
            // url: 'safety/aiVideo/siteList',
            url: 'safety/aiAlarmRecord/siteList',
            method: 'POST',
            params: param
        })
    }
    //新增/编辑区域下拉列表
    getAddAIVideoSiteList(param) {
        return request({
            url: 'safety/aiVideo/siteList',
            method: 'POST',
            params: param
        })
    }
    //根据区域查询位置
    getTBdAiPositions(param) {
        return request({
            url: 'safety/aiVideo/getTBdAiPositions',
            method: 'POST',
            params: param
        })
    }
    //新增
    addAIVideo(param) {
        return request({
            url: 'safety/aiVideo/add',
            method: 'POST',
            contentType: 'multipart/form-data',
            data: param
        })
    }
    //修改
    updateAIVideo(param) {
        return request({
            url: 'safety/aiVideo/update',
            method: 'PUT',
            contentType: 'multipart/form-data',
            data: param
        })
    }
    //修改
    deleteAIVideo(param) {
        return request({
            url: 'safety/aiVideo/delete/'+param,
            method: 'delete',
        })
    }

    /**
     * 风险等级汇总列表
     */
    //获取列表
    getRiskLevelDate(param) {
        return request({
            url: 'safety/riskLevel/list',
            method: 'POST',
            params: param
        })
    }

    /**
     * AI报警统计
     */
    //获取列表
    getAiAlarmStatistics(param) {
        return request({
            url: 'safety/aiAlarmStatistics/list',
            method: 'POST',
            params: param
        })
    }

    /**
     * AI报警记录
     */
    //警报类型
    getAlarmTypes(param) {
        return request({
            url: 'safety/aiAlarmRecord/alarmTypes',
            method: 'POST',
            params: param
        })
    }
    //风险等级
    getAlarmRiskLevel(param) {
        return request({
            url: 'safety/aiAlarmRecord/riskLevel',
            method: 'POST',
            params: param
        })
    }
    //提示词条列表
    promptEntry(param) {
        return request({
            url: 'safety/aiAlarmRecord/promptEntry',
            method: 'POST',
            params: param
        })
    }
    //列表
    getAiAlarmRecordList(param) {
        return request({
            url: 'safety/aiAlarmRecord/list',
            method: 'POST',
            params: param
        })
    }
    //已读
    updateAiAlarmRecord(param) {
        return request({
            url: 'safety/aiAlarmrecord/update',
            method: 'PUT',
            params: param
        })
    }

    /**
     * 品牌
     */
    //获取列表
    getFalconOneVideoList(param) {
        return request({
            url: 'safety/falconOneVideo/list',
            method: 'POST',
            params: param
        })
    }
    //新增
    addFalconOneVideo(param) {
        return request({
            url: 'safety/falconOneVideo/add',
            method: 'POST',
            contentType: 'multipart/form-data',
            data: param
        })
    }
    //修改
    updateFalconOneVideo(param) {
        return request({
            url: 'safety/falconOneVideo/update',
            method: 'PUT',
            contentType: 'multipart/form-data',
            data: param
        })
    }
    //导出
    exportFalconOneVideo(param) {
        return request({
            url: 'safety/falconOneVideo/export',
            method: 'get',
            contentType: 'application/vnd.ms-excel',
            params: param
        })
    }
    //删除
    delFalconOneVideo(param) {
        return request({
            url: 'safety/falconOneVideo/delete/'+param,
            method: 'delete',
        })
    }


}

export default AICameraRequest
